import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GameSessionService } from 'src/app/services/GameSessionService';

@Component({
  selector: 'app-modal-language',
  templateUrl: './modal-language.component.html',
  styleUrls: ['./modal-language.component.scss'],
})
export class ModalLanguageComponent implements OnInit {
  languages: string[] = ['fr'];
  selectedLanguage: string = 'fr';
  iconCloseImageUrl: string = null;

  hasChanges: boolean = false;

  constructor(
    private navParams: NavParams,
    private translateService: TranslateService,
    private modalController: ModalController,
    private gameSessionSrv: GameSessionService,
  ) { }

  ngOnInit() {
    const rootConfig = this.gameSessionSrv.getRootConfiguration();
    this.iconCloseImageUrl = rootConfig?.iconCloseImageUrl ?? 'https://cdn.mocli.fr/icon/cross.svg';

    if (this.navParams.data.languages) {
      this.languages = this.navParams.data.languages;
    }
    this.selectedLanguage = this.translateService.currentLang;
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }

  onLanguageClick(language: string) {
    this.selectedLanguage = language;
    this.hasChanges = true;
  }

  confirmLanguage() {
    this.closeModal(this.selectedLanguage);
  }

}
