/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OrderService } from '@mocli/MocliCommonLib';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subject, debounceTime, firstValueFrom } from 'rxjs';
import { OrderStatus } from 'src/app/data/enums/OrderStatus.enum';
import { ExperienceOrder } from 'src/app/data/models/ExperienceOrder';
import { ModalConfirmComponent } from 'src/app/features/shared/modal/modal-confirm/modal-confirm.component';
import { AuthService } from 'src/app/services/AuthService';
import { LandingPageService } from 'src/app/services/LandingPageService';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

export class LandingPage {
  pages: LandingPageConfig[];

  constructor() {
    this.pages = [new LandingPageConfig()];
  }
}

export class LandingPageConfig {
  label: string;
  expId: string;
  active: boolean;
  labelInactive: string;
  index: number;

  constructor() {
      this.label = '';
      this.expId = null;
      this.active = true;
      this.labelInactive = '';
      this.index = 0;
  }
}


@Component({
  selector: 'app-landing-page-configurator',
  templateUrl: './landing-page-configurator.component.html',
  styleUrls: ['./landing-page-configurator.component.scss'],
})
export class LandingPageConfiguratorComponent implements OnInit, OnDestroy {
  @ViewChild('swiperAnchor', { read: ViewContainerRef, static: true })
  swiperAnchor: ViewContainerRef;
  // @ViewChild('swiperAnchor', { read: SwiperComponent, static: true }) swiper: SwiperComponent;
  @ViewChild('swiperAnchor') swiper: SwiperComponent;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: true,
    speed: 200,
    autoHeight: true,

    noSwiping: true,
    noSwipingClass: 'mySwiper',
  };

  landingPageForm: FormGroup;
  config: any;
  configByLang: any[] = [];
  languages: any[] = [];
  dropdownSelectedLanguage: any = null;
  isSubmitted: boolean = false;

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  data: LandingPage;
  inputDisabled: boolean = null;

  pages: LandingPageConfig[] = [];
  pageComplete: boolean[] = [false];

  // Nombre maximum d'expériences
  maxExperiences = 10;

  selectedLanguage: string = 'fr';

  expList: {name: string; id: string; isUsed: boolean; order: ExperienceOrder}[] = [];
  isLoading: boolean = false;

  settingExpList: boolean = false;
  previousId: any = null;

  changesSubject: BehaviorSubject<boolean>;
  saveSubject: Subject<any>;
  isCompleteSubject: BehaviorSubject<boolean>;

  finishedInitFormGroup: boolean = false;
  isNewElement: boolean = false;
  isSaving: boolean = false;

  selectedIdx: number = 0;
  maxElements: number = 50;
  orderId: number = null;
  experienceInfoId: number = null;

  valueChangesSubscription: any = null;

  constructor(
    private fb: FormBuilder,
    private orderSrv: OrderService,
    private authService: AuthService,
    private modalController: ModalController,
    private messageService: MessageService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.orderId = this.route.snapshot.params.id;

    this.initFormGroup();
    await this.getCurrentOrder();
    await this.getOrders();

    setTimeout(() => {
      this.initGamesLeft();
      this.finishedInitFormGroup = true;
    }, 400);
  }

  ngOnDestroy() {
  }

  async getCurrentOrder() {
    this.isLoading = true;
    const userId = this.authService?.userLogged?.value?.id;

    if (!userId) {
      this.isLoading = false;
      return;
    }

    try {
      const res = await firstValueFrom(this.orderSrv.getOrderById(userId, this.orderId)) as any;

      if (!res) {
        this.isLoading = false;
        return;
      }

      this.experienceInfoId = res?.experienceInfoId;
    } catch (e) {
      console.log('error', e);
    }
    this.isLoading = false;
  }

  async getOrders() {
    this.isLoading = true;

    try {
      const res: any = await firstValueFrom(this.orderSrv.getOrdersForUser(this.authService?.userLogged?.value?.id)) as any;

      if (res && res.length > 0) {
        res.forEach(order => {
          if ((order?.order?.orderStatus === OrderStatus.active || order?.order?.orderStatus === OrderStatus.configuredNotYetStarted || order?.order?.orderStatus === OrderStatus.toConfigure)
            && (this.experienceInfoId === null || this.experienceInfoId !== order?.order?.experienceInfoId)
          ) {

            let nameFmt = (order?.order?.experienceInfo?.name ?? 'N/A') + ' - ';

            if (order?.order?.tags) nameFmt += this.getTagsFormated(order?.order?.tags) + ' - ';

            nameFmt += '(' + order?.order?.id + ')';

            this.expList.push({name: nameFmt, id: order?.order?.id, isUsed: false, order});
          }
        });
      }
    } catch (e) {
      this.expList = [];
    }
    this.isLoading = false;
  }

  getTagsFormated(tags: string) {
    if (!tags) return '';

    const tagsFmt = tags?.split('|');
    if (tagsFmt && tagsFmt.length <= 4) {
      let final: string = '';
      tagsFmt.forEach((tag: string, index: number) => {
        if (tag !== '') {
          if (index !== 0)
            final += ' ';

          final += ('#' + tag);
        }
      });
      return final;
    }
    return '';
  }

  uniqueFieldsValidator(): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
      const values = [];

      for (let i = 0; i < this.pages.length; i++) {
        values.push(formGroup.get('label_' + i)?.value);
      }

      const hasDuplicates = values.some((value, index) => values.indexOf(value) !== index);

      return hasDuplicates ? { uniqueFields: { value: values } } : null;
    };
  }

  initFormGroup(firstTime: boolean = true) {
    if (this.config.modularConfig?.content[0].LandingPage && firstTime) this.data = this.config.modularConfig?.content[0].LandingPage;
    if (this.valueChangesSubscription) this.valueChangesSubscription.unsubscribe();

    this.landingPageForm = this.fb.group({});

    this.pages = [];

    for (let i = 0; i < this.data?.pages?.length; i++) {
      this.addElement(this.data.pages[i], i, true);
    }

    this.valueChangesSubscription = this.landingPageForm.valueChanges.pipe(debounceTime(200)).subscribe((event) => {
      if (this.isNewElement) return;
      if (this.changesSubject && this.changesSubject?.value === false) {
        this.changesSubject.next(true);
      }

      if (this.isCompleteSubject) {
        const isComplete = this.isOrderComplete();
        this.isCompleteSubject.next(isComplete);
        setTimeout(() => {
          const isComplete2 = this.isOrderComplete();
          this.isCompleteSubject.next(isComplete2);
        }, 500);
      }
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  addElement(page: LandingPageConfig = null, idx: number = -1, fromLoop: boolean = false) {
    if (!page) {
      page = new LandingPageConfig();
    }

    if (idx === -1) {
      idx = this.pages.length;
    }

    page.index = idx;

    this.landingPageForm.addControl('label_' + idx, new FormControl(page?.label ?? '', [Validators.maxLength(30), Validators.required, this.alreadyExistsValidator(idx)]));
    this.landingPageForm.addControl('game_' + idx, new FormControl(page?.expId ?? '', [Validators.required]));
    this.landingPageForm.addControl('active_' + idx, new FormControl(page?.active ?? true));

    this.pages = [...this.pages, page];
    if (!fromLoop) this.pageComplete.push(false);

    if (!fromLoop) {
      this.swiper.swiperRef.update();
      setTimeout(() => {
        this.gotoPage(this.pages.length - 1);
      }, 100);
    }
  }

  // Custom email validator function
  alreadyExistsValidator(idx: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // delete every space before and after the value
      const value: string = control.value?.trim() ?? '';

      for (let i = 0; i < this.pages.length; i++) {
        const newValue = this.landingPageForm.get('label_' + i)?.value.trim() ?? '';
        if (i < idx && newValue === value) {
          return { alreadyExists: { value: true } };
        }
      }

      return null;
    };
  }

  isOrderComplete() {
    return this.pageComplete.every((page) => page === true);
  }

  autoSave() {
    if (!this.landingPageForm) return;

    this.isValidEventEmitter.emit(this.landingPageForm.valid);

    const pagesComplete = [];

    this.pages?.forEach((page, idx) => {
      if (!this.data.pages[idx]) {
        this.data.pages[idx] = new LandingPageConfig();
      }
      this.data.pages[idx].label = this.landingPageForm.get('label_' + idx)?.value ?? '';
      this.data.pages[idx].expId = this.landingPageForm.get('game_' + idx)?.value ?? '';
      this.data.pages[idx].active = this.landingPageForm.get('active_' + idx)?.value ?? true;

      this.data.pages[idx].index = page?.index;

      const completed = this.landingPageForm.get('label_' + idx).valid && this.data.pages[idx].label && this.data.pages[idx].expId ? true : false;

      pagesComplete.push(completed);
    });

    // remove every data pages after pages.length but not before, not starting at 0 but at pages.length
    this.data.pages.splice(this.pages.length, this.data.pages.length - this.pages.length);

    this.pageComplete = [...pagesComplete];
  }

  initGamesLeft() {
    if (this.finishedInitFormGroup && this.changesSubject.value === false) this.changesSubject.next(true);

    setTimeout(() => {
      if (this.expList.length > 0) {
        this.expList.forEach((exp, index) => exp.isUsed = false);

        this.pages.forEach((page, pageIdx) => {
          const expId = this.landingPageForm?.get('game_' + pageIdx)?.value;

          if (expId) {
            this.expList.forEach((exp, index) => {
              if (exp.id === expId) {
                exp.isUsed = true;
              }
            });
          }
        });
      }
    }, 100);
  }

  onLanguageChange(event: any) {
    if (event && event?.value?.key) {
      this.selectLanguage(event.value.key);
    }
  }

  selectLanguage(lang: string) {
    this.selectedLanguage = lang;
  }

  gotoPage(idx: number) {
    this.selectedIdx = idx;
    this.swiper.swiperRef.slideTo(idx);
  }

  async openModalDeleteElement(idx: number) {
    if (this.pages.length === 1) {
      return;
    }

    const props: any = {
      paramTitle: 'Voulez-vous vraiment supprimer cet élément ?',
      paramDescription: '',
    };

    const modal = await this.modalController.create({
      component: ModalConfirmComponent,
      componentProps: props,
      cssClass: 'modal-confirm-custom',
    });

    modal.onWillDismiss().then(async (dataReturned: any) => {
      if (dataReturned?.data === 'confirm') {
        this.isLoading = true;
          const pagesTmp = [...this.pages];
          pagesTmp.splice(idx, 1);

          const newPages = JSON.parse(JSON.stringify(pagesTmp));

          if (this.changesSubject && this.changesSubject?.value === false) {
            this.changesSubject.next(true);
          }

          this.data.pages = [...newPages];

          this.initFormGroup(false);
          this.selectedIdx = 0;

          this.expList = [];
          await this.getOrders();
          this.initGamesLeft();
      }
    });
    return await modal.present();
  }

  getSaveTooltip() {
    if (this.changesSubject.value === true && this.isOrderComplete()) return null;

    if (!this.isOrderComplete()) {
      return 'Merci de remplir tous les champs requis, avec au minimum :\n\nUn jeu lié à chaque page\nUn nom pour chaque page\n';
    }

    if (this.changesSubject.value === false) return 'Aucun changement à sauvegarder';
  }

  async save() {
    if (!this.isOrderComplete()) {
      return;
    }

    this.isSaving = true;
    this.autoSave();

    const tmp = JSON.parse(JSON.stringify(this.data.pages));

    try {
      const final = [];

      for (let i = 0; i < this.data.pages.length; i++) {
        this.data.pages?.forEach(page => {
          if (page.index === i) final.push(page);
        });
      }

      const config = {
        ModularRoot: {
          configuration: this.config.configuration,
          content: [
            {
              LandingPage: {
                isStartPage: true,
                pages: final
              },
            },
          ],
        }
      };

      await firstValueFrom(this.orderSrv.configureGenericExperience(this.orderId, config));
      this.changesSubject.next(false);
      setTimeout(() => {
        this.messageService.add({severity:'success', detail: 'Sauvegarde effectuée avec succès.'});
        this.isSaving = false;
      }, 500);

    } catch (e) {
      this.messageService.add({severity:'error', detail: 'Une erreur est survenue lors de la sauvegarde.'});
      console.log('error', e);
    }
  }

  incompleteToast() {
    this.isSubmitted = true;
    this.swiper.swiperRef.update();
    setTimeout(() => {
      this.swiper.swiperRef.update();
    }, 200);
    this.messageService.add({severity:'error', detail: 'Veuillez compléter tous les champs avant de sauvegarder.'});
  }

  async onReorderList(event: any) {
    this.autoSave();
    this.pages.forEach((page, index) => {
      page.index = index;
      this.swiper.swiperRef.update();
    });

    const final = [];

    for (let i = 0; i < this.data.pages.length; i++) {
      this.data.pages?.forEach(page => {
        if (page.index === i) final.push(page);
      });
    }

    this.data.pages = JSON.parse(JSON.stringify(final));

    this.initFormGroup(false);

    this.expList = [];
    await this.getOrders();
    this.initGamesLeft();

    if (this.changesSubject?.value === false) this.changesSubject.next(true);

    setTimeout(() => {
      if (event?.length > 0 && event[0]?.index >= 0) {
        this.selectedIdx = event[0]?.index;
        this.swiper.swiperRef.slideTo(event[0]?.index);
      }
    }, 100);
  }
}
