<div class="catalog-tutorial">
  <div class="title-container">
    <div class="title">
      {{selectedTutorial?.title ?? ''}}
    </div>
  </div>
  <div class="main-content">
    <div class="img-container">
      <img [src]="'../../../../../assets/images/catalogTutorial/' + selectedTutorial.imageUrl" alt="">  
    </div>
    <div class="description">{{selectedTutorial?.description ?? ''}}</div>
  </div>
  <div class="footer">
    <div class="catalog-tutorial-carrousel">
      <div class="dots">
        <div *ngFor="let tutorial of tutorialsSlide; let i = index" class="dots-child" [ngClass]="{'dots-child-selected': selectedIdx === i}"></div>
      </div>
      <div class="carrousel-counter">{{selectedIdx + 1}}/{{tutorialsSlide?.length > 0 ? tutorialsSlide.length : '0'}}</div>
    </div>

    <div class="mocli-button btn-next-tutorial" (click)="nextSlide()">{{selectedTutorial?.btnText ?? ''}}</div>
  </div>
</div>