import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GameSessionService } from 'src/app/services/GameSessionService';

@Component({
  selector: 'app-modal-scanner-error',
  templateUrl: './modal-scanner-error.component.html',
  styleUrls: ['./modal-scanner-error.component.scss'],
})
export class ModalScannerErrorComponent implements OnInit {
  public iconCloseImageUrl: string = null;
  public errorMessage: string = null;

  constructor(
    private modalController: ModalController,
    private gameSessionSrv: GameSessionService,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    const rootConfig = this.gameSessionSrv.getRootConfiguration();
    this.iconCloseImageUrl = rootConfig?.iconCloseImageUrl ?? 'https://cdn.mocli.fr/icon/cross.svg';

    if (this.navParams?.data?.errorMessage) {
      this.errorMessage = this.navParams.data.errorMessage;
    }
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }
}
