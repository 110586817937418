import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpService } from './HttpService';
import { PartialUserInfo, UserInfo } from '../data/models/UserInfo';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginClaim } from '../data/models/LoginClaim';
import { UserToken } from '../data/models/UserToken';
import { CompanyInfo } from '../data/models/CompanyInfo';
import { RolesEnum } from '../data/enums/Roles.enum';
import { AuthService } from './AuthService';
import { CdnResponse } from '../data/models/cdnResponse';
import { WalletTransaction } from '../data/models/WalletTransaction';
import { DateTime } from 'luxon';
import { UserService } from '@mocli/MocliCommonLib';

@Injectable({ providedIn: 'root' })
export class UserWebService {
    token: UserToken;
    user: UserInfo;

    private requester: HttpService;

    constructor(
        public client: HttpClient,
        private authService: AuthService,
        private userService: UserService
    ) {
        const endpoint = environment.mainUrl + '/api/User';
        this.requester = new HttpService(client, endpoint, authService);
    }

    login(email: string, password: string): Observable<UserToken> {
        const claims = {
            email,
            password,
            audience: 'https://mocli.fr',
            issuer: 'https://mocli.fr/api',
         } as LoginClaim;

        return this.requester.postApi<any>('Login', claims).pipe(
            map((response) => {
                try {
                    this.token = response as UserToken;
                    if (this.token.expireAt > DateTime.now()) {
                        return null;
                    }

                    //on set le token pour permettre l'appel a getUserById
                    this.authService.newUserLogged({...this.authService?.userLogged?.value,token: this.token.token, id: this.token.userId});

                    // firstValueFrom(this.userService.getUserById(this.token.userId)).then((val) => {
                    //     (val as any).token = this.token.token;
                    //     this.authService.newUserLogged(val);
                    // });
                    return this.token;
                }
                catch(syntaxError) {
                    return null;
                }
            })
        );
    }
}
