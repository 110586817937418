<div [formGroup]="loginFormGroup" class="login-container mocli-container-content">
  <!-- <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
      <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div> -->
  <div class="email-container">
      <label for="email">Email</label>
      <input [ngClass]="{'invalid-input': loginFormGroup.controls.email.invalid && isSubmitted}" formControlName="email" type="text" id="email">
      <div *ngIf="loginFormGroup.controls.email.invalid && isSubmitted" class="invalid-input-text">
          Ce champ est requis.
      </div>
  </div>
  <div class="password-container">
      <label for="password">Mot de passe</label>
      <input [ngClass]="{'invalid-input': loginFormGroup.controls.password.invalid && isSubmitted}" formControlName="password" type="password" id="password">
      <div *ngIf="loginFormGroup.controls.password.invalid && isSubmitted" class="invalid-input-text">
          Ce champ est requis.
      </div>
  </div>
  <a href="/forgotPassword">Vous avez oublié votre mot de passe ?</a>
  <div class="error-message">
      <p *ngIf="errorMessage">{{errorMessage}}</p>
  </div>
  <div class="mocli-button btn-connect" (click)="submitForm()">
      <span *ngIf="!isLoading">
          Se Connecter
      </span>
      <span *ngIf="isLoading">
          <ion-spinner color="light" name="crescent"></ion-spinner>
      </span>
  </div>
</div>