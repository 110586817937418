<div class="add-stat-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>

  <div class="add-stat-form" [formGroup]="addSectionFormGroup">
    <div class="input-container">
      <label for="title">{{title ?? 'Titre de la section *'}}</label>
      <input placeholder="titre..." [ngClass]="{'invalid-input': addSectionFormGroup.controls.title.invalid && isSubmitted}" formControlName="title" type="text" id="title">
      <div class="invalid-input-container">
          <span *ngIf="addSectionFormGroup.controls.title.invalid" class="invalid-input-text">
              <span *ngIf="addSectionFormGroup.controls.title?.errors?.required && isSubmitted">Champs requis. </span>
          </span>
      </div>
    </div>
  </div>

  <div class="add-stat-controls">
    <div class="mocli-button add-btn" (click)="onAddClick()">
      {{!sectionToEdit ? 'Ajouter' : 'Enregistrer'}}
    </div>
  </div>
</div>