import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-add-order-option',
  templateUrl: './modal-add-order-option.component.html',
  styleUrls: ['./modal-add-order-option.component.scss'],
})
export class ModalAddOrderOptionComponent implements OnInit {
  addOrderOptionFormGroup: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.addOrderOptionFormGroup = this.fb.group({
      name: ['', [Validators.required]],
      amount: [1, [Validators.required]],
    });
  }

  async onAddClick() {
    this.isSubmitted = true;

    if (this.addOrderOptionFormGroup.valid) {
      const dataReturned = {
        name: this.addOrderOptionFormGroup.value.name,
        amount: this.addOrderOptionFormGroup.value.amount,
      };
      this.closeModal(dataReturned);
    }
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

}
