import { Component, Input, Renderer2, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent {
  @Input() label: string;
  @Input() isCheckboxValid: boolean = true;
  @Input() disable: boolean = false;
  @Input() error: boolean = false;
  @Input() name: string;
  @Input() id: string;

  @Input() checked: boolean = false;

  constructor() { }
}

