<div class="mocli-box-content configure-question">

  <div class="show-choices" *ngIf="showChoices">
    <div class="choice-library" (click)="onChoiceClick('library')">
      Ajouter une question depuis la bibliothèque d’idées
    </div>
    <div class="choice-custom" (click)="onChoiceClick('custom')">
      Créer une question personnalisée
    </div>
    
  </div>

  <!-------- Library -------->
  <div class="show-library" *ngIf="showLibrary">
    <div class="title">Liste de questions proposées</div>

    <ng-container *ngFor="let question of library">
      <div class="library-question-container" [ngStyle]="{'background-color': question.isSelected ? '#E3F0FFB2' : 'transparent'}">
        <div class="content">
          <div class="library-fun-fact">
            <strong>Fun Fact : </strong>{{question.funFact}}
          </div>
          <div class="library-answer" [ngClass]="{'library-answer-true': question.answer, 'library-answer-false': !question.answer}">
            <strong>Réponse : </strong>{{question.answer ? 'Vrai' : 'Faux'}}
          </div>
          <div class="library-explain">
            <strong>Explication : </strong>{{question.explain}}
          </div>
        </div>
        <div class="button-container">
          <div class="button" (click)="onAddOrDeleteLibraryQuestionClick(question)" [ngClass]="{'button-selected': question.isSelected, 'button-not-selected': !question.isSelected}">
            {{question.isSelected ? 'Supprimer' : 'Ajouter'}}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="button-close-container">
      <div class="button button-not-selected" style="width: fit-content" (click)="showLibrary = false">
        Fermer le catalogue
      </div>
    </div>
  </div>

  <!-------- Configurator -------->
  <div *ngIf="!showLibrary">
    <form [formGroup]="trueOrFalseForm">

      <!-- Titre -->
      <div class="question-info">
        <div class="big-title title">Fun Fact {{data.index + 1}}</div>
        <div class="question-content">
          <textarea formControlName="question"
                    placeholder="Écrire ici..."
                    [ngClass]="{'invalid-input': trueOrFalseForm.controls.question.invalid, 'disabled-input': inputDisabled}">
          </textarea>
          <div class="question-content-counter">
            <span *ngIf="trueOrFalseForm.controls.question.invalid" class="invalid-input-text">
              <span *ngIf="trueOrFalseForm.controls.question?.errors?.required">Champs requis. </span>
              <span *ngIf="trueOrFalseForm.controls.question?.errors?.maxlength">Taille maximale atteinte. </span>
            </span>
            <span>Caractères : {{trueOrFalseForm?.controls?.question?.value?.length}} / 300</span>
          </div>
        </div>
      </div>
    </form>
      
    <!-- Réponses -->
    <div class="answer-top">
      <div class="first top">
        <div class="title">Réponse</div>
      </div>
      <div class="title-correct">Réponse correcte</div>
    </div>

    <div class="answers" [formGroup]="trueOrFalseForm">
      <!-- True answer -->
      <div class="answer">
        <div class="first">
          Vrai
        </div>
        
        <div class="second">
          <app-radio-input [checked]="trueOrFalseForm.controls.correctAnswer.value" (click)="inputDisabled ? null : onSelectCorrectAnswer('correctAnswer', 'wrongAnswer')"></app-radio-input>
        </div>
      </div>
      
      <!-- False answer -->
      <div class="answer">
        <div class="first">
          Faux
        </div>
        
        <div class="second">
          <app-radio-input [checked]="trueOrFalseForm.controls.wrongAnswer.value" (click)="inputDisabled ? null : onSelectCorrectAnswer('wrongAnswer', 'correctAnswer')"></app-radio-input>
        </div>
      </div>
    </div>

    <!-- Explication -->
    <div class="explain">
      <form [formGroup]="trueOrFalseForm">
        <div class="explain-title">
          <div class="title">Explication</div>
          <div class="optional">(optionnelle)</div>
        </div>
        <div class="explain-content">
          <textarea [rows]="3" 
                    formControlName="explain"
                    placeholder="Écrire ici..."
                    [ngClass]="{'invalid-input': trueOrFalseForm.controls.explain.invalid, 'disabled-input': inputDisabled}">
          </textarea>
          <div class="explain-content-counter">
            <span class="invalid-input-text">
              <span *ngIf="trueOrFalseForm.controls.explain?.errors?.maxlength">Taille maximale atteinte. </span>
            </span>
            <span>Caractères : {{trueOrFalseForm?.controls?.explain?.value?.length}} / 300</span>
          </div>
        </div>
      </form>
    </div>

    <!-- Visuel -->
    <!-- <div class="visuel" [formGroup]="trueOrFalseFormGroup">
      <div class="visuel-title">
        <div class="title">Visuel</div>
        <div class="optional">(optionnel)</div>
      </div>
      <div *ngIf="!visualFileLabel">
        <input #inputFile id="inputFile" type="file" formControlName="visual" name="file" class="inputfile" accept="image/png, image/jpeg"/>
        <label for="inputFile" class="add-file">Joindre un fichier</label>
      </div>
      <div *ngIf="visualFileLabel" class="visual-name-container">
        <div class="visual-name" >{{visualFileLabel}}</div>
        <div class="delete">
          <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
        </div>
      </div>
    </div>
    <div *ngIf="visualFile" class="preview-container">
      <img id="previewVisual" [src]="previewVisualUrl()" alt="" class="preview">
    </div> -->

    <div class="button button-not-selected" *ngIf="!inputDisabled" style="margin-top: 20px;" (click)="showLibrary = true">
      Ouvrir le catalogue
    </div>
  </div>

</div>