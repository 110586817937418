import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ExperienceConditions } from 'src/app/data/models/ExperienceConditions';

@Component({
  selector: 'app-modal-terms',
  templateUrl: './modal-terms.component.html',
  styleUrls: ['./modal-terms.component.scss'],
})
export class ModalTermsComponent implements OnInit {
  conditions: ExperienceConditions = null;
  selectedIdx: number = 0;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.conditions) {
      this.conditions = this.navParams.data.conditions;
      this.selectedIdx = -1;
    }
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }

}
