/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/AuthService';
import { HeaderBarService } from '../services/HeaderBarService';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private headerBar: HeaderBarService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.authService.userLogged && this.authService.userLogged.value && this.authService.userLogged.value.token) {
      return true;
    }
    else if (this.authService.isFromExperiences) {
      this.authService.isFromExperiences = false;
      this.setHeaderBarEmpty();
      this.router.navigate(['/register'], {queryParams: {redirect: (route as any)._routerState.url}});
      return false;
    }
    else {
      this.setHeaderBarEmpty();
      if (this.authService.findUserCookies()) return true;
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  setHeaderBarEmpty() {
    this.headerBar.updateHeaderBar({
      ...this.headerBar.onHeaderBarChanged.value,
      title: '',
      showBackBtn: false,
    });
  }
}
