import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ExperienceInfo } from 'src/app/data/models/ExperienceInfo';

@Component({
  selector: 'app-modal-order-validate',
  templateUrl: './modal-order-validate.component.html',
  styleUrls: ['./modal-order-validate.component.scss'],
})
export class ModalOrderValidateComponent implements OnInit {
  experience: ExperienceInfo;
  orderId: number;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.experience)
    {
      this.experience = this.navParams.data.experience;
    }
  }

  async closeModal(data: string) {
    const onClosedData: string = data;

    this.router.navigateByUrl('/configure/' + this.orderId);
    await this.modalController.dismiss(onClosedData);
  }

}
