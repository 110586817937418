<div class="add-theme-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>

  <div class="add-theme-form" [formGroup]="addThemeFormGroup">
    <div class="input-container">
      <label for="name">Nom du thème *</label>
      <input [ngClass]="{'invalid-input': addThemeFormGroup.controls.name.invalid && isSubmitted}" formControlName="name" type="text" id="name">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.name.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.name?.errors?.required && isSubmitted">Champs requis. </span>
              <span *ngIf="addThemeFormGroup.controls.name?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
          </span>
      </div>
    </div>
  </div>

  <div class="add-theme-controls">
    <div class="mocli-button add-btn" (click)="onCreateClick()">Créer le thème</div>
  </div>
</div>