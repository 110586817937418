/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { UserFormQuestion } from '@mocli/MocliCommonLib/lib/data/models/UserFormQuestion';
import { ModalConfirmComponent } from '../../modal-confirm/modal-confirm.component';
import { FieldTypeEnum } from 'src/app/data/enums/FieldTypeEnum';

@Component({
  selector: 'app-modal-data-unique',
  templateUrl: './modal-data-unique.component.html',
  styleUrls: ['./modal-data-unique.component.scss'],
})
export class ModalDataUniqueComponent implements OnInit {

  isRequired: boolean = false;
  charCount: number = 0;

  isSubmitted: boolean = false;

  modalDataFormGroup: FormGroup = null;

  isEdit: boolean = false;
  answers: string[] = ['answer_0', 'answer_1'];
  idxMax = 1;

  languages: string[] = ['fr'];
  currentLanguageIdx: number = 0;
  configByLang: any = [];

  stepStatusLabels = {
    completed: 'Complété',
    current: 'En cours',
    waiting: 'En attente',
  };

  stepStatusColors = {
    completed: '#6CC729',
    current: '#0075F1',
    waiting: '#CECECE',
  };

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.languages) {
      this.languages = this.navParams.data.languages;
    }

    if (this.navParams?.data?.fields) {
      this.isEdit = true;

      this.navParams.data.fields.forEach((field, idx) => {
        this.configByLang[this.languages[idx]] = field;
      });
      this.isRequired = this.configByLang['fr']?.required;
    }

    this.modalDataFormGroup = this.fb.group({
      question: [this.isEdit && this.configByLang['fr']?.question ? this.configByLang['fr']?.question : '', [Validators.required, Validators.maxLength(200)]],
      answer_0: [this.isEdit && this.configByLang['fr']?.values.length > 0 ? this.configByLang['fr']?.values[0] : '', [Validators.required, Validators.maxLength(50)]],
      answer_1: [this.isEdit && this.configByLang['fr']?.values.length > 1 ? this.configByLang['fr']?.values[1] : '', [Validators.required, Validators.maxLength(50)]],
    });

    if (this.isEdit && this.configByLang['fr']?.values.length > 2) {
      for (let i = 2; i < this.configByLang['fr']?.values?.length; i++) {
        this.answers?.push('answer_' + i);
        this.modalDataFormGroup.addControl('answer_' + i, new FormControl(this.configByLang['fr']?.values[i] ?? '', [Validators.required, Validators.maxLength(50)]));
        this.idxMax++;
      }
    }

    console.log('this.configByLang after init', this.configByLang);
  }

  async closeModal(data: any = null, role: string = 'close') {
    await this.modalController.dismiss(data, role);
  }

  async openModalDeleteAnswer(idx: number) {
    // const props: any = {
    //   paramTitle: 'Voulez-vous vraiment supprimer la réponse ?',
    //   paramDescription: '',
    // };

    // const modal = await this.modalController.create({
    //   component: ModalConfirmComponent,
    //   componentProps: props,
    //   cssClass: 'modal-confirm-custom',
    // });

    // modal.onWillDismiss().then((dataReturned: any) => {
    //   if (dataReturned !== null && dataReturned.data === 'confirm' && this.answers.length > 1) {

    //   }
    // });
    // return await modal.present();

    if (this.answers.length > 2) {
      console.log('idx', idx);
      console.log('this.answers[idx]', this.answers);
      this.modalDataFormGroup.removeControl(this.answers[idx]);
      this.answers.splice(idx, 1);
    }
  }

  addAnswer() {
    this.idxMax++;
    this.modalDataFormGroup.addControl(`answer_${this.idxMax}`, this.fb.control('', [Validators.required, Validators.maxLength(50)]));
    this.answers.push(`answer_${this.idxMax}`);
    setTimeout(() => {
      const element = document.getElementById('answers-list');
      element.scrollTop = element.scrollHeight;
    }, 10);
  }

  getStatus(idx: number) {
    if (idx < this.currentLanguageIdx) {
      return 'completed';
    } else if (idx === this.currentLanguageIdx) {
      return 'current';
    }
    return 'waiting';
  }

  nextStep() {
    this.isSubmitted = true;
    if (this.modalDataFormGroup.invalid) return;

    this.setupQuestion();

    if (this.currentLanguageIdx + 1 === this.languages.length) {
      this.closeModal(this.configByLang, 'confirm');
    } else {
      this.currentLanguageIdx++;
      this.buildNewQuestion();
    }
  }

  prevStep() {
    if (this.currentLanguageIdx > 0) {
      this.setupQuestion();

      this.currentLanguageIdx--;
      this.buildNewQuestion();

    } else {
      this.closeModal();
    }
  }

  setupQuestion() {
    const final: UserFormQuestion = {
      question: this.modalDataFormGroup.get('question').value,
      fieldType: FieldTypeEnum.Radio as any,
      required: this.isRequired,
      values: this.answers.map((answer: string) => this.modalDataFormGroup.get(answer).value),
      rgpdDataLevel: 0
    };
    this.configByLang[this.languages[this.currentLanguageIdx]] = final;
  }

  buildNewQuestion() {
    if (this.configByLang[this.languages[this.currentLanguageIdx]]?.question) {
      this.modalDataFormGroup.get('question').setValue(this.configByLang[this.languages[this.currentLanguageIdx]].question);
    } else {
      this.modalDataFormGroup.get('question').setValue('');
    }

    this.answers.forEach((answer: string, idx: number) => {
      const value = this.configByLang[this.languages[this.currentLanguageIdx]]?.values[idx];

      this.modalDataFormGroup.get(answer).setValue(value ?? '');
    });
    this.isSubmitted = false;
  }
}
