/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

export enum ModalConfirmDisplayTemplate {
  DEFAULT = 'DEFAULT',
  FINAL_SAVE = 'FINAL_SAVE',
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {

  modalTitle: string = null;
  modalDescription: string = null;
  isDescriptionHTML: boolean = false;
  isTitleHTML: boolean = false;

  displayTemplate: string = ModalConfirmDisplayTemplate.DEFAULT;

  modalConfirmDisplayTemplate: typeof ModalConfirmDisplayTemplate = ModalConfirmDisplayTemplate;

  constructor(
    private _modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.modalTitle = this.navParams.data.paramTitle;
    if (this.navParams?.data?.paramDescription) this.modalDescription = this.navParams.data.paramDescription;
    if (this.navParams?.data?.isDescriptionHTML) this.isDescriptionHTML = this.navParams.data.isDescriptionHTML;
    if (this.navParams?.data?.isTitleHTML) this.isTitleHTML = this.navParams.data.isTitleHTML;
    if (this.navParams?.data?.displayTemplate) this.displayTemplate = this.navParams.data.displayTemplate;
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this._modalController.dismiss(onClosedData);
  }
}
