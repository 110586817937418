import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    }
  ]
})
export class InputSelectComponent implements ControlValueAccessor {
  @Input() formGroup: FormGroup = null;
  @Input() formControlName: string = null;
  @Input() autoSelectFirst: boolean = false;

  @Input() options: any[] = [];
  @Input() optionLabel: string = null;
  @Input() optionValue: string = null;
  @Input() optionDisabled: string = null;

  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();

  // Méthode appelée lorsque la valeur change
  onChange: any = () => {};
  onTouched: any = () => {};

  // Reçoit la nouvelle valeur du parent (formControl)
  writeValue(value: string): void {
    this.value = value;
  }

  // Enregistre la fonction à appeler lorsque la valeur change dans le formulaire
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Enregistre la fonction à appeler lorsque l'input est touché
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Retourne les erreurs de validation du contrôle
  validate(control: AbstractControl): ValidationErrors | null {
    // Si la valeur du contrôle est invalide, appliquez la logique d'erreur ici
    if (control.errors) {
      console.log('control.errors', control.errors);
      return control.errors;
    }
    return null;
  }

  // Appelée lorsque l'utilisateur tape dans l'input
  onSelectChange(input: any) {
    if (!this.value) return;

    this.valueChange.emit(this.value); // Informe le parent du changement de valeur

    this.onChange(this.value); // Informe Angular du changement de valeur
    this.onTouched();     // Marque le champ comme touché
  }
}
