import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@mocli/MocliCommonLib';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { SeekAndScan } from 'src/app/data/models/SeekAndScan';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
import { RandomHelper } from 'src/app/helpers/RandomHelper';
import { AuthService } from 'src/app/services/AuthService';

@Component({
  selector: 'app-find-place-configurator',
  templateUrl: './find-place-configurator.component.html',
  styleUrls: ['./find-place-configurator.component.scss'],
})
export class FindPlaceConfiguratorComponent implements OnInit {
  config: any;
  data: SeekAndScan;
  saved = false;
  inputDisabled: boolean = null;

  visualFile: File;
  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  findPlaceFormGroup: FormGroup;
  qrCodeSolution: string;

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private authSrv: AuthService,
  ) {}

  ngOnInit() {
    this.data = this.config.FindPlace;
    if (this.data?.mainImageUrl) {
      this.visualImageUrl = this.data.mainImageUrl;
    }
    this.data.showHintTimer = true;
    this.data.showSkipButton = true;

    if (!this.data.solution || this.data.solution.length === 0) {
      this.data.solution = RandomHelper.getRandomSeekAndScanCode();
    }

    this.findPlaceFormGroup = this.fb.group({
      hint: [
        {value: this.data?.hint ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      solutionDescription: [
        {value: this.data?.solutionDescription ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      solution: [
        {value: this.data?.solution ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  public configuratorComplete(): boolean {
    return this.findPlaceFormGroup.valid;
  }

  //attention cette méthode doit être la plus légère possible
  autoSave() {
    this.qrCodeSolution = (this.findPlaceFormGroup.get('solution').value as string).toUpperCase();

    this.isValidEventEmitter.emit(this.findPlaceFormGroup.valid);

    if (this.findPlaceFormGroup.invalid)
      return;

    this.data.hint = this.findPlaceFormGroup.get('hint').value;
    this.data.solutionDescription = this.findPlaceFormGroup.get('solutionDescription').value;
    this.data.solution = this.findPlaceFormGroup.get('solution').value;
  }

  async deleteFile() {
    if (this.uploadedImage?.name) {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
      this.data.mainImageUrl = '';
    } else if (this.visualImageUrl) {
      this.visualImageUrl = undefined;
    }
  }

  async onFileUpload(event) {
    const files = event.target.files;
    const file = files[0] as File;
    this.visualFile = file;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        firstValueFrom(uploadPromise).then(res => {
          if (res && res.url) {
            this.visualImageUrl = res.url;
            this.data.mainImageUrl = res.url;
          }
          this.uploadedImage = res;
        });
      } else {
        //max file size
        console.error('file > 5mb');
      }
    };

    reader.readAsArrayBuffer(file);
  }

  generateSolutionCode() {
    this.qrCodeSolution = RandomHelper.getRandomSeekAndScanCode();
    this.findPlaceFormGroup.get('solution').patchValue(this.qrCodeSolution);
  }
}
