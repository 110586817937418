import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm-contract-rgpd',
  templateUrl: './modal-confirm-contract-rgpd.component.html',
  styleUrls: ['./modal-confirm-contract-rgpd.component.scss']
})
export class ModalConfirmContractRGPDComponent implements OnInit {
  pdfSrc = 'https://cdn.mocli.fr/pdf/contrat-sous-traitance.pdf';
  safePdfSrc: SafeUrl = null;
  accepted = false;
  isContractAccepted = false;
  listenerAdded = false;

  isPreview: false;

  constructor(
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
    private navParams: NavParams,
  ) {

  }

  ngOnInit(): void {
    this.safePdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);

    if (this.navParams?.get('isPreview')) {
      this.isPreview = this.navParams.data.isPreview;
    }
  }

  async closeModal(data: any) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }
}
