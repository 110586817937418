export class SimpleQuiz {
    question: string;
    answers: SimpleQuizAnswer[];
    explanation: string;
    questionImageUrl: string;
    explainImageUrl: string;
    nextQuestionImageUrl: string;
    idleQuestionImageUrl: string;

    totalQuestions: number;
    //index de la question en cours
    index: number;
    quizType: number;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    constructor(hasDefaultSelectedAnswer: boolean = false) {
        this.question = '';
        this.explanation = '';
        this.questionImageUrl = '';
        this.explainImageUrl = '';
        this.nextQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
        this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-bulb.svg';
        this.totalQuestions = 0;
        this.index = 0;
        if (hasDefaultSelectedAnswer === true) {
            this.answers = [
                new SimpleQuizAnswer(true),
                new SimpleQuizAnswer(false),
            ];
        } else {
            this.answers = [
                new SimpleQuizAnswer(false),
                new SimpleQuizAnswer(false),
            ];
        }
    }
}

export class SimpleQuizAnswer {
    answer: string;
    correct: boolean;

    selected: boolean;

    constructor(isCorrect: boolean) {
        this.answer = '';
        this.correct = isCorrect;
    }
}
