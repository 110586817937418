import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from './features/shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { CorsInterceptor } from './helpers/interceptor/cors.interceptor';
import { ModalModule } from './features/shared/modal/modal.module';
import { AuthInterceptor } from './helpers/interceptor/auth.interceptor';
import { ScrollbarThemeModule } from './helpers/directives/ScrollBar.directive';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from './helpers/translate.custom';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PendingChangesGuard } from './helpers/pending-changes.guard';
import { AdminModule } from './features/admin/admin.module';
import { StatsManagementModule } from './features/admin/stats-management/stats-management.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ScannerCustomModule } from './features/shared/scanner-custom/scanner-custom.module';
import { MocliFrontLibModule } from '@mocli/MocliCommonLib';
import { MocliCommonLibConfig } from '@mocli/MocliCommonLib/lib/data/models/MocliCommonLibConfig';
import { environment } from 'src/environments/environment';
import { ConfiguratorPendingChangesGuard } from './helpers/configurator-pending-changes.guard';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AutoSaveService } from './services/AutoSaveService';
import { StepsService } from './features/steps/steps.service';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const libraryConfig: MocliCommonLibConfig = {
  apiUrl: environment.mainUrl,
  processingUrl: environment.processingUrl
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      },
      isolate: true
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    NgChartsModule,
    ColorPickerModule,
    SharedModule,
    ModalModule,
    ScrollbarThemeModule,
    BrowserAnimationsModule,
    ToastModule,
    DialogModule,
    DynamicDialogModule,
    MocliFrontLibModule.forRoot(libraryConfig)
  ],
  providers: [
    DialogService,
    CookieService,
    PendingChangesGuard,
    ConfiguratorPendingChangesGuard,
    MessageService,
    AutoSaveService,
    StepsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
