/* eslint-disable max-len */
import { Location } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import { HeaderBarConfig } from 'src/app/data/models/HeaderBarConfig';
import { Notifications } from 'src/app/data/models/Notifications';
import { AuthService } from 'src/app/services/AuthService';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { UserService } from '@mocli/MocliCommonLib';
import { RolesEnum } from 'src/app/data/enums/Roles.enum';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, OnDestroy {

  public headerConfig: HeaderBarConfig = null;

  public subscription: Subscription;
  public showNotifications: boolean = false;
  public showSettingsIdx: number = -1;

  userBalance: number = 0;
  userNotificationsAmount: number = 1;

  notificationsList: Notifications[] = [];

  hasToolTipDescription: boolean = false;


  private resizeSubject = new Subject();
  private resizeSubscription: Subscription;

  private newUserSubscription: Subscription;

  constructor(
    private location: Location,
    private headerBarService: HeaderBarService,
    private userSrv: UserService,
    private authSrv: AuthService,
    private router: Router
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.resizeSubject.next(event);
  }

  async ngOnInit() {
    this.subscription = this.headerBarService.onHeaderBarChanged.subscribe((header: HeaderBarConfig) => {
      if (header?.title !== this.headerConfig?.title && header?.secondTitle === this.headerConfig?.secondTitle) header.secondTitle = null;
      if (header?.title !== this.headerConfig?.title && header?.pageDescription === this.headerConfig?.pageDescription) header.pageDescription = null;
      if (header?.title !== this.headerConfig?.title && header?.description === this.headerConfig?.description) header.description = null;
      this.headerConfig = header;
      if (header?.expMode !== true) {
        if (header?.updateWallet) {
          firstValueFrom(this.userSrv.getUserBalance(this.authSrv?.userLogged?.value?.id)).then(balance => {
            this.userBalance = balance;
            if (!this.headerConfig) this.headerConfig = {title: '', showBackBtn: false};
            this.headerConfig.updateWallet = false;
          });
        }
      }
      this.updateDescription();

      this.resizeSubscription = this.resizeSubject.pipe(debounceTime(50)).subscribe(() => {
        this.handleResize();
      });
    });

    this.listenNewUser();
  }

  handleResize() {
    // Your code to run after the window has stopped resizing
    this.updateDescription();
  }

  updateDescription() {
    setTimeout(() => {
      const textContainer: any = document.querySelector('.page-description');
      const textContent: any = document.querySelector('.description-text');

      if (!textContainer || !textContent) {
        setTimeout(() => {
          this.updateDescription();
        }, 500);
        return;
      };
      textContainer.style.maxHeight = '60px'; // Set the initial maximum height

      // Adjust the number of lines based on container height
      if (textContainer.scrollHeight > textContainer.clientHeight) {
        this.hasToolTipDescription = true;
        textContent.style.webkitLineClamp = Math.floor(textContainer.clientHeight / parseFloat(getComputedStyle(textContent).lineHeight));
      } else {
        this.hasToolTipDescription = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
    if (this.newUserSubscription) this.newUserSubscription.unsubscribe();
  }

  listenNewUser() {
    this.newUserSubscription = this.authSrv.userLogged.subscribe(user => {

      if (!user || !user?.roles || user?.roles?.length <= 0) return;

      if (user.roles.includes(RolesEnum.admin)) {
        this.headerBarService.onHeaderBarChanged.next({ ...this.headerConfig, showAdmin: true, showWallet: true, updateWallet: true});
      } else {
        this.headerBarService.onHeaderBarChanged.next({ ...this.headerConfig, showAdmin: false, showWallet: false });
      }
    });
  }

  onBackBtnClick() {
    this.location.back();
  }

  onClickWallet() {
    this.router.navigate(['/book/buy']);
  }

  onClickNotifications() {
    this.showNotifications = !this.showNotifications;

    if (this.showNotifications === false) this.unreadNotifications();
  }

  unreadNotifications() {
    if (!this.notificationsList || this.notificationsList.length <= 0) return;

    this.notificationsList.forEach(element => {
      if (element.isNew) {
        element.isNew = false;
      }
    });
  }

  formatDate(date: DateTime) {
    const today = DateTime.now();

    if (date.year !== today.year) {
      const value = Math.abs(date.year - today.year);
      return 'il y a ' + value + ' an' + (value > 1 ? 's' : '');
    } else if (date.month !== today.month) {
      const value = Math.abs(date.month - today.month);
      return 'il y a ' + value + ' mois';
    } else if (date.day !== today.day) {
      const value = Math.abs(date.day - today.day);
      return 'il y a ' + value + ' jour' + (value > 1 ? 's' : '');
    } else if (date.hour !== today.hour) {
      const value = Math.abs(date.hour - today.hour);
      return 'il y a ' + value + ' heure' + (value > 1 ? 's' : '');
    } else if (date.minute !== today.minute) {
      const value = Math.abs(date.minute - today.minute);
      return 'il y a ' + value + ' minute' + (value > 1 ? 's' : '');
    } else {
      return 'à l’instant';
    }
  }

  onClickSettings(idx: number) {
    if (this.showSettingsIdx === idx) {
      this.showSettingsIdx = -1;
    } else {
      this.showSettingsIdx = idx;
    }
  }

  onClickNotificationBlock(idx: number) {
    if (!this.notificationsList || this.notificationsList.length <= idx) return;

    this.notificationsList[idx].isNew = false;
    this.notificationsList[idx].isRead = true;
  }

  onDeleteNotification(idx: number) {
    if (!this.notificationsList || this.notificationsList.length <= idx) return;

    this.notificationsList.splice(idx, 1);
    this.showSettingsIdx = -1;
  }

  getNewNotificationsCount() {
    if (!this.notificationsList || this.notificationsList.length <= 0) return 0;

    let count = 0;
    this.notificationsList.forEach(element => {
      if (element.isNew) {
        count++;
      }
    });

    return count;
  }

  onClickAdmin() {
    window.open('/admin', '_blank');
  }
}
