import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SidemenuService } from './SidemenuService';
import { RolesEnum } from '../data/enums/Roles.enum';
import { HttpService } from './HttpService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { ModalController } from '@ionic/angular';
import { ModalLoginComponent } from '../features/shared/modal/modal-login/modal-login.component';
import { Router } from '@angular/router';
import { MocliCommonLibConfigService } from '@mocli/MocliCommonLib';
import { PartialUserInfo } from '@mocli/MocliCommonLib/lib/data/models/UserInfo';

@Injectable({ providedIn: 'root' })
export class AuthService {

  public userLogged: BehaviorSubject<PartialUserInfo> = new BehaviorSubject(null);
  public launchTutorial: Subject<void> = new Subject<void>();

  public hasDoneTutorials: boolean = false;
  public isFromExperiences: boolean = false;
  public userNotActive: boolean = false;
  public isOnPopupLogin: boolean = false;

  public nextRoute: string = null;

  private requester: HttpService;



  constructor(
    private cookieService: CookieService,
    private sideMenuService: SidemenuService,
    private modalController: ModalController,
    private router: Router,
    private mocliCommonLibConfigService: MocliCommonLibConfigService,
    public client: HttpClient,
  ) {
    const endpoint = environment.mainUrl + '/api/User';
    this.requester = new HttpService(client, endpoint, this);
    if (!this.findUserCookies()) {
      this.sideMenuService.onSideMenuStateChanged.next(false);
    }
  }

  public newUserLogged(partialUserInfo: PartialUserInfo) {
    const cookieExpiry = DateTime.now().plus({ minutes: 240}).toJSDate();

    if (partialUserInfo?.token) {
      this.cookieService.delete('mocli-token');
      this.cookieService.set('mocli-token', partialUserInfo.token, cookieExpiry, '/');
      this.mocliCommonLibConfigService.setToken(partialUserInfo.token);

      const jwtParsed = this.parseJwt(partialUserInfo.token);

      if (jwtParsed?.role) {
        const roles = this.parseRoles(jwtParsed.role);

        if (roles) {
          partialUserInfo.roles = roles;
        }
      }
    }

    if (partialUserInfo?.id) {
      this.cookieService.delete('mocli-userId');
      this.cookieService.set('mocli-userId', partialUserInfo.id.toString(), null, '/');
    }

    if (partialUserInfo?.roles?.includes(RolesEnum.admin)) {
      this.cookieService.delete('mocli-exp-su');
      this.cookieService.set('mocli-exp-su', 'true', null, '/');
    }

    // eslint-disable-next-line max-len
    if (Object.keys(partialUserInfo).includes('hasOrdered') && partialUserInfo?.hasOrdered === true || !Object.keys(partialUserInfo).includes('hasOrdered')) {
      this.sideMenuService.onSideMenuStateChanged.next(true);
    } else {
      this.sideMenuService.onSideMenuStateChanged.next(false);
    }
    this.userLogged.next(partialUserInfo);
  }

  parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  parseRoles(rolesStr: string) {
    if (!rolesStr === null || typeof rolesStr !== 'string') return null;

    const roles = rolesStr?.split('|');
    const finalRoles = [];

    roles?.forEach(role => {
      if (role === 'Admin') {
        finalRoles.push(RolesEnum.admin);
      } else if (role === 'User') {
        finalRoles.push(RolesEnum.user);
      }
    });

    return finalRoles;
  }

  public async findUserCookies() {
    let condition = false;
    if (this.cookieService.check('mocli-token')) {
      this.newUserLogged({...this.userLogged.value, token: this.cookieService.get('mocli-token')});
      condition = true;
    }
    if (this.cookieService.check('mocli-userId'))
      this.newUserLogged({...this.userLogged.value, id: JSON.parse(this.cookieService.get('mocli-userId'))});

    if (this.cookieService.check('mocli-tuto-catalog'))
      this.hasDoneTutorials = (this.cookieService.get('mocli-tuto-catalog') === 'true');
    else
      this.hasDoneTutorials = false;

    return condition;
  }

  // public async isUserAdmin() {
  //   if (this.cookieService.check('mocli-exp-su') && this.cookieService.get('mocli-exp-su') === 'true') {
  //     const params = new HttpParams().append('userId', this.userLogged?.value?.id);
  //     const roles = await firstValueFrom(this.requester.getApi<RolesEnum[]>('GetUserRoles', params));

  //     if (roles?.indexOf(RolesEnum.admin) > -1) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  public disconnectUser() {
    this.cookieService.delete('mocli-token');
    this.cookieService.delete('mocli-userId');
    this.cookieService.delete('mocli-exp-su');
    this.hasDoneTutorials = false;
    this.userLogged.next(null);
  }

  public setTutorialCatalogDone() {
    this.hasDoneTutorials = true;
    this.cookieService.set('mocli-tuto-catalog', 'true', null, '/');
  }

  public async openPopupLogin(url: string) {
    // if (!this.userLogged?.value?.token || !this.userLogged?.value?.id) {
    //   this.router.navigateByUrl('/login');
    //   return;
    // };
    if (this.isOnPopupLogin === true) return;

    this.disconnectUser();
    this.isOnPopupLogin = true;

    const modal = await this.modalController.create({
      component: ModalLoginComponent,
      cssClass: 'modal-login',
      backdropDismiss: false,
    });

    modal.onWillDismiss().then(async (dataReturned: any) => {
      if (dataReturned?.data?.valid) {
        const finalUrl = this.nextRoute ? this.nextRoute : url;
        this.router.navigateByUrl(finalUrl).then(() => {
          this.nextRoute = null;
          setTimeout(() => {
            this.sideMenuService.onSideMenuStateChanged.next(this.userLogged?.value?.hasOrdered);
            window.location.reload();
          }, 100);
        });
      }
      this.isOnPopupLogin = false;
    });

    return await modal.present();
  }
}
