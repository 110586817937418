<div class="stickers-content">
    <div class="title">Contenu du pack de {{codesCount}} stickers</div>
    <ng-container *ngFor="let sticker of stickerRepartition">
      <div class="sticker">
        <div class="sticker-title">{{sticker.name}}</div>
        <div class="sticker-amount">
          <div class="label">Quantité :</div>
          <div class="value">{{sticker.quantity}}</div>
        </div>
        <div class="sticker-score">
          <div class="label">Score :</div>
          <div class="value">{{sticker.points}} points</div>
        </div>
        <div class="sticker-example" *ngIf="sticker?.imageUrl" (click)="openExampleModal(sticker?.imageUrl)">Voir un exemple</div>
      </div>
    </ng-container>
  </div>