<div class="order-validate">
  <div class="cross" (click)="closeModal('cross')">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="X">
  </div>
  
  <div class="image-container">
    <img [src]="experience?.imageUrl ?? '../../.../../../../../assets/experience-img-offline/chasse-au-tresor_big.png'" alt="">
  </div>

  <div class="title-container">
    <div class="title">
      Félicitations, l'achat de votre expérience
    </div>
    <p>{{experience && experience.name ? experience.name : "Nom de l'expérience"}}</p>
    <div class="title">
      est validé !
    </div>
  </div>
  <div class="description">
    Il ne vous reste plus que quelques étapes pour<br>configurer les paramètres et pouvoir la démarrer.
  </div>
  <div class="btn-configure-now mocli-button" (click)="closeModal('confirm')">
    Configurer Maintenant
  </div>
  <div class="later" (click)="closeModal('later')">
    Me le rappeler plus tard
  </div>
</div>