/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { FieldTypeEnum } from '@mocli/MocliCommonLib/lib/data/enums/FieldTypeEnum';
import { UserFormQuestion } from '@mocli/MocliCommonLib/lib/data/models/UserFormQuestion';

@Component({
  selector: 'app-modal-data-required',
  templateUrl: './modal-data-required.component.html',
  styleUrls: ['./modal-data-required.component.scss'],
})
export class ModalDataRequiredComponent implements OnInit {
  isRequired: boolean = false;
  fieldName: string = null;
  isSubmitted: boolean = false;

  isEdit: boolean = false;
  languages: string[] = ['fr'];
  configByLang: any = [];

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.languages) {
      this.languages = this.navParams.data.languages;
    }

    if (this.navParams?.data?.fields) {
      this.isEdit = true;

      this.navParams.data.fields.forEach((field, idx) => {
        this.configByLang[this.languages[idx]] = field;
      });
      this.isRequired = this.configByLang['fr']?.required;
    }
  }

  async closeModal(data: any = null, role: string = 'close') {
    await this.modalController.dismiss(data, role);
  }

  confirm() {
    this.isSubmitted = true;

    this.languages.forEach((lang) => {
      this.configByLang[lang].required = this.isRequired;
    });

    this.closeModal(this.configByLang, 'confirm');
  }

}
