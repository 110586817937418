<div class="mocli-box-content configure-question">
  <form [formGroup]="seekAndScanForm">
    <!-- Titre -->
    <div class="question-info">
      <div class="big-title title">Indice {{data.index + 1}}</div>
      <div class="question-content">
        <textarea formControlName="hint"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': seekAndScanForm.controls.hint.invalid, 'disabled-input': inputDisabled}">
                </textarea>
        <div class="question-content-counter">
          <span *ngIf="seekAndScanForm.controls.hint.invalid" class="invalid-input-text">
            <span *ngIf="seekAndScanForm.controls.hint?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="seekAndScanForm.controls.hint?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{seekAndScanForm.controls.hint.value.length}} / 300
          </span>
        </div>
      </div>
    </div>

    <!-- Visuel -->
    <div class="visuel">
      <div class="visuel-title">
        <div class="title">Indice visuel</div>
        <div class="optional">(optionnel)</div>
      </div>
      <div *ngIf="!visualImageUrl">
        <input #inputFile 
              [id]="'inputFile-' + data?.index"
              type="file"
              name="file"
              class="inputfile"
              accept="image/*"
              (change)="onFileUpload($event)">
        <label [for]="'inputFile-' + data?.index" *ngIf="!inputDisabled" class="add-file">Joindre un fichier</label>
      </div>
      <div *ngIf="visualImageUrl" class="visual-name-container">
        <div class="delete">
          <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
        </div>
      </div>
    </div>
    <div *ngIf="visualImageUrl" class="preview-container">
      <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
    </div>

    <!-- Explication -->
    <div class="explain" style="margin-top: 15px;">
      <div class="explain-title">
        <div class="title">Explication : emplacement du QR code</div>
      </div>
      <div class="explain-content">
        <textarea formControlName="solutionDescription"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': seekAndScanForm.controls.solutionDescription.invalid, 'disabled-input': inputDisabled}">
        </textarea>
        <div class="explain-content-counter">
          <span *ngIf="seekAndScanForm.controls.solutionDescription.invalid" class="invalid-input-text">
            <span *ngIf="seekAndScanForm.controls.solutionDescription?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="seekAndScanForm.controls.solutionDescription?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{seekAndScanForm.controls.solutionDescription.value.length}} / 300
          </span>
        </div>
      </div>
    </div>

    <!-- Solution -->
    <div class="explain" style="margin-top: 15px;">
      <div class="explain-title">
        <div class="title">Code de solution</div>
      </div>
      <div class="explain-content inline-button">
        <input type="text" 
              pInputText
              formControlName="solution"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': seekAndScanForm.controls.solution.invalid, 'disabled-input': inputDisabled}">
        <div class="explain-content-counter">
          <span *ngIf="seekAndScanForm.controls.solution.invalid" class="invalid-input-text">
            <span *ngIf="seekAndScanForm.controls.solution?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="seekAndScanForm.controls.solution?.errors?.required">Champ requis. </span>
            <span *ngIf="seekAndScanForm.controls.solution?.errors?.pattern">Caractères alphanumériques uniquement. </span>
          </span>
        </div>
      </div>


      <div class="explain" style="margin-top: 15px;">
        <div class="explain-title">
          <div class="title">QR code de solution</div> 
        </div>
        <div class="explain-content inline-button">
          <qrcode [qrdata]="qrCodeSolution"></qrcode>
        </div>
      </div>
    </div>
  </form>
</div>