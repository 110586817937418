import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfirmDialogService } from './confirm-dialog.service';

export interface ConfiguratorComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;

  saveSubject: Subject<void>;
  changesSubject: BehaviorSubject<boolean>;
  isCompleteSubject: BehaviorSubject<boolean>;
}

@Injectable()
export class ConfiguratorPendingChangesGuard implements CanDeactivate<ConfiguratorComponentCanDeactivate> {

    constructor(
        private confirmDialogService: ConfirmDialogService,
    ) {

    }

    canDeactivate(
        component: ConfiguratorComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        const res = component.canDeactivate();
        const changesSubject = component.changesSubject;
        const saveSubject = component.saveSubject;
        const isCompleteSubject = component.isCompleteSubject;
        if (res === true) return res;


        // return false;
        return (this.confirmDialogService.openConfirmationModal({
            nextUrl: nextState.url,
            changesSubject,
            saveSubject,
            isCompleteSubject
        }), false);
    }
}
