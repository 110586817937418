/* eslint-disable @typescript-eslint/ban-types */
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';
import { BackgroundShape } from '../data/models/BackgroundShape';

export class keyFunction {
  //subElementName de la page
  key: string;
  //si plusieurs page du même type alors on spécifie l'index (on peut utiliser celui de la configuration)
  index?: number;
  //fonction à appeler
  fn: Function;
}

@Injectable({ providedIn: 'root' })
export class GameSessionService {
  public orderId: number;
  public currentOrder: any;

  public swiperDelegate: SwiperComponent;
  public contentDelegate: IonContent;

  //on peut ajouter des fonctions dans cette variable avec comme clé le subElementName (voir CompTypeHelper)
  // elles seront alors appelées à chaque slideChange
  public slideChangeFunctions: keyFunction[] = [];

  //id unique du joueur en cours
  public uuid: string;

  public score: number = 0;
  //le total potentiel du score. Exemple s'il y a 3 questions totalScore = 3 mais score va suivre le nombre de bonne réponses
  public totalScore: number = 0;

  style: HTMLLinkElement;

  isExamplePhone: boolean = false;

  userIdentifier: string = null;
  userToken: string = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
  ){}


  getRootContent() {
    let rootElem = null;
    if (this.currentOrder.configuration.MultiPageRoot) {
      rootElem = this.currentOrder.configuration.MultiPageRoot;
    } else if (this.currentOrder.configuration.CodeHuntRoot) {
      rootElem = this.currentOrder.configuration.CodeHuntRoot;
    } else if (this.currentOrder.configuration.configuration) {
      rootElem = this.currentOrder.configuration;
    }

    return rootElem;
  }

  getExperienceTitle() {
    const root = this.getRootConfiguration();
    return root?.title;
  }

  getRootConfiguration() {
    return this.getRootContent().configuration;
  }

  addScore(scoreValue: number, scoretotalValue: number) {
    this.score += scoreValue;
    this.totalScore += scoretotalValue;
  }

  setRootConfiguration(config: any) {
    if (this.currentOrder?.configuration) {
      this.currentOrder.configuration = config;
    }
  }

  getScore() {
    return {score: this.score, totalScore: this.totalScore};
  }

  disconnectUser() {
    this.userToken = null;
    this.userIdentifier = null;
    this.cookieService.delete('mocli-experience-token');
    this.cookieService.delete('mocli-experience-identifier');
  }

  /* ------------ Gestion du thème ------------ */

  loadStyle(styleUrl: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;


    if (themeLink) {
      themeLink.href = styleUrl;
    } else {
      this.style = this.document.createElement('link');
      this.style.id = 'client-theme';
      this.style.rel = 'stylesheet';
      this.style.crossOrigin = 'anonymous';
      this.style.href = styleUrl;

      head.appendChild(this.style);
    }
  }

  changeStyle(styleUrl: string) {
    this.style.href = styleUrl;
  }
}
