export class GuessClosest {
    question: string;
    answerUnitHint: string;
    answerUnitHintPlural: string;
    answer: number;
    explanation: string;
    questionImageUrl: string;

    nextQuestionImageUrl: string;
    idleQuestionImageUrl: string;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    totalQuestions: number;
    index: number;

    constructor() {
        this.question = '';
        this.answerUnitHint = '';
        this.answerUnitHintPlural = '';
        this.answer = 0;
        this.explanation = '';
        this.questionImageUrl = '';
        this.nextQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-bulb.svg';
        this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-measure.svg';
        this.totalQuestions = 0;
        this.index = 0;
    }
}
