/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-modal-confirm-prime',
  templateUrl: './modal-confirm-prime.component.html',
  styleUrls: ['./modal-confirm-prime.component.scss']
})
export class ModalConfirmPrimeComponent implements OnInit {
  dynamicRoute: string = null;
  changesSubject: BehaviorSubject<boolean>;
  saveSubject: Subject<{ref: any; nextUrl: string}>;
  isCompleteSubject: BehaviorSubject<boolean>;;

  constructor(
    public ref: DynamicDialogRef,
    private router: Router,
    private route: ActivatedRoute,
    private config: DynamicDialogConfig
  ) {

  }

  ngOnInit() {
    if (this.config?.data?.nextUrl) this.dynamicRoute = this.config.data.nextUrl;
    if (this.config?.data?.changesSubject !== null) {
      this.changesSubject = this.config.data.changesSubject;
    }
    if (this.config?.data?.saveSubject !== null) {
      this.saveSubject = this.config.data.saveSubject;
    }
    if (this.config?.data?.isCompleteSubject !== null) {
      this.isCompleteSubject = this.config.data.isCompleteSubject;
    }
  }

  cancel() {
    this.ref.close();
  }

  leave() {
    this.changesSubject.next(false);
    this.ref.close();
    this.router.navigate([this.dynamicRoute]);
  }

  saveAndLeave() {
    if (this.saveSubject && this.isCompleteSubject?.value === true) this.saveSubject?.next({ref: this.ref, nextUrl: this.dynamicRoute});
  }

}
