/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Type } from '@angular/core';
import { CodeHunt } from '../data/models/CodeHunt';
import { CodeHuntConfiguratorComponent } from '../features/configure/shared-exp-configurator/code-hunt-configurator/code-hunt-configurator.component';
import { FindPlaceConfiguratorComponent } from '../features/configure/shared-exp-configurator/find-place-configurator/find-place-configurator.component';
import { GuessClosestConfiguratorComponent } from '../features/configure/shared-exp-configurator/guess-closest-configurator/guess-closest-configurator.component';
import { HowManyConfiguratorComponent } from '../features/configure/shared-exp-configurator/how-many-configurator/how-many-configurator.component';
import { MysteryIngredientQuizConfiguratorComponent } from '../features/configure/shared-exp-configurator/mystery-ingredient-quiz-configurator/mystery-ingredient-quiz-configurator.component';
import { OddOneOutConfiguratorComponent } from '../features/configure/shared-exp-configurator/odd-one-out-configurator/odd-one-out-configurator.component';
import { SeekAndScanConfiguratorComponent } from '../features/configure/shared-exp-configurator/seek-and-scan-configurator/seek-and-scan-configurator.component';
import { SimpleQuizConfiguratorComponent } from '../features/configure/shared-exp-configurator/simple-quiz-configurator/simple-quiz-configurator.component';
import { TastingQuizConfiguratorComponent } from '../features/configure/shared-exp-configurator/tasting-quiz-configurator/tasting-quiz-configurator.component';
import { ThePriceIsRightConfiguratorComponent } from '../features/configure/shared-exp-configurator/the-price-is-right-configurator/the-price-is-right-configurator.component';
import { TheWeightIsRightConfiguratorComponent } from '../features/configure/shared-exp-configurator/the-weight-is-right-configurator/the-weight-is-right-configurator.component';
import { TrueOrFalseConfiguratorComponent } from '../features/configure/shared-exp-configurator/true-or-false-configurator/true-or-false-configurator.component';
import { Feedback } from '../data/models/Feedback';
import { GuessClosest } from '../data/models/GuessClosest';
import { HowMany } from '../data/models/HowMany';
import { OddOneOut } from '../data/models/OddOneOut';
import { SeekAndScan } from '../data/models/SeekAndScan';
import { SimpleQuiz, SimpleQuizAnswer } from '../data/models/SimpleQuiz';
import { ThePriceOrWeightIsRight } from '../data/models/ThePriceOrWheightIsRight';
import { TrueOrFalse } from '../data/models/TrueOrFalse';
import { Outro } from '../data/models/Outro';
import { LanguagePicker } from '../data/models/LanguagePicker';
import { IntroPage } from '../data/models/Intro';
import { LandingPage, LandingPageConfiguratorComponent } from '../features/configure/shared-exp-configurator/landing-page-configurator/landing-page-configurator.component';
import { CodeHuntDashboard, CodeHuntLeaderboard, CodeHuntPrizes, CodeHuntQuizList, CodeHuntScanner, IntroAuth, LoginAuth, MissingPasswordAuth, RegisterAuth } from '../data/models/ModularElements';

export interface ModularElement {
  configuratorType?: Type<any>;
  // componentType: Type<any>;

  //retourne un objet correctement entouré de son type englobant. Attention le constructeur n'est appellé qu'une fois.
  dataClassType: any;

  onePage?: boolean;
  descriptionKey?: string;
}

interface ModularElementConfiguration {
  [key: string]: ModularElement;
}


export interface GenericElement {
    configuratorType: Type<any>;
    componentType: Type<any>;

    //retourne un objet correctement entouré de son type englobant. Attention le constructeur n'est appellé qu'une fois.
    dataClassType: any;
    subElementName: string;
    //si cet element peut participer à un suivi de score
    scorable: boolean;

    onePage?: boolean;
    descriptionKey?: string;
}

interface GenericElementConfiguration {
  [key: string]: GenericElement;
}


export const NonContentTypeObjects = [
  'IntroPage',
  'Feedback',
  'Outro',
  'LanguagePicker',
];

export const ModularTypeObjects = [
  'IntroAuth',
  'LoginAuth',
  'MissingPasswordAuth',
  'RegisterAuth',
  'CodeHuntLeaderboard',
  'CodeHuntScanner',
  'CodeHuntQuizList',
  'CodeHuntPrizes',
  'CodeHuntDashboard',
  'LandingPage'
];

export enum ModularConfigTypes {
  CODEHUNT = 'CodeHunt',
  CODEHUNT_QUIZ = 'CodeHuntQuiz',
  QUIZ_LEADERBOARD = 'QuizLeaderboard',
  LANDING_PAGE = 'LandingPage',
};

//Classes qui nous permettent de faire le lien entre les composants de configuration et de présentation d'experiences
export class CompTypeHelper {
  static compTypeConfig: GenericElementConfiguration = {
    // MultiPageRoot: {
    //   configuratorType: undefined,
    //   componentType:    MultiPageRootComponent,
    //   dataClassType:    { MultiPageRoot: new MultiPageRoot() },
    //   subElementName:   'Page'
    // },
    SimpleQuiz: {
      configuratorType:  SimpleQuizConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ SimpleQuiz: new SimpleQuiz() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'simpleQuizDescription',
    },
    TastingQuiz: {
      configuratorType:  TastingQuizConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ TastingQuiz: new SimpleQuiz() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'tastingQuizDescription',
    },
    MysteryIngredientQuiz: {
      configuratorType:  MysteryIngredientQuizConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ MysteryIngredientQuiz: new SimpleQuiz() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'mysteryIngredientQuizDescription',
    },
    IntroPage: {
      configuratorType: undefined,
      componentType:    null,
      dataClassType:    () => ({ IntroPage: new IntroPage() }),
      subElementName:   'Introduction',
      scorable:         false,
    },
    LanguagePicker: {
      configuratorType: undefined,
      componentType:    null,
      dataClassType:    () => ({ LanguagePicker: new LanguagePicker() }),
      subElementName:   'Choix de langue',
      scorable:         false,
    },
    GuessClosest: {
      configuratorType:  GuessClosestConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ GuessClosest: new GuessClosest() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'guessClosestDescription',
    },
    Feedback: {
      configuratorType: undefined,
      componentType:    null,
      dataClassType:    () => ({ Feedback: new Feedback() }),
      subElementName:   'Avis',
      scorable:         false,
    },
    Outro: {
      configuratorType: undefined,
      componentType:    null,
      dataClassType:    () => ({ Outro: new Outro() }),
      subElementName:   'Outro',
      scorable:         false,
    },
    SeekAndScan: {
      configuratorType: SeekAndScanConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ SeekAndScan: new SeekAndScan() }),
      subElementName:   'Indice',
      scorable:         true,
      descriptionKey:   'seekAndScanDescription',
    },
    TheWeightIsRight: {
      configuratorType:  TheWeightIsRightConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ TheWeightIsRight: new ThePriceOrWeightIsRight() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'theWeightIsRightDescription',
    },
    ThePriceIsRight: {
      configuratorType:  ThePriceIsRightConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ ThePriceIsRight: new ThePriceOrWeightIsRight() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'thePriceIsRightDescription',
    },
    OddOneOut: {
      configuratorType:  OddOneOutConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ OddOneOut: new OddOneOut() }),
      subElementName:   'Question',
      scorable:         true,
      descriptionKey:   'oddOneOutDescription',
    },
    TrueOrFalse: {
      configuratorType:  TrueOrFalseConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ TrueOrFalse: new TrueOrFalse() }),
      subElementName:   'Question',
      scorable:         true,
    },
    HowMany: {
      configuratorType:  HowManyConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ HowMany: new HowMany() }),
      subElementName:   'Question',
      scorable:         true,
      onePage:          true,
    },
    FindPlace: {
      configuratorType:  FindPlaceConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ FindPlace: new SeekAndScan() }),
      subElementName:   'Question',
      scorable:         true,
    },
    LandingPage: {
      configuratorType:  LandingPageConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ LandingPage: new LandingPage() }),
      subElementName:   'Expérience',
      scorable:         false,
    }
  };

  static codeHuntConfig: GenericElementConfiguration = {
    CodeHunt: {
      configuratorType:  CodeHuntConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ CodeHunt: new CodeHunt() }),
      subElementName:   'Périmètre + Indice',
      scorable:         true,
      onePage:          true,
      descriptionKey:   'codeHuntDescription'
    },
    CodeHuntQuiz: {
      configuratorType:  CodeHuntConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ CodeHunt: new CodeHunt({isCodeHuntQuiz: true}) }),
      subElementName:   'Périmètre + Indice',
      scorable:         true,
      onePage:          true,
      descriptionKey:   'codeHuntDescription'
    },
    QuizLeaderboard: {
      configuratorType:  CodeHuntConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ CodeHunt: new CodeHunt({isCodeHuntQuiz: true, isQuizLeaderboard: true}) }),
      subElementName:   'Périmètre + Indice',
      scorable:         true,
      onePage:          true,
      descriptionKey:   'codeHuntDescription'
    },
    LandingPage: {
      configuratorType:  LandingPageConfiguratorComponent,
      componentType:    null,
      dataClassType:    () => ({ LandingPage: new LandingPage() }),
      subElementName:   'Périmètre + Indice',
      scorable:         true,
      onePage:          true,
      descriptionKey:   'landingPageDescription'
    }
  };

  static modularConfig: ModularElementConfiguration = {
    IntroAuth: {
      dataClassType:    () => ({ IntroAuth: new IntroAuth() }),
    },
    LoginAuth: {
      dataClassType:    () => ({ LoginAuth: new LoginAuth() }),
    },
    MissingPasswordAuth: {
      dataClassType:    () => ({ MissingPasswordAuth: new MissingPasswordAuth() }),
    },
    RegisterAuth: {
      dataClassType:    () => ({ RegisterAuth: new RegisterAuth() }),
    },
    CodeHuntLeaderboard: {
      dataClassType:    () => ({ CodeHuntLeaderboard: new CodeHuntLeaderboard() }),
    },
    CodeHuntScanner: {
      dataClassType:    () => ({ CodeHuntScanner: new CodeHuntScanner() }),
    },
    CodeHuntPrizes: {
      dataClassType:    () => ({ CodeHuntPrizes: new CodeHuntPrizes() }),
    },
    CodeHuntDashboard: {
      dataClassType:    () => ({ CodeHuntDashboard: new CodeHuntDashboard() }),
    },
    CodeHuntQuizList: {
      dataClassType:    () => ({ CodeHuntQuizList: new CodeHuntQuizList() }),
    },
    LandingPage: {
      dataClassType:    () => ({ LandingPage: new LandingPage() }),
    }
  };

  public static getModularComponentConfig(content: any[]) {
    if (!content || content?.length <= 0) return null;

    const modularConfigTypes = Object.values(ModularConfigTypes);
    if (!modularConfigTypes || modularConfigTypes?.length <= 0) return null;

    let component = null;
    let configFinal = null;
    modularConfigTypes.forEach((configType: any) => {
      if (component === null) {
        component = content.find((c) => c && c[configType] !== undefined);
        configFinal = configType;
      }
    });
    if (component) return component[configFinal];

    return null;
  }

  public static getGenericElementConfiguration(obj: object): GenericElement {
    if(!obj) {
      return {
        configuratorType: null,
        componentType: null,
        dataClassType: null,
        subElementName: null,
        scorable: false,
      };
    }
    const name = Object.entries(obj)[0][0].toString();
    let final = this.compTypeConfig[name];
    if (final === undefined) final = this.codeHuntConfig[name];
    return final;
  }

  public static isNonContentType(objName: object) {
    const obj = objName as any;
    let condition = false;
    NonContentTypeObjects.forEach(element => {
      if (obj?.hasOwnProperty(element)) {
        condition = true;
      }
    });
    return condition;
  }

  //permet de vérifier si un this.config.content à un élément par son type (de configuration)
  // Pour voir s'il y a un Feedback il faut passer ElementTypeName: Feedback (pas FeedbackComponent)
  public static findElementIndex(contents: any[], elementTypeName: string): number {
    const elemNames = contents.map(e => Object.entries(e)[0][0]);
    return elemNames.findIndex(e => e === elementTypeName);
  }

  // public static getGenericConfigOfflineData(generic: GenericElement): any {
  //   if (generic.componentType === SimpleQuizComponent) {
  //     const data = new SimpleQuiz();
  //     data.question = 'A quelle minute a été inscrit le 1er but du match de ce soir ?';
  //     data.explanation = 'But marqué à la 18e minute par Zinedine Zidane, son 35e de la saison en Ligue 1.';
  //     data.totalQuestions = 1;
  //     data.answers = [
  //       {
  //         answer: '13e minute', correct: false,
  //       } as SimpleQuizAnswer,
  //       {
  //         answer: '18e minute', correct: true,
  //       } as SimpleQuizAnswer,
  //       {
  //         answer: '22e minute', correct: false,
  //       } as SimpleQuizAnswer
  //     ];
  //     return { SimpleQuiz: data };
  //   } else if (generic.componentType === SeekAndScanComponent) {
  //     const data = new SeekAndScan();
  //     data.hint = 'Le coffre peut-il lui aussi se trouver dans un coffre ? Leur couleur peut-elle être la même ?';
  //     data.solution = 'AA01';
  //     data.solutionDescription = 'Juste en dessous de la grande horloge. code = AA01';
  //     data.totalQuestions = 1;
  //     return { SeekAndScan: data };
  //   } else if (generic.componentType === GuessClosestComponent) {
  //     const data = new GuessClosest();
  //     data.question = 'Quelle est la distance entre la Terre et le Soleil ?';
  //     data.explanation = 'La distance moyenne entre la Terre et le Soleil est de 149 597 870 km.';
  //     data.answerUnitHint = 'km';
  //     data.answerUnitHintPlural = 'km';
  //     data.answer = 149597870;
  //     data.totalQuestions = 1;
  //     return { GuessClosest: data };
  //   } else if (generic.componentType === ThePriceOrWeightIsRightComponent) {
  //     const data = new ThePriceOrWeightIsRight();
  //     data.maxAttempts = 5;
  //     data.name = 'Quel est le prix de départ du Volkswagen Tiguan ?';
  //     data.value = 39135;
  //     data.unit = '€';
  //     data.unitPlural = '€';
  //     data.totalQuestions = 1;
  //     data.questionImageUrl = 'https://cdn.discordapp.com/attachments/780857327896494102/1023966807599632474/unknown.png';
  //     return { ThePriceOrWeightIsRight: data };
  //   } else if (generic.componentType === OddOneOutComponent) {
  //     const data = new OddOneOut();
  //     data.name = 'Quelle attraction n’existe pas dans le parc ?';
  //     data.objects = ['Le bateau pirate', 'La grande pente', 'Le silver hole', 'Le cheval de deux', 'La descente verte', 'La chute de sang', 'Le train fantôme'];
  //     data.intruder = 'Le cheval de deux';
  //     data.explain = 'Malheureusement cette attraction inspirée du cheval de Troie ne fait pas encore parti du parc !';
  //     data.totalQuestions = 1;
  //     return { OddOneOut: data };
  //   } else if (generic.componentType === TrueOrFalseComponent) {
  //     const data = new TrueOrFalse();
  //     data.question = 'Il existe une journée mondiale des toilettes.';
  //     data.answer = true;
  //     data.explain = 'C’est vrai, et elle a lieu chaque année le 9 novembre.';
  //     data.totalQuestions = 1;
  //     return { TrueOrFalse: data };
  //   } else if (generic.componentType === HowManyComponent) {
  //     const data = new HowMany();
  //     data.searchArea = 'Tout au long de la file d’attente, des stickers sont dispersés sur votre chemin.';
  //     data.totalStickers = 23;
  //     data.totalQuestions = 1;
  //     return { HowMany: data };
  //   } else if (generic.componentType === CodeHuntComponent) {
  //     const data = new CodeHunt();
  //     return { CodeHunt: data };
  //   }
  //   else {
  //     return null;
  //   }
  // }
}
