<div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
  <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
</div>

<div *ngIf="calendarFormGroup && !showExtend && !showExtendSuccess" id="statsModalCustomPeriod" class="stats-period-container" [formGroup]="calendarFormGroup">
  <div class="calendar-container">
    <div class="calendar" style="margin-left: 15px;">
      <h1>Date de départ</h1>
      <p-calendar styleClass="dateSelectorClass" formControlName="dateStart" [inline]="true"></p-calendar>
      <div class="date-container">
        <h3>{{dateStart ? dateStart.toLocaleDateString() : 'Non sélectionée'}}</h3>
      </div>
    </div>
    <div class="calendar" style="margin-right: 15px;">
      <h1>Date de fin</h1>
      <p-calendar [minDate]="dateStart" styleClass="dateSelectorClass" formControlName="dateEnd" [inline]="true"></p-calendar>
      <div class="date-container">
        <h3>{{dateEnd ? dateEnd.toLocaleDateString() : 'Non sélectionée'}}</h3>
      </div>
    </div>
  </div>
  <div class="errorMessage">{{daysAmountSelected}} jour{{daysAmountSelected > 1 ? 's' : ''}} sélectionné{{daysAmountSelected > 1 ? 's' : ''}} sur les {{maxDays}} disponibles</div>
  <div class="controls">
    <div class="mocli-button button cancel" (click)="closeModal('cancel')">Annuler</div>
    <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="confirmRange()">Confirmer</div>
  </div>
</div>

<div *ngIf="showExtend" class="extend-container">
  <div class="text">
    La durée maximale actuelle de votre<br>expérience est de {{maxDays}} jours.<br>Souhaitez-vous la prolonger de {{daysToPay}} jours ?
  </div>
  <div class="info">
    Coût : {{cost}} crédits<br>
  </div>
  <div class="balance">
    <div class="balance-text">Solde actuel : </div>
    <div class="balance-value" [ngClass]="{'balance-value-insufficient': cost > actualBalance}">
      {{actualBalance > cost ? (actualBalance + ' crédits') : 'insuffisant'}}
    </div>
  </div>
  <div *ngIf="cost > actualBalance; else cgu" class="buyCredits">
    Si vous souhaitez acheter des crédits, cliquez <a (click)="onBuyCreditsClick()">ici</a>
  </div>
  <ng-template #cgu>
    <div class="cgu">
      Je comprends qu'en cliquant sur prolonger je serai débité de crédits sur mon compte.
    </div>
  </ng-template>
  <div class="controls">
    <div class="mocli-button button cancel" (click)="cancelDates()">Annuler</div>
    <div *ngIf="cost <= actualBalance" class="mocli-button button confirm" style="margin-left: 10px;" (click)="confirmExtend()">Prolonger</div>
  </div>
</div>

<div *ngIf="showExtendSuccess" class="extend-success-container">
  <div class="content">
    <div class="text">Prolongation effectuée</div>
    <div class="icon-check">
      <img src="https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg" alt="icon checked">
    </div>
  </div>
  <div class="mocli-button button confirm" (click)="closeModal('confirm')">Fermer</div>
</div>