import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@mocli/MocliCommonLib';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { HowMany } from 'src/app/data/models/HowMany';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
import { AuthService } from 'src/app/services/AuthService';

@Component({
  selector: 'app-how-many-configurator',
  templateUrl: './how-many-configurator.component.html',
  styleUrls: ['./how-many-configurator.component.scss'],
})
export class HowManyConfiguratorComponent implements OnInit {
  howManyForm: FormGroup;
  config: any;
  data: HowMany;
  inputDisabled: boolean = null;

  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private authSrv: AuthService,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.data = this.config.HowMany;

    this.visualImageUrl = this.data?.questionImageUrl;
    this.uploadedImage.url = this.visualImageUrl;
    this.uploadedImage.name = this.visualImageUrl.substring(20);

    this.howManyForm = this.fb.group({
      totalStickers: [
        {value: this.data?.totalStickers ?? '', disabled: this.inputDisabled},
        [Validators.required]
      ],
      searchArea: [
        {value: this.data?.searchArea ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  autoSave() {
    this.isValidEventEmitter.emit(this.howManyForm.valid);

    if (this.howManyForm.invalid)
      return;

    this.data.questionImageUrl = this.uploadedImage?.url ?? '';

    this.data.totalStickers = this.howManyForm.get('totalStickers').value;
    this.data.searchArea = this.howManyForm.get('searchArea').value;
  }

  async deleteFile() {
    if (this.uploadedImage?.name) {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    }
  }

  async onFileUpload(event) {
    const files = event.target.files;
    const file = files[0] as File;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        firstValueFrom(uploadPromise).then(res => {
          this.visualImageUrl = res.url;
          this.uploadedImage = res;
        });
      } else {
        //max file size
        console.error('file > 5mb');
      }
    };

    reader.readAsArrayBuffer(file);
  }
}
