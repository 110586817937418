<div class="mocli-box-content configure-question">
<!-- <div class="mocli-box-content configure-question custom-top" [ngClass]="{'custom-top': customTop === true}"> -->
  <form [formGroup]="quizForm">
    <!-- <div class="type-selector-container">
      <div class="visuel-title title">Format de question</div>
      <p-dropdown  id="categories-selector">
        <ng-template let-type pTemplate="item">
          <div>{{ type.label }}</div>
        </ng-template>
      </p-dropdown>
      
    </div> -->
    
    <!-- Selecteur de type -->
    <div class="quiz-form-block" id="mInput_select-160px">
      <app-input-label label="Format de question"></app-input-label>
      <p-dropdown
        (onChange)="onSelectType($event)"
        [options]="quizTypesList"
        [optionValue]="'value'"
        formControlName="questionType"
        placeholder="Choisir un format..."
      ></p-dropdown>
    </div>

    <div class="quiz-form-block">
      <app-input-textarea
        [inputFormGroup]="quizForm"
        [inputFormControlName]="'question'"
        [placeholder]="baseConfig?.question ? ('À traduire : ' + baseConfig?.question) : 'Écrire ici...'"
        [maxLength]="300"
        [maxConsecutiveNewLines]="2"
        [isFormSubmitted]="isSubmitted"
        [label]="'Question ' + (data.index + 1)"
      ></app-input-textarea>
    </div>
  </form>
    
  <!-- Réponses -->
  <div class="answer-top" style="margin-bottom: 0;">
    <div class="visuel-title" style="margin-bottom: 0;">
      <div class="title">Réponses</div>
      <div class="optional">(Entre 2 et 4 réponses)</div>
    </div>
    <div class="title-correct" *ngIf="selectedQuizType !== quizTypesEnum.POLL" [ngClass]="{'title-correct-error': isSubmitted && hasAnswersError() }">Réponse correcte</div>
    <div *ngIf="answersForms.length < 2"></div>
  </div>

  <div *ngIf="data?.answers" class="answers">
    <ng-container *ngFor="let answer of answersForms; let i = index">
      <form [formGroup]="answer">
        <div class="answer">
          <div class="index">{{i+1}}</div>

          <div class="first">
            <app-input-text
              [inputFormGroup]="answer"
              [isFormSubmitted]="isSubmitted"
              [inputFormControlName]="'answer'"
              [placeholder]="baseConfig?.answers?.length > i && baseConfig?.answers[i]?.answer ?  ('À traduire : ' + baseConfig?.answers[i]?.answer) : 'Écrire ici...'"
              [maxLength]="75"
            ></app-input-text>
          </div>
          
          <div class="delete" >
            <img *ngIf="isBaseLanguage && data?.answers && data?.answers?.length > 2"
                src="https://cdn.mocli.fr/icon/delete.svg"
                (click)="openModalDeleteAnswer(i)" alt="delete">
          </div>

          <div class="second" *ngIf="selectedQuizType !== quizTypesEnum.POLL">
            <app-radio-input [disable]="inputDisabled || this.selectedLanguage !== 'fr'" [checked]="answer.controls.correct.value" (click)="inputDisabled || this.selectedLanguage !== 'fr' ? '' : onSelectCorrectAnswer(i)"></app-radio-input>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <!-- <div *ngIf="data?.answers && selectedQuizType !== quizTypesEnum.BOOLEAN && !inputDisabled" (click)="addAnswer()" class="add-answer" [style]="data?.answers?.length >= 4 ? 'cursor: not-allowed !important;' : ''"> -->
  <div *ngIf="data?.answers && !inputDisabled && data?.answers?.length < 4 && baseConfig === null" (click)="addAnswer()" class="add-answer" [style]="data?.answers?.length >= 4 ? 'cursor: not-allowed !important;' : ''">
    <div class="add-answer-add">+</div>
    <div class="add-answer-content">Ajouter une réponse</div>
  </div>

  <!-- Visuel -->
  <div class="visuel">
    <div class="visuel-title">
      <div class="title">Visuel</div>
      <div class="optional">(optionnel, 5 Mo max)</div>
    </div>
    <div *ngIf="!visualImageUrl && selectedLanguage === 'fr'">
      <input #inputFile 
            [id]="'inputFile-' + data?.index"
            type="file"
            name="file"
            class="inputfile"
            accept="image/*"
            (change)="onFileUpload($event)">
      <label [for]="'inputFile-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrl && selectedLanguage === 'fr'" class="visual-name-container">
      <div class="delete">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrl" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
  </div>

  <!-- Explication -->
  <!-- <div class="explain" *ngIf="selectedQuizType !== quizTypesEnum.POLL">
    <form [formGroup]="quizForm">
      <div class="title-container">
        <div class="explain-title">
          <div class="title">Explication</div>
          <div class="optional">(optionnelle)</div>
        </div>
        <div class="question-content-counter">
          <span *ngIf="quizForm.controls.explanation.invalid && isSubmitted" class="invalid-input-text">
            <span *ngIf="quizForm.controls.explanation?.errors?.required">Champs requis. </span>
            <span *ngIf="quizForm.controls.explanation?.errors?.maxlength">Taille maximale atteinte. </span>
          </span>
          <span>Caractères : {{quizForm.controls.explanation.value.length}} / 300</span>
        </div>
      </div>
      <div class="explain-content">
        <textarea [rows]="3" 
                  formControlName="explanation"
                  [placeholder]="baseConfig?.explanation ? ('À traduire : ' + baseConfig?.explanation) : 'Écrire ici...'"
                  [ngClass]="{'invalid-input': quizForm.controls.explanation.invalid && isSubmitted, 'disabled-input': inputDisabled}">
        </textarea>
        
      </div>
    </form>
  </div> -->

  <div class="quiz-form-block explain" *ngIf="selectedQuizType !== quizTypesEnum.POLL" style="margin-top: 20px;">
    <app-input-textarea
      [inputFormGroup]="quizForm"
      [inputFormControlName]="'explanation'"
      [placeholder]="baseConfig?.explanation ? ('À traduire : ' + baseConfig?.explanation) : 'Écrire ici...'"
      [maxLength]="300"
      [isFormSubmitted]="isSubmitted"
      label="Explication"
      labelDescription="(optionnelle)"
    ></app-input-textarea>
  </div>

  <!-- Visuel de l'explication -->
  <div class="visuel" *ngIf="selectedQuizType !== quizTypesEnum.POLL">
    <div class="visuel-title">
      <div class="title">Visuel de l'explication</div>
      <div class="optional">(optionnel, 5 Mo max)</div>
    </div>
    <div *ngIf="!visualImageUrlExplain && selectedLanguage === 'fr'">
      <input #inputFileExplain 
            [id]="'inputfileExplain-' + data?.index"
            type="file"
            name="file"
            class="inputfileExplain"
            accept="image/*"
            (change)="onFileUpload($event, 'explain')">
      <label [for]="'inputfileExplain-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrlExplain && selectedLanguage === 'fr'" class="visual-name-container">
      <div class="delete">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile('explain')" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrlExplain" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrlExplain" alt="" class="preview">
  </div>
</div>