/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CodeHuntHint } from 'src/app/data/models/CodeHunt';
import { GameSessionService } from 'src/app/services/GameSessionService';

const everyHintsKey = 'everyHintsKey';

@Component({
  selector: 'app-modal-hint',
  templateUrl: './modal-hint.component.html',
  styleUrls: ['./modal-hint.component.scss'],
})
export class ModalHintComponent implements OnInit {
  @Output() nextHintClick: EventEmitter<any> = new EventEmitter();
  @Output() hintIndexShown: EventEmitter<number> = new EventEmitter();
  @Output() onSelectHintCategory: EventEmitter<string> = null;

  baseHints: CodeHuntHint[] = [];
  filteredHints: CodeHuntHint[] = [];
  hintShown: any = null;
  hintShownTotal: number = 0;
  hintTotal: number = 0;
  hintContent: string = 'Regardez là où coule le café, blabla pour remplir 3 lignes et voir si ça passe';
  nextHintImageUrl: string = null;
  iconCloseImageUrl: string = null;
  iconBackImageUrl: string = null;

  userCodes: string[] = [];
  hasChanges: boolean = false;

  oneCategoryOnly: boolean = false;
  hasMultiCategories: boolean = false;
  categories: string[] = [];
  selectedCategory: string = null;

  everyHintsKey: string = everyHintsKey;

  constructor(
    private modalController: ModalController,
    private gameSessionSrv: GameSessionService,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    const rootConfig = this.gameSessionSrv.getRootConfiguration();
    this.nextHintImageUrl = rootConfig?.nextHintImageUrl ?? 'https://cdn.mocli.fr/icon/ch-magnifier-2.png';
    this.iconCloseImageUrl = rootConfig?.iconCloseImageUrl ?? 'https://cdn.mocli.fr/icon/cross.svg';
    this.iconBackImageUrl = rootConfig?.iconBackImageUrl ?? 'https://cdn.mocli.fr/icon/back-btn-clear.svg';
    if (this.navParams?.data?.userCodes) {
      this.userCodes = this.navParams.data.userCodes;
    }
    if (this.navParams?.data?.hasMultiCategories) {
      this.categories = this.navParams.data.multiCategories;
      this.hasMultiCategories = true;
    }
    if (this.navParams?.data?.selectedCategory) {
      this.selectedCategory = this.navParams.data.selectedCategory;
    }
    if (this.navParams?.data?.onSelectHintCategory) {
      this.onSelectHintCategory = this.navParams.data.onSelectHintCategory;
    }
    if (this.navParams?.data?.hints) {
      this.baseHints = this.navParams.data.hints;
      this.selectRandomHint();
    };

    if (this.categories?.length <= 0) return;

    let condition = true;
    this.categories.forEach(category => {
      if (this.categoryHintLength(category) > 0) condition = false;
    });
    if (condition) {
      this.oneCategoryOnly = true;
      this.selectedCategory = this.everyHintsKey;
      this.selectRandomHint();
    }
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }

  selectRandomHint() {
    if (this.hasMultiCategories && !this.selectedCategory) return;

    if (this.baseHints?.length <= 0) return;

    const finalArray = [];
    const hintShownCount = 0;

    this.baseHints.forEach(hint => {
      let condition = true;

      // check if hint is in selected category or if selected category is 'everyHintsKey'
      if (this.hasMultiCategories && hint.category !== this.selectedCategory && this.selectedCategory !== everyHintsKey) condition = false;

      // check if hint is in userCodes or if hint status is false
      if (this.userCodes.includes(hint?.code) || (Object.keys(hint).includes('status') && hint.status === false)) condition = false;

      // check if hint is already shown
      // if (hint.shown === true) {
      //   if (condition) hintShownCount++;
      //   condition = false;
      // }

      // if every conditions are false, push hint in finalArray
      if (condition) finalArray.push(hint);
    });
    this.filteredHints = [...finalArray];

    this.hintShownTotal = hintShownCount + 1;
    this.hintTotal = this.filteredHints.length + this.hintShownTotal - 1;

    // no overlapping
    this.hintShownTotal = this.hintShownTotal > this.hintTotal ? this.hintTotal : this.hintShownTotal;

    // select random index from our filtered array of hints
    const randomIndex = Math.floor(Math.random() * this.filteredHints.length);
    this.hintShown = this.filteredHints[randomIndex];
    if (this.hintShown) this.hintShown.shown = true;

    // DEV MODE ONLY : show feedback modal at end of hints
    // if (this.hintShownTotal === this.hints.length) {
    //   this.closeModal('showFeedback');
    // }
  }

  restartHints() {
    this.baseHints.forEach(hint => {
      // if (hint && hint.shown) hint.shown = false;
    });
    this.selectRandomHint();
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.onSelectHintCategory.emit(this.selectedCategory);
    this.baseHints.forEach(hint => {
      // hint.shown = false;
    });
    this.selectRandomHint();
  }

  onBackBtnClick() {
    this.selectedCategory = null;
    this.onSelectHintCategory.emit(null);
  }

  firstLetterUppercase(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  categoryHintLength(category: string) {
    let count = 0;
    this.baseHints.forEach(hint => {
      let condition = true;
      if (hint.category !== category) condition = false;
      if (this.userCodes.includes(hint?.code) || (Object.keys(hint).includes('status') && hint.status === false)) condition = false;

      if (condition) count++;
    });
    return count;
  }
}
