import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../services/AuthService';
import { UserService } from '@mocli/MocliCommonLib';
import { RolesEnum } from '@mocli/MocliCommonLib/lib/data/enums/Roles.enum';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const roles = route.data.roles as Array<RolesEnum>;

    if (roles.length === 0) {
      firstValueFrom(this.aRoute.data).then(d => {
        console.error('roles empty trying to sub to route', d);
      });
    }

    if(!this.authService.userLogged || !this.authService.userLogged.value?.id) {
      this.router.navigateByUrl('/projects');
      return false;
    }

    let userRoles = null;
    try {
      userRoles = await firstValueFrom(this.userService.getUserRoles(this.authService.userLogged.value.id));
    } catch (error) {
      console.error('error getting user roles on role.guard.ts l:34', error);
      return false;
    }

    let hasRole = false;

    if (userRoles && userRoles.length > 0) {
      userRoles.forEach(role => {
        if (roles.includes(role))
          hasRole = true;
      });

      if (hasRole)
        return true;

      this.router.navigateByUrl('/projects');
      return false;
    } else {
      this.router.navigateByUrl('/projects');
      return false;
    }
  }
}
