/* eslint-disable max-len */
import { CodeHuntCodeStatus } from 'src/app/features/configure/shared-exp-configurator/code-hunt-configurator/codehunt-content-editor/codehunt-content-editor.component';

/* eslint-disable max-len */
export class CodeHunt {
  totalCodes: number;
  codesPoints: number;
  //la somme de tous les scores de tous les codes
  maximumScore: number;
  searchArea: string;

  introImageUrl: string;
  questionImageUrl: string;
  idleQuestionImageUrl: string;
  hintAccessIconUrl: string;
  scanIconUrl: string;

  //Si on veux personnaliser le mot 'Sticker' qu'on affiche on peut le faire via cette variable
  // example pour paques on veux potentiellement avoir des 'Oeufs' à chercher
  stickerWord: string;
  stickerWordPlural?: string;

  //si on veux se login avec le mail et avoir le process de changement de mdp
  //si false on affiche pas la récupération de mdp
  useMailLogin: boolean;

  // si on veux afficher la modal de statistiques CSAT/NPS
  displayFeedback: boolean;

  // Pour que feedbackScanCount et feedbackDate que soient utilisées, il faut que displayFeedback soit à true
  // si on veux que la modal soit affichée après un certain nombre de scan
  feedbackScanCount: number;
  // si on veux que la modal soit affichée après une certaine date
  feedbackDate: Date;

  exampleStickers: string[] = [];

  hasAnswered: boolean;
  hasCorrectlyAnswered: boolean;
  codes: CodeHuntCode[] = [];

  index: number;

  introText: string;
  loginText: string;
  registerText: string;
  afterRegisterText: string;
  missingPasswordTextFirst: string;
  missingPasswordTextSecond: string;
  missingPasswordTextThird: string;
  newAccountText: string;
  hints: CodeHuntHint[];
  multiCategories: string[];

  iconCloseGuideIconUrl: string;
  previousGuideIconUrl: string;
  nextGuideIconUrl: string;
  exampleGuideImageUrl: string;
  demonstrativeAdjective: string;
  specialMessage: string;
  hasMultiCategories: boolean;

  conditionsId: string;

  isCodeHuntQuiz: boolean;
  isQuizLeaderboard: boolean;

  subExpAnswerScore: number;
  subExpStreakBonus: boolean;

  structureUpdated: boolean;

  constructor(config: any = null) {
    this.totalCodes = 0;
    this.codesPoints = 100;
    this.maximumScore = 0;
    this.useMailLogin = true;
    this.searchArea = '';
    this.introImageUrl = 'https://cdn.mocli.fr/exp/mocli-header-path.png';
    this.questionImageUrl = '';
    this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
    this.hintAccessIconUrl = 'https://cdn.mocli.fr/icon/random.png';
    this.scanIconUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
    this.stickerWord = 'QR Code';
    this.stickerWordPlural = 'QR Codes';
    this.demonstrativeAdjective = 'ce';
    this.index = 0;
    this.hasAnswered = false;
    this.introText = 'Partez à la recherche des QR Codes cachés dans ce lieu. En les scannant, marquez des points et grimpez dans le classement.';
    this.loginText = 'Connectez-vous pour accéder à votre score';
    this.registerText = 'Veuillez remplir le formulaire pour finaliser votre inscription.';
    this.afterRegisterText = 'Merci pour votre inscription, un email de confirmation vient de vous être envoyé, vous n’avez pas besoin de valider cet email pour démarrer votre participation.';
    this.displayFeedback = false;
    this.missingPasswordTextFirst = 'Veuillez saisir votre adresse mail pour recevoir un code de récupération :';
    this.missingPasswordTextSecond = 'Veuillez saisir le code reçu par email :';
    this.missingPasswordTextThird = 'Veuillez saisir votre nouveau code PIN :';
    this.newAccountText = 'Merci pour votre inscription, un email de confirmation vient de vous être envoyé, vous n’avez pas besoin de valider cet email pour démarrer votre participation.';
    this.feedbackScanCount = null;
    this.feedbackDate = null;
    this.hints = [];
    this.codes = [];
    this.iconCloseGuideIconUrl = 'https://cdn.mocli.fr/icon/cross.svg';
    this.previousGuideIconUrl = 'https://cdn.mocli.fr/icon/previous.svg';
    this.nextGuideIconUrl = 'https://cdn.mocli.fr/icon/next.svg';
    this.exampleGuideImageUrl = 'https://cdn.mocli.fr/icon/exemple-sticker.png';
    this.specialMessage = null;
    this.hasMultiCategories = false;
    this.structureUpdated = false;
    this.multiCategories = [];
    this.conditionsId = null;
    this.subExpAnswerScore = 100;

    //TODO: Images par défaut
    this.exampleStickers = ['', '', ''];

    if (config !== null && config?.isCodeHuntQuiz === true) {
      this.isCodeHuntQuiz = true;
    } else {
      this.isCodeHuntQuiz = false;
    }

    if (config !== null && config?.isQuizLeaderboard === true) {
      this.isQuizLeaderboard = true;
    } else {
      this.isQuizLeaderboard = false;
    }
  }
}

export class CodeHuntCode {
  code: string;
  points: number;
  isUsed?: boolean;
  subExpConfig?: any;
  visible?: boolean;
  hint?: CodeHuntHint;
  label?: string;

  subExpActive?: boolean;
  periods?: {startDate: string; endDate: string}[];
  status?: CodeHuntCodeStatus;

  constructor() {
    this.code = '';
    this.points = 1;
    this.isUsed = false;
    this.visible = true;
    this.hint = null;
    this.label = null;
    this.subExpActive = true;
    this.periods = [];
  }
}

export interface CodeHuntHint {
  content: string;
  index: number;
  code?: string;
  location?: string;
  status?: boolean;
  category?: string;
}

export enum CodeHuntViews {
  Intro = 'intro',
  Login = 'login',
  MissingPassword = 'missing-password',
  Register = 'register',
  Code = 'code',
  LeaderBoard = 'leaderboard',
  Dashboard = 'dashboard',
  Scan = 'scan',
  Prize = 'prize',
  Example = 'example',
}
