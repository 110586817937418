import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RolesEnum } from './data/enums/Roles.enum';
import { NotfoundComponent } from './features/notfound/notfound.component';
import { NotOrderedGuard } from './helpers/not-ordered.guard';
import { RoleGuard } from './helpers/role.guard';
import { TestUserPageComponent } from './features/admin/test-user-page/test-user-page.component';
import { AuthGuard } from './helpers/auth.guard';
import { AdditionalOptionsGuard } from './helpers/additional-options.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    title: 'Connexion'
  },
  {
    path: 'register',
    loadChildren: () => import('./features/register/register.module').then(m => m.RegisterModule),
    title: 'Inscription'
  },
  {
    path: 'verify',
    loadChildren: () => import('./features/verify/verify.module').then(m => m.VerifyModule),
    title: 'Vérification'
  },
  {
    path: 'forgotPassword',
    loadChildren: () => import('./features/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    title: 'Mot de passe oublié'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Tableau de bord'
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Administration'
  },
  {
    path: 'experience',
    loadChildren: () => import('./features/experience/experience.module').then( m => m.ExperienceModule),
    canActivate: [NotOrderedGuard, AuthGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Expérience'
  },
  {
    path: 'experiences',
    loadChildren: () => import('./features/experiences/experiences.module').then( m => m.ExperiencesModule),
    canActivate: [NotOrderedGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Liste de jeux'
  },
  {
    path: 'book',
    loadChildren: () => import('./features/book-experience/book-experience.module').then( m => m.BookExperienceModule),
    canActivate: [NotOrderedGuard, AuthGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Réservation'
  },
  {
    path: 'password',
    loadChildren: () => import('./features/update-password/update-password.module').then( m => m.UpdatePasswordModule),
    canActivate: [AuthGuard],
    title: 'Mise à jour du mot de passe'
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then( m => m.ProfileModule),
    canActivate: [AuthGuard],
    title: 'Profil'
  },
  // {
  //   path: 'order',
  //   loadChildren: () => import('./features/experience-order/order.module').then( m => m.OrderModule),
  //   canActivate: [AuthGuard],
  //   title: 'Commande'
  // },
  {
    path: 'favorites',
    loadChildren: () => import('./features/favorites/favorites.module').then( m => m.FavoritesModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [RolesEnum.admin]},
    title: 'Mes favoris'
  },
  {
    path: 'projects',
    loadChildren: () => import('./features/projects/projects.module').then( m => m.ProjectsModule),
    canActivate: [AuthGuard],
    title: 'Mes jeux'
  },
  {
    path: 'stats',
    loadChildren: () => import('./features/stats/stats.module').then( m => m.StatsModule),
    canActivate: [AuthGuard],
    title: 'Statistiques'
  },
  {
    path: 'configure',
    loadChildren: () => import('./features/experience-order/order.module').then( m => m.OrderModule),
    canActivate: [AuthGuard],
    title: 'Configuration de la commande'
  },
  {
    path: 'customize',
    loadChildren: () => import('./features/configure/configure.module').then( m => m.ConfigureModule),
    canActivate: [AuthGuard],
    title: 'Configuration de l\'expérience'
  },
  {
    path: 'data',
    loadChildren: () => import('./features/data/data.module').then( m => m.DataModule),
    canActivate: [AuthGuard, AdditionalOptionsGuard],
    title: 'Captation de données'
  },
  {
    path: 'prize',
    loadChildren: () => import('./features/prize/prize.module').then( m => m.PrizeModule),
    canActivate: [AuthGuard, AdditionalOptionsGuard],
    title: 'Récompenses'
  },
  {
    path: 'test-user',
    canActivate: [RoleGuard],
    data: {roles: [RolesEnum.admin]},
    component: TestUserPageComponent
  },
  {
    path: '**',
    component: NotfoundComponent,
    title: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
