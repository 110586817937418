export class SeekAndScan {
    hint: string;
    solution: string;
    solutionDescription: string;

    secondsUntilPass: number;

    mainImageUrl: string;
    nextPageImageUrl: string;
    scanButtonImageUrl: string;

    showHintTimer: boolean;
    showSkipButton: boolean;

    totalQuestions: number;
    index: number;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;
    hasForcePassed: boolean;

    constructor() {
        this.hint = '';
        this.solution = '';
        this.solutionDescription = '';

        this.mainImageUrl = '';
        this.secondsUntilPass = 60;

        this.nextPageImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-chest.svg';
        this.scanButtonImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-chest.svg';

        this.showHintTimer = false;
        this.showSkipButton = false;

        this.totalQuestions = 0;
        this.index = 0;
    }
}
