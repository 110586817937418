<div class="hint-modal ch-background-color__modal">

  <div *ngIf="hasMultiCategories && !selectedCategory && !oneCategoryOnly" class="hint-categories-container">
    <div class="top-hint" style="margin-bottom: 10px !important">
      <div class="empty"></div>
      <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
        <img [src]="iconCloseImageUrl" alt="close modal">
      </div>
    </div>
    
    <div class="hint-categories-title ch-color__primary color-custom">{{'hint-categories-title' | translate}}</div>
    <div class="hint-categories ch-color__primary color-custom">
      <div class="big-border-custom hint-category ch-color__tertiary" [ngClass]="{'hint-category-selected': selectedCategory === everyHintsKey}" (click)="selectCategory(everyHintsKey)">{{'hint-back-modal' | translate}}</div>
      <ng-container *ngFor="let category of categories">
        <div *ngIf="categoryHintLength(category) > 0" class="big-border-custom hint-category ch-color__tertiary " (click)="selectCategory(category)">
          <div class="hint-category-title">{{firstLetterUppercase(category)}}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!hasMultiCategories || (hasMultiCategories && selectedCategory) || oneCategoryOnly" class="hint-main">
    <div class="top-hint" [style]="'margin-bottom: ' + (hasMultiCategories ? '18px !important;' : '10px !important;')">
      <div *ngIf="hasMultiCategories && !oneCategoryOnly" class="back-btn" (click)="onBackBtnClick()">
        <div class="back-icon-container">
          <img [src]="iconBackImageUrl" alt="back">
        </div>
        <div class="ch-color__primary color-custom back-text">
          {{'back' | translate}}
        </div>
      </div>
      <div *ngIf="!hasMultiCategories || oneCategoryOnly" class="empty"></div>
      <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
        <img [src]="iconCloseImageUrl" alt="close modal">
      </div>
    </div>
    
    <div class="hint-title ch-color__primary color-custom">{{'hint-title' | translate}} {{hintShownTotal}}/{{hintTotal}} :</div>
    <div class="hint-content" *ngIf="hintShown" [innerHtml]="hintShown?.content"></div>
    <div class="hint-next" *ngIf="hintShown && hintTotal > 1" (click)="selectRandomHint()">
      <div class="image-container">
        <img [src]="nextHintImageUrl" alt="next hint magnifier">
      </div>
      <div class="text ch-color__primary color-custom">{{'hint-discover' | translate}}</div>
    </div>
  
    <div class="no-hint" *ngIf="!hintShown">
      <ng-container *ngIf="hintTotal > 0">
        <div class="title ch-color__tertiary color-custom">
          {{'hint-finish' | translate}}.
        </div>
        <div class="restart text ch-color__primary color-custom" (click)="restartHints()">
          {{'hint-restart' | translate}}
        </div>
      </ng-container>

      <ng-container *ngIf="hintTotal <= 0">
        <div class="title">
          {{'hint-no-hint' | translate}}
        </div>
      </ng-container>
    </div>
  </div>
</div>
