<ion-content>
  <div class="modal-preview">
    <div class="icon-close" (click)="closeModal('cancel')" style="cursor: pointer;">
      <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
    </div>
    <div class="fake-phone">
      <svg class="ios-notch" viewBox="0 0 219 31">
        <path d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z"
          fill-rule="evenodd"></path>
      </svg>
      <div #phoneContent></div>
      <div class="centered" *ngIf="showPhoneError">Il y a eu une erreur lors du chargement de l’exemple d’expérience.<br>Merci de réessayer.</div>
    </div>
  </div>
</ion-content>