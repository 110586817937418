import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { debounceTime } from 'rxjs';
import { ExperienceInfo } from 'src/app/data/models/ExperienceInfo';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-edit-demo',
  templateUrl: './modal-edit-demo.component.html',
  styleUrls: ['./modal-edit-demo.component.scss'],
})
export class ModalEditDemoComponent implements OnInit {
  experience: ExperienceInfo;
  editFormGroup: FormGroup;
  qrCodeSolution: string = null;
  isSubmitted: boolean = false;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initFormGroup();
    if (this.navParams.data && this.navParams.data.experience) this.experience = this.navParams.data.experience;
  }

  getOrderRouteUrl(id: number): string {
    return environment.experienceUrl + '/e/' + id;
  }

  initFormGroup() {
    this.editFormGroup = this.fb.group({
      demoId: [null, Validators.required],
    });

    this.editFormGroup.get('demoId').valueChanges.pipe(debounceTime(500))
    .subscribe((data) => {
      if (!data) {
        this.qrCodeSolution = null;
        return;
      };
      this.qrCodeSolution = this.getOrderRouteUrl(data);
    });
  }

  confirmEdit() {
    this.isSubmitted = true;
    if (this.editFormGroup.invalid) return;
    this.closeModal('confirm');
  }

  async closeModal(data: string) {
    const onClosedRole: string = data;

    const onClosedData: any = {
      demoId: this.editFormGroup?.get('demoId')?.value ?? null,
    };

    await this.modalController.dismiss(onClosedData, onClosedRole);
  }

}
