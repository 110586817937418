<div class="loader">
  <div *ngIf="!ready">
    {{'camera-opening' | translate}}
  </div>
  <ion-spinner *ngIf="!ready" name="crescent"></ion-spinner>
</div>

<div class="scanner-container">
  <!-- Scanner -->
  <div class="video-container">
    <video id="camera-view"></video>
  </div>

  <!--Switch Cam -->
  <div *ngIf="showSwitchCamBtn && ready" class="switch-cam-btn" (click)="switchCamera()">
    <div class="icon icon-switch"></div>
  </div>

  <div class="error" *ngIf="errorMessage">{{errorMessage}}</div>

  <div class="target-container">
    <div class="horizontal"></div>
    <div class="vertical"></div>
  </div>
</div>