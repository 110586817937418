/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
// import { RgpdDataLevelEnum } from '@mocli/MocliCommonLib/lib/data/enums/RgpdDataLevelEnum';
import { UserFormQuestion } from '@mocli/MocliCommonLib/lib/data/models/UserFormQuestion';
import { FieldTypeEnum } from 'src/app/data/enums/FieldTypeEnum';
import { ModalConfirmComponent } from '../../modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-modal-data-checkbox',
  templateUrl: './modal-data-checkbox.component.html',
  styleUrls: ['./modal-data-checkbox.component.scss'],
})
export class ModalDataCheckboxComponent implements OnInit {
  isRequired: boolean = false;
  charCount: number = 0;

  isSubmitted: boolean = false;

  modalDataFormGroup: FormGroup = null;

  isEdit: boolean = false;

  languages: string[] = ['fr'];
  currentLanguageIdx: number = 0;
  configByLang: any = [];

  stepStatusLabels = {
    completed: 'Complété',
    current: 'En cours',
    waiting: 'En attente',
  };

  stepStatusColors = {
    completed: '#6CC729',
    current: '#0075F1',
    waiting: '#CECECE',
  };

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.languages) {
      this.languages = this.navParams.data.languages;
    }

    if (this.navParams?.data?.fields) {
      this.isEdit = true;

      this.navParams.data.fields.forEach((field, idx) => {
        this.configByLang[this.languages[idx]] = field;
      });
      this.isRequired = this.configByLang['fr']?.required;
    }


    this.modalDataFormGroup = this.fb.group({
      question: [this.isEdit && this.configByLang['fr']?.question ? this.configByLang['fr']?.question : '', [Validators.required, Validators.maxLength(200)]],
    });
  }

  async closeModal(data: any = null, role: string = 'close') {
    await this.modalController.dismiss(data, role);
  }

  getStatus(idx: number) {
    if (idx < this.currentLanguageIdx) {
      return 'completed';
    } else if (idx === this.currentLanguageIdx) {
      return 'current';
    }
    return 'waiting';
  }

  nextStep() {
    this.isSubmitted = true;
    if (this.modalDataFormGroup.invalid) return;

    this.setupQuestion();

    if (this.currentLanguageIdx + 1 === this.languages.length) {
      this.closeModal(this.configByLang, 'confirm');
    } else {
      this.currentLanguageIdx++;
      if (this.configByLang[this.languages[this.currentLanguageIdx]]?.question) {
        this.modalDataFormGroup.get('question').setValue(this.configByLang[this.languages[this.currentLanguageIdx]].question);
      } else {
        this.modalDataFormGroup.get('question').setValue('');
      }
      this.isSubmitted = false;
    }
  }

  prevStep() {
    if (this.currentLanguageIdx > 0) {
      this.setupQuestion();

      this.currentLanguageIdx--;
      if (this.configByLang[this.languages[this.currentLanguageIdx]]?.question) {
        this.modalDataFormGroup.get('question').setValue(this.configByLang[this.languages[this.currentLanguageIdx]].question);
      } else {
        this.modalDataFormGroup.get('question').setValue('');
      }
    } else {
      this.closeModal();
    }
  }

  setupQuestion() {
    const final: UserFormQuestion = {
      question: this.modalDataFormGroup.get('question').value,
      fieldType: FieldTypeEnum.Checkbox as any,
      required: this.isRequired,
      rgpdDataLevel: 0
    };
    this.configByLang[this.languages[this.currentLanguageIdx]] = final;
  }
}
