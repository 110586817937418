<div class="edit-demo-container">
  <div class="demo" style="margin-top: 15px;">
    <div class="demo-title">
      <div class="title">ID de l'expérience de démonstration</div>
    </div>
    <div class="demo-content inline-button" *ngIf="editFormGroup" [formGroup]="editFormGroup" style="position: relative;">
      <input type="number" 
            pInputText
            formControlName="demoId"
            [ngClass]="{'invalid-input': editFormGroup.controls.demoId.invalid && isSubmitted}">
      <div class="demo-content-counter">
        <span *ngIf="editFormGroup.controls.demoId.invalid && isSubmitted" class="invalid-input-text">
          <span *ngIf="editFormGroup.controls.demoId?.errors?.required && isSubmitted">Champ requis. </span>
        </span>
      </div>
    </div>


    <div class="demo demo-qr" *ngIf="qrCodeSolution" style="margin-top: 25px;">
      <div class="demo-title">
        <div class="title">QR code de l'expérience</div> 
      </div>
      <div class="demo-contentinline-button">
        <qrcode [qrdata]="qrCodeSolution"></qrcode>
      </div>
    </div>
  </div>

  <div class="controls">
    <div class="mocli-button button cancel" (click)="closeModal('close')">Annuler</div>
    <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="confirmEdit()">Confirmer</div>
  </div>
</div>