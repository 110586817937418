import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-text-info',
  templateUrl: './modal-text-info.component.html',
  styleUrls: ['./modal-text-info.component.scss'],
})
export class ModalTextInfoComponent implements OnInit {
  text: string = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.text) {
      this.text = this.navParams.data.text;
    } else {
      this.closeModal('error');
    }
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }

}
