import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CodeHuntPlayer } from 'src/app/data/models/CodeHuntPlayer';
import { GameSessionService } from 'src/app/services/GameSessionService';

@Component({
  selector: 'app-modal-codehunt-guide',
  templateUrl: './modal-codehunt-guide.component.html',
  styleUrls: ['./modal-codehunt-guide.component.scss'],
})
export class ModalCodehuntGuideComponent implements OnInit {
  iconCloseImageUrl: string = null;
  previousIconUrl: string = null;
  nextIconUrl: string = null;
  exampleImageUrl: string = null;

  pageIndex: number = 0;
  totalPages: number = 3;

  totalCodes: number = 0;
  specialMessage: string = null;
  perimeter: string = null;

  leaderBoard: CodeHuntPlayer[] = [
    {
      name: 'Jean-Baptiste',
      score: 3200
    },
    {
      name: 'Clem',
      score: 3000
    },
    {
      name: 'Eric',
      score: 2500
    },
    {
      name: 'Marie23',
      score: 2400
    },
    {
      name: 'papillon',
      score: 1900
    },
    {
      name: 'Marc67',
      score: 1700
    },
  ];
  stickerWord: string = null;

  config: any = null;

  constructor(
    public translateService: TranslateService,
    private gameSessionSrv: GameSessionService,
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.hints?.length > 0) this.totalPages = 4;
    if (this.navParams?.data?.config) this.config = this.navParams?.data?.config;

    if (!this.config?.content || this.config.content?.length <= 0) return;

    this.iconCloseImageUrl = this.config?.content[0]?.CodeHunt?.iconCloseImageUrl ?? 'https://cdn.mocli.fr/icon/cross.svg';
    this.previousIconUrl = this.config?.content[0]?.CodeHunt?.previousIconUrl ?? 'https://cdn.mocli.fr/icon/previous.svg';
    this.nextIconUrl = this.config?.content[0]?.CodeHunt?.nextIconUrl ?? 'https://cdn.mocli.fr/icon/next.svg';
    this.exampleImageUrl = this.config?.content[0]?.CodeHunt?.exampleImageUrl ?? 'https://cdn.mocli.fr/icon/exemple-sticker.png';
    this.specialMessage = this.config?.content[0]?.CodeHunt?.specialMessage ?? null;

    this.totalCodes = this.config?.content[0]?.CodeHunt?.totalCodes ?? 0;
    this.perimeter = this.config?.content[0]?.CodeHunt?.searchArea ?? null;
    this.stickerWord = this.config?.content[0]?.CodeHunt?.stickerWord ?? 'codes';
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }

  // 100 = last page
  updatePage(toAdd: number) {
    if ((this.pageIndex + toAdd) >= this.totalPages) {
      this.pageIndex = 100;
      return;
    };

    this.pageIndex += toAdd;
    if (this.pageIndex < 0) this.pageIndex = 0;
  }
}
