<div class="crop-container">
  
  <div class="content">
    <div class="crop-wrapper">
      <h3>Éditer votre image</h3>
      <image-cropper *ngIf="imageChangedEvent; else loaderCropper"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="true"
          [cropperMinHeight]="250"
          [aspectRatio]="5 / 3"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <ng-template #loaderCropper>
        <ion-spinner name="crescent"></ion-spinner>
      </ng-template>
    </div>
    <div class="result">
      <h3>Résultat</h3>
      <img *ngIf="croppedImage; else loaderImg" [src]="croppedImage" alt="resultat image éditée">
      <ng-template #loaderImg>
        <ion-spinner name="crescent"></ion-spinner>
      </ng-template>
    </div>
  </div>

  <div class="controls">
    <div class="mocli-button button cancel" (click)="closeModal('close')">Annuler</div>
    <div class="mocli-button button save" style="margin-left: 15px;" (click)="closeModal('confirm')">Sauvegarder</div>
  </div>
</div>

<!-- 
<img [src]="croppedImage" /> -->