export class HowMany {
    totalStickers: number;
    searchArea: string;

    questionImageUrl: string;

    idleQuestionImageUrl: string;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    totalQuestions: number;
    index: number;

    constructor() {
        this.totalStickers = 0;
        this.searchArea = '';
        this.questionImageUrl = '';
        this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
        this.totalQuestions = 0;
        this.index = 0;
        this.hasAnswered = false;
    }
}
