import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { AuthService } from './AuthService';
import { OrderService } from '@mocli/MocliCommonLib';
import { TodoGroup } from '@mocli/MocliCommonLib/lib/data/models/TodoGroup';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  public todoServiceSubject: BehaviorSubject<TodoGroup[]> = new BehaviorSubject<TodoGroup[]>(null);

  constructor(
    private orderService: OrderService,
    private authService: AuthService
  ) {
    this.initTodoList();
  }

  initTodoList() {
    if (this.todoServiceSubject.value) return;
    this.updateTodoList();
  }

  async updateTodoList() {
    try {
      if (!this.authService.userLogged || !this.authService.userLogged.value.id)
        return;
      const todoGroup = await firstValueFrom(this.orderService.getTodoItemsForUser(this.authService.userLogged.value.id));
      if (todoGroup?.length > 0) {
        this.todoServiceSubject.next(todoGroup);
      }
    } catch (err) {
    }
  }
}
