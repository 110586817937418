<div class="buy-credits">
  <div class="title-container">
    <div class="credits">
      Achat de {{creditsAmount}} crédits
    </div>
    <div class="total">
      pour un total de {{price}},00€ HT
    </div>
  </div>

  <div class="buy-credits-form">
    <div class="double-container">
      <!-- Prénom -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
        <label for="firstName">Prénom</label>
        <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.firstName.invalid}"
               formControlName="firstName"
               type="text"
               id="firstName">
        <div *ngIf="paymentsFormGroup.controls.firstName.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      <!-- Nom -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
          <label for="lastName">Nom</label>
          <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.lastName.invalid}"
                 formControlName="lastName"
                 type="text"
                 id="lastName">
          <div *ngIf="paymentsFormGroup.controls.lastName.invalid" class="invalid-input-text">
              Ce champ est requis.
          </div>
      </div>
    </div>

    <div class="double-container">
      <!-- Nom de l'entreprise -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
        <label for="companyName">Nom de l'entreprise</label>
        <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.companyName.invalid}"
               formControlName="companyName"
               type="text"
               id="companyName">
        <div *ngIf="paymentsFormGroup.controls.companyName.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      <!-- Adresse -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
          <label for="address">Adresse</label>
          <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.address.invalid}"
                 formControlName="address"
                 type="text"
                 id="address">
          <div *ngIf="paymentsFormGroup.controls.address.invalid" class="invalid-input-text">
              Ce champ est requis.
          </div>
      </div>
    </div>

    <div class="double-container">
      <!-- Ville -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
        <label for="city">Ville</label>
        <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.city.invalid}"
               formControlName="city"
               type="text"
               id="city">
        <div *ngIf="paymentsFormGroup.controls.city.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      <!-- Code Postal -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
          <label for="postalCode">Code Postal</label>
          <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.postalCode.invalid}" 
                 formControlName="postalCode"
                 type="text"
                 id="postalCode">
          <div *ngIf="paymentsFormGroup.controls.postalCode.invalid" class="invalid-input-text">
              Ce champ est requis.
          </div>
      </div>
    </div>

    <div class="double-container">
      <!-- Pays -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
        <label for="country">Pays</label>
        <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.country.invalid}" 
               formControlName="country"
               type="text"
               id="country">
        <div *ngIf="paymentsFormGroup.controls.country.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      <!-- Numéro de TVA -->
      <div [formGroup]="paymentsFormGroup" *ngIf="paymentsFormGroup">
          <label for="tvaNumber">Numéro de TVA</label>
          <input [ngClass]="{'invalid-input': paymentsFormGroup.controls.tvaNumber.invalid}"
                 formControlName="tvaNumber"
                 type="text"
                 id="tvaNumber">
          <div *ngIf="paymentsFormGroup.controls.tvaNumber.invalid" class="invalid-input-text">
              Ce champ est requis.
          </div>
      </div>
    </div>

    <div class="save-info" (click)="saveInfo = !saveInfo">
      <div class="checkbox">
        <app-checkbox-input type="checkbox" [disable]="true" [checked]="saveInfo"></app-checkbox-input>
      </div>
      <div class="text">
        Sauvegarder les informations
      </div>
    </div>

    <div class="cgu">
      Votre vie privée est importante pour nous, vos informations personnelles seront toujours utilisées en accord avec nos <a>conditions d’utilisation</a> et <a>Politique de confidentialité</a>.
    </div>
  </div>

  <div *ngIf="paymentsFormGroup">
    <div class="btn-confirm-payment mocli-button"
         (click)="onSubmit()">
      Passer au paiement
    </div>
    
    <form ngNoForm
          #payForm
          *ngIf="paymentsFormGroup.valid === true"
          [action]="actionLink"
          method="POST"
          enctype="application/x-www-form-urlencoded">
      <input type="hidden" name="UserId" [value]="userId">
      <input type="hidden" name="Quantity" [value]="creditsAmount">
      <input *ngIf="experience" type="hidden" name="Origin" [value]="'book/' + experience.id">
    </form>
  </div>
</div>