import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidemenuService implements OnDestroy {
    public onSideMenuStateChanged: Subject<boolean> = new Subject<boolean>();
    public sideMenuShown: boolean = true;

    private subscription: Subscription;

    constructor() {
        this.subscription = this.onSideMenuStateChanged.subscribe((data: boolean) => {
            this.sideMenuShown = data;
        });
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }
}
