<div class="container" (click)="onClick?.emit()" [class]="classes" >
  <div *ngIf="imageUrl && !iconRight" class="codehunt-button-icon" [ngClass]="{'circle-white': whiteCircle, 'smallImage': smallImage}">
    <img [src]="imageUrl" alt="">
  </div>
  <div class="codehunt-button-text" [ngClass]="{'no-image': !imageUrl || iconRight}" [style]="!imageUrl || iconRight ? '' : 'margin-left: 16px;'">
    {{text}}
    <div class="icon-right" *ngIf="imageUrl && iconRight">
      <img [src]="imageUrl" alt="">
    </div>
  </div>
</div>