import { ModalController } from '@ionic/angular';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/features/shared/modal/modal-confirm/modal-confirm.component';
import { isComplete } from '../IsComplete';
import { AuthService } from 'src/app/services/AuthService';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
import { firstValueFrom } from 'rxjs';
import { DateTime } from 'luxon';
import { UserService } from '@mocli/MocliCommonLib';
import { SimpleQuiz, SimpleQuizAnswer } from 'src/app/data/models/SimpleQuiz';

@Component({
  selector: 'app-mystery-ingredient-quiz-configurator',
  templateUrl: './mystery-ingredient-quiz-configurator.component.html',
  styleUrls: ['./mystery-ingredient-quiz-configurator.component.scss'],
})
export class MysteryIngredientQuizConfiguratorComponent implements OnInit, isComplete {

  config: any;
  data: SimpleQuiz;
  saved = false;
  inputDisabled: boolean = null;

  quizForm: FormGroup;
  answersForms: FormGroup[] = [];

  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  visualImageUrlExplain: string;
  uploadedImageExplain: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private userSrv: UserService,
    private authSrv: AuthService,
  ) {}

  ngOnInit() {
    this.data = this.config.MysteryIngredientQuiz;

    this.visualImageUrl = this.data?.questionImageUrl;
    this.uploadedImage.url = this.visualImageUrl;
    this.uploadedImage.name = this.visualImageUrl.substring(20);

    this.visualImageUrlExplain = this.data?.explainImageUrl;
    this.uploadedImageExplain.url = this.visualImageUrlExplain;
    this.uploadedImageExplain.name = this.visualImageUrlExplain?.substring(20);

    this.quizForm = this.fb.group({
      question: [
        {value: this.data.question, disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      explanation: [
        {value: this.data.explanation, disabled: this.inputDisabled},
        [Validators.maxLength(300)]
      ]
    });


    for (const answer of this.data.answers) {
      this.answersForms.push(this.fb.group({
        answer: [
          {value: answer.answer, disabled: this.inputDisabled},
          [Validators.required, Validators.maxLength(50)]
        ],
        correct: [ {value: answer.correct, disabled: this.inputDisabled} ]
      }));
    }

    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  async openModalDeleteAnswer(idx: number) {
    const props: any = {
      paramTitle: 'Voulez-vous vraiment supprimer la réponse ?',
      paramDescription: '',
    };

    const modal = await this.modalController.create({
      component: ModalConfirmComponent,
      componentProps: props,
      cssClass: 'modal-confirm-custom',
    });

    modal.onWillDismiss().then((dataReturned: any) => {
      if (dataReturned !== null && dataReturned.data === 'confirm' && this.answersForms.length > 1) {
        //si on efface la réponse correcte on l'assigne à la question 0
        if (this.answersForms[idx].get('correct').value) {
          if (idx === 0)
            idx = 1;
          this.answersForms[0].get('correct').patchValue(true);
        }

        this.data.answers.splice(idx, 1);
        this.answersForms.splice(idx, 1);
      }
    });
    return await modal.present();
  }

  addAnswer() {
    if (this.data?.answers?.length >= 4) return;

    this.data.answers.push({
      answer: '',
      correct: false,
    } as SimpleQuizAnswer);

    const aForm = this.fb.group({
      answer: [
        this.data.answers[this.data.answers.length - 1].answer,
        [Validators.required, Validators.maxLength(50)]
      ],
      correct: [
        this.data.answers[this.data.answers.length - 1].correct,
      ]
    });

    this.answersForms.push(aForm);
  }

  onSelectCorrectAnswer(idx: number) {
    this.answersForms.forEach(e => e.get('correct').patchValue(false));
    this.answersForms[idx].get('correct').patchValue(true);
  }

  configuratorComplete(): boolean {
    const hasACorrectAnswer = this.answersForms.filter(e => e.controls.correct.value === true).length === 1;
    const noEmptyAnswers = this.answersForms.filter(e => e.controls.answer.value.length === 0).length === 0;
    const quizFormValid = this.quizForm.valid;
    const everyAnswerFormValid = this.answersForms.filter(e => e.valid).length === this.answersForms.length;
    const hasAtLeast2Answers = this.answersForms.length >= 2;
    const isValid = hasACorrectAnswer
                    && noEmptyAnswers
                    && quizFormValid
                    && everyAnswerFormValid
                    && hasAtLeast2Answers;

    this.isValidEventEmitter.emit(isValid);
    return isValid;
  }

  autoSave() {
    const isValid = this.configuratorComplete();

    this.data.question = this.quizForm.controls.question.value;
    this.data.explanation = this.quizForm.controls.explanation.value;
    this.data.questionImageUrl = this.uploadedImage?.url ?? '';
    this.data.explainImageUrl = this.uploadedImageExplain?.url ?? '';

    const targetIdx = this.answersForms.findIndex(e => e.controls.correct.value === true);
    this.data.answers = [];
    let idx = 0;
    for (const answerForm of this.answersForms) {
      const answer = new SimpleQuizAnswer(targetIdx === idx++);
      answer.answer = answerForm.controls.answer.value;
      this.data.answers.push(answer);
    }
  }

  async deleteFile(type?: string) {
    if (type === 'explain') {
      this.imageDeleteEventEmitter.emit(this.uploadedImageExplain);
      this.visualImageUrlExplain = undefined;
      this.uploadedImageExplain = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    } else {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    }
  }

  async onFileUpload(event: any, type?: string) {
    const files = event.target.files;
    const file = files[0] as File;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        firstValueFrom(uploadPromise).then(res => {
          if (type === 'explain') {
            this.visualImageUrlExplain = res.url;
            this.uploadedImageExplain = res;
          } else {
            this.visualImageUrl = res.url;
            this.uploadedImage = res;
          }
        });
      } else {
        //max file size
        console.error('file > 5mb');
      }
    };

    reader.readAsArrayBuffer(file);
  }
}
