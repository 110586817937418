/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BackgroundShape } from '../data/models/BackgroundShape';

export interface AutoSaveConfig {
  active: boolean;
  result?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService {
  autoSaveSubject: BehaviorSubject<AutoSaveConfig> = new BehaviorSubject<AutoSaveConfig>({active: false});

  constructor(
  ) {}

  startAutoSave() {
    this.autoSaveSubject.next({active: true});
  }

  stopAutoSave(result: boolean = null) {
    this.autoSaveSubject.next({active: false, result});
  }
}
