<div class="mocli-box-content configure-question">
  <form [formGroup]="oddOneOutForm">
    <!-- Name -->
    <div class="question-info">
      <div class="big-title title">Intitulé {{data.index + 1}}</div>
      <div class="question-content">
        <textarea formControlName="name"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': oddOneOutForm.controls.name.invalid, 'disabled-input': inputDisabled}">
                </textarea>
        <div class="question-content-counter">
          <span *ngIf="oddOneOutForm.controls.name.invalid" class="invalid-input-text">
            <span *ngIf="oddOneOutForm.controls.name?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="oddOneOutForm.controls.name?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{oddOneOutForm.controls.name.value.length}} / 150
          </span>
        </div>
      </div>
    </div>

    <!-- Objects -->
    <div class="explain config-objects">
      <div class="visuel-title">
        <div class="title">Liste d’objets</div>
        <div class="optional">(10 maximum)</div>
      </div>

      <div class="objects-input" [formGroup]="oddOneOutForm">

        <div class="input-part">
          <div class="input">
            <input type="text" (keydown.enter)="inputDisabled ? null : onAddObjectClick()" formControlName="objectInput" [ngClass]="{'invalid-input': oddOneOutForm.controls.objectInput.invalid || alreadyExists, 'disabled-input': inputDisabled}">
            <div (click)="inputDisabled ? null : onAddObjectClick()" class="button-ok" [ngClass]="{'button-ok-disabled': showMaxObjects || oddOneOutForm.controls.objectInput.invalid || alreadyExists, 'disabled-input': inputDisabled}">Ok</div>
          </div>
          <div class="question-content-counter" style="position: relative;">
            <span>
              Caractères : {{oddOneOutForm.controls.objectInput.value.length}} / 20
            </span>
            <span *ngIf="alreadyExists && !oddOneOutForm.controls.objectInput.invalid" class="invalid-input-text" style="position: absolute; left: 115px; width: 200px;">
              <span>Cet objet existe déjà.</span>
            </span>
            <span *ngIf="oddOneOutForm.controls.objectInput.invalid" class="invalid-input-text" style="position: absolute; left: 115px; width: 200px;">
              <span *ngIf="oddOneOutForm.controls.objectInput?.errors?.maxlength">Taille maximale atteinte. </span>
            </span>
          </div>
        </div>
        
        <div class="objects-list">
          <ng-container *ngFor="let object of objectsList; let i = index">
            <div class="object">
              <div class="text">{{object}}</div>
              <div class="cross" *ngIf="!inputDisabled" (click)="onDeleteObjectClick(i)">
                <img src="https://cdn.mocli.fr/icon/rounded-cross.png" alt="">
              </div>
            </div>
          </ng-container>
        </div>
  
      </div>
    </div>

    <!-- Intruder -->
    <div class="explain">
      <div class="big-title title">Intrus</div>
      <div class="small-content inline-button" id="oddOneOutSelector">
        <!-- <input type="text" 
              pInputText
              placeholder="Écrire ici..."
              formControlName="intruder"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': oddOneOutForm.controls.intruder.invalid}"> -->

        <p-dropdown [options]="objectsList" 
                  formControlName="intruderSelect"
                  [autoDisplayFirst]="true"
                  (onChange)="onChangeIntruder($event)">
        </p-dropdown>
        <div class="question-content-counter">
          <span *ngIf="oddOneOutForm.controls.intruderSelect.invalid && objectsList?.length > 0" class="invalid-input-text">
            <span *ngIf="oddOneOutForm.controls.intruderSelect?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="oddOneOutForm.controls.intruderSelect?.errors?.required">Champ requis. </span>
          </span>
        </div>
      </div>
    </div>

    <!-- Explain -->
    <div class="question-info">
      <div class="visuel-title">
        <div class="title">Explication</div>
        <div class="optional">(optionnelle)</div>
      </div>
      <div class="question-content">
        <textarea formControlName="explain"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': oddOneOutForm.controls.explain.invalid, 'disabled-input': inputDisabled}">
                </textarea>
        <div class="question-content-counter">
          <span *ngIf="oddOneOutForm.controls.explain.invalid" class="invalid-input-text">
            <span *ngIf="oddOneOutForm.controls.explain?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="oddOneOutForm.controls.explain?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{oddOneOutForm.controls.explain.value.length}} / 500
          </span>
        </div>
      </div>
    </div>

    <!-- Visuel -->
    <!-- <div class="visuel" [formGroup]="catFormGroup">
      <div class="visuel-title">
        <div class="title">Photo de l’emplacement</div>
        <div class="optional">(optionnelle)</div>
      </div>
      <div *ngIf="!visualFileLabel">
        <input #inputFile id="inputFile" type="file" formControlName="visual" name="file" class="inputfile" accept="image/png, image/jpeg"/>
        <label for="inputFile" class="add-file">Joindre un fichier</label>
      </div>
      <div *ngIf="visualFileLabel" class="visual-name-container">
        <div class="visual-name" >{{visualFileLabel}}</div>
        <div class="delete">
          <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
        </div>
      </div>
    </div>
    <div *ngIf="visualFile" class="preview-container">
      <img id="previewVisual" [src]="previewVisualUrl()" alt="" class="preview">
    </div> -->
  </form>
</div>