import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss']
})
export class InputLabelComponent {
  @Input() label: string = null;
  @Input() errors: any = null;
  @Input() isFormSubmitted: boolean = null;
  @Input() optional: boolean = false;
  @Input() optionalFeminine: boolean = false;
  @Input() labelDescription: string = null;
  @Input() labelTooltip: string = null;
  @Input() labelBottom: number = 0;
  @Input() labelWidth: number = 0;

  getErrorsFmt() {
    if (!this.errors) return null;

    if (this.errors?.required === true) return 'Champ requis';

    if (this.errors?.maxlength) return 'Taille maximum atteinte';

    if (this.errors?.alreadyExists) return 'Ce nom existe déjà';

    if (this.errors?.noQuestions) return 'Question requise';

    return 'Erreur inconnue';
  }
}
