import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-qr',
  templateUrl: './modal-qr.component.html',
  styleUrls: ['./modal-qr.component.scss'],
})
export class ModalQRComponent implements OnInit {

  modalTitle: string = null;
  modalDescription: string = null;
  qrCodeValue: string = null;
  pin: number = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.modalTitle = this.navParams.data.paramTitle;
    if (this.navParams?.data?.paramDescription) this.modalDescription = this.navParams.data.paramDescription;
    if (this.navParams?.data?.qrCodeValue) this.qrCodeValue = this.navParams.data.qrCodeValue;
    if (this.navParams?.data?.pin) this.pin = this.navParams.data.pin;
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }
}
