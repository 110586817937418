/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BackgroundShape } from '../data/models/BackgroundShape';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  public backgroundShapesGlobal: BackgroundShape[][] = null;
  public backgroundShapes: BehaviorSubject<BackgroundShape[]> = new BehaviorSubject<BackgroundShape[]>(null);
  public onBackgroundShapesUrlChanged: Subject<string | BackgroundShape[]> = new Subject<string | BackgroundShape[]>();

  constructor(
    private http: HttpClient,
  ) {
    this.onBackgroundShapesUrlChanged.subscribe((data) => {
      // Url
      if (typeof data === 'string') {
        if (data === 'null') {
          this.backgroundShapesGlobal = null;
          this.backgroundShapes.next(null);
          return;
        }
        this.http.get(data).subscribe((res: BackgroundShape[] | BackgroundShape[][]) => {
          if (!res || res.length <= 0) return;
          if (Array.isArray(res[0])) {
            this.backgroundShapesGlobal = res as BackgroundShape[][];
            this.backgroundShapes.next(res[0]);
          } else {
            this.backgroundShapesGlobal = null;
            this.backgroundShapes.next(res as BackgroundShape[]);
          }
        });
      }
      // Array
      else if (typeof data === 'object') {
        this.backgroundShapes.next(data);
      }
    });
  }
}
