<div class="update-adress">
  <div class="title-container">
    <div class="adress">
      Mise à jour de l'adresse de {{type === 1 ? 'livraison' : 'facturation'}}
    </div>
  </div>

  <div class="update-adress-form">

    <div class="double-container">
      <!-- Numéro -->
      <div [formGroup]="adressFormGroup" *ngIf="adressFormGroup">
        <label for="streetNumber">Numéro</label>
        <input [ngClass]="{'invalid-input': adressFormGroup.controls.streetNumber.invalid}"
        formControlName="streetNumber"
        type="text"
        id="streetNumber">
        <div *ngIf="adressFormGroup.controls.streetNumber.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      
      <!-- Adresse -->
      <div [formGroup]="adressFormGroup" *ngIf="adressFormGroup">
        <label for="addressName">Adresse</label>
        <input [ngClass]="{'invalid-input': adressFormGroup.controls.addressName.invalid}"
        formControlName="addressName"
        type="text"
        id="addressName">
        <div *ngIf="adressFormGroup.controls.addressName.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>

    </div>


    <div class="double-container">
      <!-- Code postal -->
      <div [formGroup]="adressFormGroup" *ngIf="adressFormGroup">
        <label for="postalCode">Code postal</label>
        <input [ngClass]="{'invalid-input': adressFormGroup.controls.postalCode.invalid}"
               formControlName="postalCode"
               type="text"
               id="postalCode">
        <div *ngIf="adressFormGroup.controls.postalCode.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
      <!-- Ville -->
      <div [formGroup]="adressFormGroup" *ngIf="adressFormGroup">
          <label for="city">Ville</label>
          <input [ngClass]="{'invalid-input': adressFormGroup.controls.city.invalid}"
                 formControlName="city"
                 type="text"
                 id="city">
          <div *ngIf="adressFormGroup.controls.city.invalid" class="invalid-input-text">
              Ce champ est requis.
          </div>
      </div>
    </div>

    <div class="double-container">
      <!-- Pays -->
      <div [formGroup]="adressFormGroup" *ngIf="adressFormGroup">
        <label for="country">Pays</label>
        <input [ngClass]="{'invalid-input': adressFormGroup.controls.country.invalid}"
               formControlName="country"
               type="text"
               id="country">
        <div *ngIf="adressFormGroup.controls.country.invalid" class="invalid-input-text">
          Ce champ est requis.
        </div>
      </div>
    </div>

    <div class="cgu">
      Votre vie privée est importante pour nous, vos informations personnelles seront toujours utilisées en accord avec nos <a>conditions d’utilisation</a> et <a>Politique de confidentialité</a>.
    </div>
  </div>

  <div *ngIf="adressFormGroup">
    <div class="btn-confirm-update mocli-button" (click)="onSubmit()">
        Confirmer
    </div>
  </div>
</div>