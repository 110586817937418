<div class="sub-exp-configurator-container">
    <div class="root-configurator">
        <div *ngFor="let configElem of config; let slideIdx = index" class="configurator-container" [style]="'transform: translateX(' + (isTranslate ? 0 : ((slideIdx - idx) * 775)) + 'px)'">
            <ng-container #rootElem></ng-container>
        </div>
    </div>
    <div class="configurator-controls">
        <div class="global-btn global-btn-cancel" (click)="closeModal(null, 'close')">Quitter sans sauvegarder</div>
        <div class="save-container">
            <div class="invalid" [ngClass]="{'invalid-active': !isValid && isSubmitted}">Merci de remplir tous les champs requis.</div>
            <div class="save-container-controls">
                <div class="global-btn global-btn-back" *ngIf="isAdd" (click)="save(true)">
                    Enregister et ajouter une nouvelle question
                </div>
                <div class="global-btn global-btn-save" (click)="save()">
                    Enregister et fermer
                </div>
            </div>
        </div>
    </div>

    <div class="global-controls" *ngIf="!isAdd && !isTranslate">
        <div class="global-control global-control-prev" [ngClass]="{'global-control-disabled': idx === 0}" (click)="onPrevClick()">
            <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.585787 13.5858C-0.195263 14.3668 -0.195263 15.6332 0.585787 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857864C15.3611 0.0768148 14.0948 0.0768147 13.3137 0.857863L0.585787 13.5858ZM25 13L2 13L2 17L25 17L25 13Z" fill="#C4C4C4"/>
            </svg>                
        </div>
        <div class="global-control global-control-next" [ngClass]="{'global-control-disabled': config?.length === idx + 1}" (click)="onNextClick()">
            <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4142 16.4142C25.1953 15.6332 25.1953 14.3668 24.4142 13.5858L11.6863 0.857862C10.9052 0.0768137 9.63891 0.0768139 8.85786 0.857863C8.07681 1.63891 8.07681 2.90524 8.85786 3.68629L20.1716 15L8.85787 26.3137C8.07682 27.0948 8.07682 28.3611 8.85787 29.1421C9.63892 29.9232 10.9052 29.9232 11.6863 29.1421L24.4142 16.4142ZM3.49691e-07 17L23 17L23 13L-3.49691e-07 13L3.49691e-07 17Z" fill="#C4C4C4"/>
            </svg>                
        </div>
    </div>
</div>