/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
import { ModalController } from '@ionic/angular';
import { Component, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/features/shared/modal/modal-confirm/modal-confirm.component';
import { isComplete } from '../IsComplete';
import { AuthService } from 'src/app/services/AuthService';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
import { firstValueFrom } from 'rxjs';
import { DateTime } from 'luxon';
import { UserService } from '@mocli/MocliCommonLib';
import { SimpleQuiz, SimpleQuizAnswer } from 'src/app/data/models/SimpleQuiz';
import { MessageService } from 'primeng/api';
import { Subject } from '@microsoft/signalr';

export enum SimpleQuizTypes {
  CLASSIC = 0,
  POLL = 1,
  // BOOLEAN = 2,
}

@Component({
  selector: 'app-simple-quiz-configurator',
  templateUrl: './simple-quiz-configurator.component.html',
  styleUrls: ['./simple-quiz-configurator.component.scss'],
})
export class SimpleQuizConfiguratorComponent implements OnInit, isComplete {
  config: any;
  configByLang: any = null;
  baseConfig: any;
  selectedLanguage: string = 'fr';
  baseLanguage: string = 'fr';
  pageIndex: number = -1;

  data: SimpleQuiz;
  saved = false;
  inputDisabled: boolean = null;

  quizForm: FormGroup;
  answersForms: FormGroup[] = [];
  memoryAnswers: FormGroup[] = null;

  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  quizTypesList: {value: number; label: string}[] = [
    {
      value: SimpleQuizTypes.CLASSIC,
      label: 'Question à choix multiples'
    },
    {
      value: SimpleQuizTypes.POLL,
      label: 'Sondage'
    },
    // {
    //   value: SimpleQuizTypes.BOOLEAN,
    //   label: 'Vrai ou Faux'
    // },
  ];

  selectedQuizType: number;

  visualImageUrlExplain: string;
  uploadedImageExplain: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  quizTypesEnum: typeof SimpleQuizTypes = SimpleQuizTypes;
  isSubmitted: boolean = true;
  isSubmittedSubject: any = null;

  isValidEventEmitter: EventEmitter<{isValid: boolean; idx: number}> = new EventEmitter<{isValid: boolean; idx: number}>();

  // isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  get isBaseLanguage() {
    return this.selectedLanguage === this.baseLanguage;
  }

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private userSrv: UserService,
    private authSrv: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.data = this.config.SimpleQuiz;

    // check if this.baseConfig is an object or an array
    if (Array.isArray(this.baseConfig)) {
      this.baseConfig = JSON.parse(JSON.stringify(this.baseConfig[this.data?.index]?.SimpleQuiz));
    }

    this.visualImageUrl = this.baseConfig?.questionImageUrl ?? this.data?.questionImageUrl;
    this.uploadedImage.url = this.visualImageUrl;
    this.uploadedImage.name = this.visualImageUrl?.substring(20);

    this.visualImageUrlExplain = this.baseConfig?.explainImageUrl ?? this.data?.explainImageUrl;
    this.uploadedImageExplain.url = this.visualImageUrlExplain;
    this.uploadedImageExplain.name = this.visualImageUrlExplain?.substring(20);

    this.selectedQuizType = this.data?.quizType ?? this.quizTypesList[0].value;

    if (!this.isBaseLanguage) {
      this.selectedQuizType = this.baseConfig?.quizType;

      if (!this.data?.answers) {
        this.data.answers = [];
      }

      const diff = this.baseConfig?.answers?.length - this.data?.answers?.length;

      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          this.data?.answers.push({
            answer: '',
            correct: false,
          } as SimpleQuizAnswer);
        }
      } else if (diff < 0) {
        this.data?.answers.splice(diff, Math.abs(diff));
      }

      this.data?.answers?.forEach((answer, idx) => {
        answer.correct = this.baseConfig?.answers[idx]?.correct;
      });
    }

    const explanationValidators = [Validators.maxLength(300)];

    // if (!this.isBaseLanguage && this.baseConfig?.explanation !== '' && this.baseConfig?.explanation !== null) {
    //   explanationValidators.push(Validators.required);
    // }

    this.quizForm = this.fb.group({
      questionType: [
        {value: this.selectedQuizType, disabled: this.inputDisabled || (!this.isBaseLanguage)},
        [Validators.required, Validators.maxLength(300)]
      ],
      question: [
        {value: this.data.question, disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      explanation: [
        {value: this.data.explanation, disabled: this.inputDisabled},
        explanationValidators
      ]
    });

    for (const answer of this.data.answers) {
      this.answersForms.push(this.fb.group({
        answer: [
          {value: answer.answer, disabled: this.inputDisabled},
          [Validators.required, Validators.maxLength(75)]
        ],
        correct: [ {value: answer.correct, disabled: this.inputDisabled} ]
      }));
    }

    if (this.isSubmittedSubject) {
      this.isSubmitted = false;
      this.isSubmittedSubject.subscribe(() => {
        this.isSubmitted = true;
      });
    }

    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  async openModalDeleteAnswer(idx: number) {
    const props: any = {
      paramTitle: 'Voulez-vous vraiment supprimer la réponse ?',
      paramDescription: '',
    };

    const modal = await this.modalController.create({
      component: ModalConfirmComponent,
      componentProps: props,
      cssClass: 'modal-confirm-custom',
    });

    modal.onWillDismiss().then((dataReturned: any) => {
      if (dataReturned !== null && dataReturned.data === 'confirm' && this.answersForms.length > 1) {
        //si on efface la réponse correcte on l'assigne à la question 0
        if (this.answersForms[idx].get('correct').value) {
          if (idx === 0)
            idx = 1;
          this.answersForms[0].get('correct').patchValue(true);
        }

        this.data.answers.splice(idx, 1);
        this.answersForms.splice(idx, 1);
      }
    });
    return await modal.present();
  }

  addAnswer() {
    if (this.data?.answers?.length >= 4) return;

    this.data.answers.push({
      answer: '',
      correct: false,
    } as SimpleQuizAnswer);

    const aForm = this.fb.group({
      answer: [
        this.data.answers[this.data.answers.length - 1].answer,
        [Validators.required, Validators.maxLength(75)]
      ],
      correct: [
        this.data.answers[this.data.answers.length - 1].correct,
      ]
    });

    this.answersForms.push(aForm);
  }

  onSelectCorrectAnswer(idx: number) {
    if (this.inputDisabled) return;
    this.answersForms.forEach(e => e.get('correct').patchValue(false));
    this.answersForms[idx].get('correct').patchValue(true);
  }

  configuratorComplete(): boolean {
    const hasACorrectAnswer = this.quizForm?.controls?.questionType?.value === SimpleQuizTypes.POLL || this.answersForms.filter(e => e.controls.correct.value === true).length === 1;
    const noEmptyAnswers = this.answersForms.filter(e => e.controls.answer.value.length === 0).length === 0;
    const quizFormValid = this.quizForm.valid || this.quizForm.status === 'DISABLED';
    const everyAnswerFormValid = this.answersForms.filter(e => e.valid || e.status === 'DISABLED').length === this.answersForms.length;
    const hasAtLeast2Answers = this.answersForms.length >= 2;

    const isValid = hasACorrectAnswer
                    && noEmptyAnswers
                    && quizFormValid
                    && everyAnswerFormValid
                    && hasAtLeast2Answers;

    this.isValidEventEmitter.emit({isValid, idx: this.data.index});
    return isValid;
  }

  autoSave() {
    const isValid = this.configuratorComplete();

    this.data.question = this.quizForm.controls.question.value;
    this.data.explanation = this.quizForm.controls.explanation.value;
    this.data.questionImageUrl = this.uploadedImage?.url ?? '';
    this.data.explainImageUrl = this.uploadedImageExplain?.url ?? '';
    this.data.quizType = this.quizForm.controls.questionType.value;

    const targetIdx = this.answersForms.findIndex(e => e.controls.correct.value === true);
    const answersFinal = [];
    let idx = 0;
    for (const answerForm of this.answersForms) {
      const answer = new SimpleQuizAnswer(targetIdx === idx++);
      answer.answer = answerForm.controls.answer.value;
      answersFinal.push(answer);
    }
    this.data.answers = [...answersFinal];
  }

  async deleteFile(type?: string) {
    if (type === 'explain') {
      this.imageDeleteEventEmitter.emit(this.uploadedImageExplain);
      this.visualImageUrlExplain = undefined;
      this.uploadedImageExplain = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    } else {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    }
  }

  async onFileUpload(event: any, type?: string) {
    const files = event.target.files;
    const file = files[0] as File;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = async () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        // const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        // firstValueFrom(uploadPromise).then(res => {

        const res = await firstValueFrom(this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file));
          if (type === 'explain') {
            this.visualImageUrlExplain = res.url;
            this.uploadedImageExplain = res;
          } else {
            this.visualImageUrl = res.url;
            this.uploadedImage = res;
          }
      } else {
        //max file size
        console.error('file > 5mb');
        // eslint-disable-next-line max-len
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'La taille de l\'image que vous avez tenté de télécharger dépasse la limite autorisée. Veuillez sélectionner une image d\'une taille inférieure à 5 Mo et réessayez.' });
      }
    };

    reader.readAsArrayBuffer(file);
  }

  onSelectType(data: any) {
    this.selectedQuizType = data?.value;

    // if (this.selectedQuizType === SimpleQuizTypes.BOOLEAN) {
    //   this.memoryAnswers = this.answersForms;
    //   this.answersForms = [];
    //   this.answersForms.push(
    //     this.fb.group({
    //       answer: [
    //         {value: 'Vrai', disabled: true},
    //         [Validators.required]
    //       ],
    //       correct: [
    //         this.data.answers[this.data.answers.length - 1].correct,
    //       ],
    //     })
    //   );
    //   this.answersForms.push(
    //     this.fb.group({
    //       answer: [
    //         {value: 'Faux', disabled: false},
    //         [Validators.required]
    //       ],
    //       correct: [
    //         this.data.answers[this.data.answers.length - 1].correct,
    //       ],
    //     })
    //   );
    // } else {
      if (this.memoryAnswers) {
        this.answersForms = this.memoryAnswers;
      }
    // }
  }

  hasAnswersError() {
    return this.answersForms.filter(e => e.controls.correct.value === true).length === 0;
  }
}
