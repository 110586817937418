//template service angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextareaService {
    idList: string[] = [];

    constructor() { }

    registerNewTextareaId() {
        // id must be unique and not already in the list
        let id = '';
        let isUnique = false;

        while (!isUnique) {
            id = this.generateRandomId();

            if (!this.idList.includes(id)) {
                isUnique = true;
            }
        }

        return id;
    }

    generateRandomId() {
        return Math.random().toString(36).substring(2);
    }
}
