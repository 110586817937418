import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TodoGroup } from '@mocli/MocliCommonLib/lib/data/models/TodoGroup';
import { firstValueFrom, Subscription } from 'rxjs';
import { TodoTaskStatusEnum } from 'src/app/data/enums/TodoTaskStatus.enum';
import { TodoService } from 'src/app/services/TodoService';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss'],
})
export class TodoListComponent implements OnInit, OnDestroy {

  @Input() public userId: number = 1;

  public todoGroups: TodoGroup[] = [];
  public todoSubscription: Subscription = null;
  public todoTaskStatusEnum: typeof TodoTaskStatusEnum = TodoTaskStatusEnum;
  public selectedFilter: TodoTaskStatusEnum | 'all' = 'all';

  constructor(
    private todoService: TodoService
  ) { }

  ngOnInit() {
    if (this.todoService?.todoServiceSubject?.value) this.todoGroups = this.todoService.todoServiceSubject.value;
    this.todoSubscription = this.todoService.todoServiceSubject.subscribe((todoGroups) => {
      this.todoGroups = todoGroups;
    });
  }

  ngOnDestroy() {
    if (this.todoSubscription) this.todoSubscription.unsubscribe();
  }


  public selectFilter(filter: TodoTaskStatusEnum | 'all') {
    this.selectedFilter = filter;
  }

  public hasTaskByFilter(selectedFilter: TodoTaskStatusEnum | 'all', todoGroup: TodoGroup, todoGroups?: TodoGroup[]) {
    if (selectedFilter === 'all') {
      return true;
    }

    if (todoGroup) {
      return todoGroup.tasks.some((todoTask) => todoTask.status === selectedFilter);
    } else if (todoGroups) {
      return todoGroups.some((todoGrp) => todoGrp.tasks.some((todoTask) => todoTask.status === selectedFilter));
    } else {
      return false;
    }
  }
}
