/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable max-len */
import { Component, Input, Output } from '@angular/core';
import { CodeHuntCodeStatus } from '../../configure/shared-exp-configurator/code-hunt-configurator/codehunt-content-editor/codehunt-content-editor.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent {
  @Input() status: CodeHuntCodeStatus = CodeHuntCodeStatus.ACTIVE;
  @Input() disabled: boolean = false;
  @Output() onStatusChanged = new BehaviorSubject<CodeHuntCodeStatus>(null);

  codeHuntCodeStatusEnum: typeof CodeHuntCodeStatus = CodeHuntCodeStatus;

  onBadgeClick() {
    if (this.disabled) return;

    if (this.status === CodeHuntCodeStatus.ACTIVE) {
      this.status = CodeHuntCodeStatus.INACTIVE;
    } else {
      this.status = CodeHuntCodeStatus.ACTIVE;
    }
    this.onStatusChanged.next(this.status);
  }
}
