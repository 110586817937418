/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CodeHuntSticker } from 'src/app/features/configure/shared-exp-configurator/code-hunt-configurator/code-hunt-configurator.component';
import { ModalPreviewImageComponent } from '../modal-preview-image/modal-preview-image.component';


@Component({
  selector: 'app-modal-sticker-repartition',
  templateUrl: './modal-sticker-repartition.component.html',
  styleUrls: ['./modal-sticker-repartition.component.scss'],
})
export class ModalStickerRepartitionComponent implements OnInit {
  codesCount: number = null;
  stickerRepartition: CodeHuntSticker[] = [];
  stickers: any = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.stickers) {
      this.stickers = this.navParams.data.stickers;

      if (this.stickers?.values?.count) this.codesCount = this.stickers.values.count;

      if (this.stickers?.values?.stickersGroup) {
        // remplir le tableau de repartition via res

        const stickersGroup = this.stickers?.values?.stickersGroup;
        if (stickersGroup?.length > 0) {
          this.stickerRepartition = [];
          for (const sticker of stickersGroup) {
            this.stickerRepartition.push({
              name: sticker.name,
              quantity: sticker.quantity,
              points: sticker.points,
              imageUrl: sticker.exampleImageUrl
            });
          }
        }
      }
    }
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }

  async openExampleModal(imageUrl: string) {
    if (!imageUrl) return;

    const props: any = {
      imageUrl,
    };

    const modal = await this.modalController.create({
      component: ModalPreviewImageComponent,
      componentProps: props,
      cssClass: 'modal-preview-image',
    });
    return await modal.present();
  }

}
