<div class="mocli-box-content configure-question">

  <!-------- Configurator -------->
  <form [formGroup]="howManyForm">

    <!-- Total stickers -->
    <div class="question-info">
      <div class="big-title title">Nombre à déviner</div>
      <div class="small-content">
        <input type="number" 
            [min]="0"
            [step]="1"
            pInputText
            placeholder="Écrire ici..."
            formControlName="totalStickers"
            [ngClass]="{'invalid-input': howManyForm.controls.totalStickers.invalid, 'disabled-input': inputDisabled}">
      </div>
    </div>
    
    <!-- Search Area -->
    <div class="question-info">
      <div class="big-title title">Zone de recherche</div>
      <div class="question-content">
        <textarea formControlName="searchArea"
                  placeholder="Décrire ici le périmètre de la zone de recherche..."
                  [ngClass]="{'invalid-input': howManyForm.controls.searchArea.invalid, 'disabled-input': inputDisabled}">
        </textarea>
        <div class="question-content-counter">
          <span *ngIf="howManyForm.controls.searchArea.invalid" class="invalid-input-text">
            <span *ngIf="howManyForm.controls.searchArea?.errors?.required">Champs requis. </span>
            <span *ngIf="howManyForm.controls.searchArea?.errors?.maxlength">Taille maximale atteinte. </span>
          </span>
          <span>Caractères : {{howManyForm.controls.searchArea.value.length}} / 300</span>
        </div>
      </div>
    </div>
  </form>

  <!-- Visuel -->
  <div class="visuel">
    <div class="visuel-title">
      <div class="title">Visuel</div>
      <div class="optional">(optionnel)</div>
    </div>
    <div *ngIf="!visualImageUrl">
      <input #inputFile 
            id="inputFile"
            type="file"
            name="file"
            class="inputfile"
            accept="image/*"
            (change)="onFileUpload($event)">
      <label for="inputFile" class="add-file" *ngIf="!inputDisabled" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrl" class="visual-name-container">
      <div class="delete" *ngIf="!inputDisabled">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrl" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
  </div>
</div>