/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { ExperienceService, UserService } from '@mocli/MocliCommonLib';
import { HeaderBarService } from 'src/app/services/HeaderBarService';
import { ExperienceInfo } from '@mocli/MocliCommonLib/lib/data/models/ExperienceInfo';

@Component({
  selector: 'experience-item',
  templateUrl: './experience-item.component.html',
  styleUrls: ['./experience-item.component.scss'],
})
export class ExperienceItemComponent {
  @Input() experience: ExperienceInfo;
  @Input() favorited: boolean = false;
  @Input() format: ('small' | 'large') = 'small';

  @Output() onToggleFavorited: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public router: Router,
    public userService: UserService,
    private experienceService: ExperienceService,
    private authService: AuthService,
  ) {
  }

  async toggleFavorite() {
    this.favorited = !this.favorited;

    try {
      let res: boolean;
      if (!this.experience || !this.authService.userLogged || !this.authService.userLogged.value.id) return;

      if (this.favorited) {
        res = await firstValueFrom(this.experienceService.addFavoriteForUser(this.authService.userLogged.value.id,
                                                                              this.experience.id,
                                                                              'Default'));
      } else {
        res = await firstValueFrom(this.experienceService.deleteFavoriteForUser(this.authService.userLogged.value.id,
                                                                                 this.experience.id,
                                                                                 'Default'));
      }
      this.onToggleFavorited.emit(this.favorited);

      if (!res) {
        // TODO: alerte danger : erreur lors de la mise à jour des favoris
      }
    } catch (e) {
      this.onToggleFavorited.emit(this.favorited);

    }
  }

  navigateToExperience() {
    if (!this.experience) return;
    this.router.navigate(['/experience/' + this.experience.id],
      {
        state: {
          experience: this.experience,
          favorited: this.favorited
        }
      }
    );
  }
}
