<div class="modal-data" [formGroup]="modalDataFormGroup" *ngIf="modalDataFormGroup">
  <div class="title">Choix multiples</div>

  <div class="languages-container" *ngIf="languages?.length > 1">
    <div class="language" *ngFor="let language of languages; let idx = index">

      <div class="top">
        <div class="flag-wrapper" [ngClass]="{'flag-wrapper-selected': currentLanguageIdx === idx}">
          <img [src]="'https://cdn.mocli.fr/icon/flags/' + language + '.svg'" alt="">
        </div>
  
        <div class="bar" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}"></div>
      </div>
      
      <div class="badge" [ngStyle]="{'background-color': (stepStatusColors[getStatus(idx)] + '4d'), 'color': stepStatusColors[getStatus(idx)]}">{{stepStatusLabels[getStatus(idx)]}}</div>
    </div>
  </div>

  <div class="input-description">Énnoncé</div>

  <div class="textarea">
    <textarea [placeholder]="currentLanguageIdx === 0 ? 'Écrire ici...' : ('À traduire : ' + configByLang['fr']?.question)" formControlName="question" [ngClass]="{'invalid-input': modalDataFormGroup.get('question')?.invalid && isSubmitted}" rows="5"></textarea>
    <div class="textarea-counter">
      <span *ngIf="modalDataFormGroup.controls.question.invalid" class="invalid-input-text">
        <span *ngIf="modalDataFormGroup.controls.question?.errors?.required && isSubmitted">Champs requis. </span>
        <span *ngIf="modalDataFormGroup.controls.question?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
      </span>
      <div class="char-count">Caractères : {{modalDataFormGroup?.controls?.question?.value?.length}} / 200</div>
    </div>
  </div>


  <div class="answers-container">
    <div class="input-description" style="margin-top: 16px;">Réponses</div>

    <div class="answers-list" id="answers-list">
      <ng-container *ngFor="let answer of answers; let i = index">
        <div class="answer">
          <div class="input-container">
            <input [formControlName]="answer" type="text" [placeholder]="currentLanguageIdx === 0 ? 'Écrire ici...' : ('À traduire : ' + configByLang['fr']?.values[i])" [ngClass]="{'invalid-input': modalDataFormGroup.get(answer)?.invalid && isSubmitted}">
            <div class="delete" >
              <img *ngIf="answers && answers?.length > 2"
                  src="https://cdn.mocli.fr/icon/delete-blue.svg"
                  (click)="openModalDeleteAnswer(i)" alt="delete">
            </div>
          </div>
          
          <div class="answer-counter">
            <span *ngIf="modalDataFormGroup.get(answer)?.invalid && isSubmitted" class="invalid-input-text">
              <span *ngIf="modalDataFormGroup.get(answer)?.errors?.maxlength">Taille maximale atteinte. </span>
              <span *ngIf="modalDataFormGroup.get(answer)?.errors?.required">Champs requis. </span>
            </span>
            <div class="char-count">Caractères : {{modalDataFormGroup.get(answer).value.length}} / 50</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div  (click)="currentLanguageIdx === 0 ? addAnswer() : ''" class="add-answer" [ngClass]="{'add-answer-disabled': currentLanguageIdx > 0}" tooltipPosition="left" [tooltipDisabled]="currentLanguageIdx === 0" [pTooltip]="'Uniquement modifiable sur la langue d\'origine (Français)'">
      <div class="add-answer-add">+</div>
      <div class="add-answer-content">Ajouter une réponse</div>
    </div>
  </div>

  <div class="max-choices-container">
    <div class="input-description">Nombre maximum de choix</div>
    <div [ngClass]="{'input-container-disabled': currentLanguageIdx > 0}"  tooltipPosition="left" [tooltipDisabled]="currentLanguageIdx === 0" [pTooltip]="'Uniquement modifiable sur la langue d\'origine (Français)'">
      <input type="number" [ngClass]="{'input-disabled': currentLanguageIdx > 0}" [min]="1" [max]="answers?.length" formControlName="maxChoices">
    </div>
  </div>

  <div class="required-container">
    <div class="input-description" style="margin-top: 16px;">Une case doit-elle être obligatoirement cochée par l’utilisateur ?</div>
    <div style="width: fit-content"  tooltipPosition="left" [tooltipDisabled]="currentLanguageIdx === 0" [pTooltip]="'Uniquement modifiable sur la langue d\'origine (Français)'">
      <div class="choice">
        <div class="label">Oui</div>
        <app-radio-input [disable]="currentLanguageIdx > 0" (click)="currentLanguageIdx > 0 ? '' : isRequired = true" [checked]="isRequired === true"></app-radio-input>
      </div>
      <div class="choice" style="margin-top: 12px;">
        <div class="label">Non</div>
        <app-radio-input [disable]="currentLanguageIdx > 0" (click)="currentLanguageIdx > 0 ? '' : isRequired = false" [checked]="isRequired === false"></app-radio-input>
      </div>
    </div>
  </div>

  <div class="controls-data">
    <div class="btn btn-cancel" (click)="prevStep()">{{currentLanguageIdx === 0 ? 'Annuler' : 'Langue précédente'}}</div>
    <div class="btn btn-confirm" (click)="nextStep()">{{currentLanguageIdx + 1 === languages?.length ? 'Confirmer' : 'Langue suivante'}}</div>
  </div>
</div>