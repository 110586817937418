import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm-leaving',
  templateUrl: './modal-confirm-leaving.component.html',
  styleUrls: ['./modal-confirm-leaving.component.scss'],
})
export class ModalConfirmLeavingComponent implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }

}
