<div class="confirm-contract-container">
    <div class="title">Contrat sous-traitance RGPD</div>

    <!-- PDF Viewer using iframe -->
    <iframe [src]="safePdfSrc" width="100%" height="500px"></iframe>

    <div class="checkbox-container" *ngIf="!isPreview" (click)="isContractAccepted = !isContractAccepted;">
        <app-checkbox-input [checked]="isContractAccepted" ></app-checkbox-input>
        <div class="text">
            En cochant la case, j'accepte le contrat RPGD mis en place par la société Mocli dans le cadre de sa prestation de collecte et de traitement de données personnelles à des fins commerciales.
        </div>
    </div>

    <div class="confirm-contract-controls">
        <div class="mocli-button button cancel" (click)="closeModal('cancel')">{{isPreview ? 'Fermer' : 'Annuler'}}</div>
        <div class="mocli-button button confirm" *ngIf="!isPreview" [ngClass]="{'mocli-button-disabled': !isContractAccepted}" style="margin-left: 10px;" (click)="isContractAccepted ? closeModal('confirm') : ''">Confirmer</div>
    </div>
</div>