import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExperienceItemComponent } from './experience-item/experience-item.component';
import { IonicModule } from '@ionic/angular';
import { NotificationsComponent } from './notifications/notifications.component';
import { TodoListComponent } from './todo/todo-list/todo-list.component';
import { TodoTaskGroupComponent } from './todo/todo-task-group/todo-task-group.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { CarouselExperiencesComponent } from './carousel-experiences/carousel-experiences.component';
import { CheckboxInputComponent } from './input/checkbox-input/checkbox-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioInputComponent } from './input/radio-input/radio-input.component';
import { StepperComponent } from './stepper/stepper.component';
import { BadgeModule } from 'primeng/badge';
import { ScannerCustomModule } from './scanner-custom/scanner-custom.module';
import { ScannerCustomComponent } from './scanner-custom/scanner-custom.component';
import { TooltipModule } from 'primeng/tooltip';
import { StepsComponent } from '../steps/steps.component';
import { StepsItemComponent } from '../steps/steps-item/steps-item.component';
import { StepsService } from '../steps/steps.service';
import { StatusBadgeComponent } from './status-badge/status-badge.component';

@NgModule({
  declarations: [
    ExperienceItemComponent,
    TodoListComponent,
    TodoTaskGroupComponent,
    HeaderBarComponent,
    CarouselExperiencesComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    StepperComponent,
    NotificationsComponent,
    StepsComponent,
    StepsItemComponent,
    StatusBadgeComponent,
  ],
  exports: [
    ExperienceItemComponent,
    TodoListComponent,
    TodoTaskGroupComponent,
    HeaderBarComponent,
    CarouselExperiencesComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    StepperComponent,
    StepsComponent,
    StatusBadgeComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    TooltipModule
    // ScannerCustomModule
  ],
  providers: [
    // StepsService
  ]
})
export class SharedModule { }
