export class OddOneOut {
    name: string;
    objects: string[];
    intruder: string;
    explain: string;

    mainImageUrl: string;
    nextPageImageUrl: string;

    totalPages: number;
    totalQuestions: number;
    index: number;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    constructor() {
        this.name = '';
        this.objects = [];
        this.intruder = '';
        this.explain = '';

        this.mainImageUrl = '';

        this.nextPageImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-chest.svg';

        this.totalPages = 0;
        this.index = 0;
    }
}
