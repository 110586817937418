/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { ExperienceService } from '@mocli/MocliCommonLib';
import { OrderService } from '@mocli/MocliCommonLib';

@Component({
  selector: 'app-modal-preview-experience',
  templateUrl: './modal-preview-experience.component.html',
  styleUrls: ['./modal-preview-experience.component.scss'],
})
export class ModalPreviewExperienceComponent implements OnInit {
  @ViewChild('phoneContent', {read: ViewContainerRef, static: true}) phoneContent: ViewContainerRef;

  multiPageConfig: any = null;
  orderId: number = null;

  showPhoneError: boolean = false;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private orderService: OrderService,
    private experienceService: ExperienceService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.multiPageConfig) this.multiPageConfig = this.navParams.data.multiPageConfig;
    if (this.navParams?.data?.orderId) this.orderId = this.navParams.data.orderId;
    this.initPhone();
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
  }

  public async initPhone() {
    //on setup le contenu dan s le portable
    if (!this.multiPageConfig || !this.orderId) return;

    try {
      const order = await firstValueFrom(this.orderService.getOrderById(this.authService?.userLogged?.value?.id, this.orderId));

      // const comp = this.phoneContent.createComponent(ClientExperienceComponent);
      // comp.instance.offlineMode = true;
      // comp.instance.isExamplePhone = true;
      // comp.instance.orderId = this.orderId;
      // comp.instance.offlineOrder = {
      //   experienceInfoId: order.experienceInfoId,
      //   experienceOrderId: order.id,
      //   configuration: {
      //     MultiPageRoot: {
      //       configuration: this.multiPageConfig.MultiPageRoot.configuration,
      //       content: this.multiPageConfig.MultiPageRoot.content
      //     }
      //   }
      // };
    } catch (e) {
      // si les données ne sont pas trouvées
      this.showPhoneError = true;
    }
  }
}

