export class ThePriceOrWeightIsRight {
    name: string;
    place: string;
    value: number;

    unit: string;
    unitPlural: string;

    maxAttempts: number;

    questionImageUrl: string;

    nextQuestionImageUrl: string;
    idleQuestionImageUrl: string;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    totalQuestions: number;
    index: number;

    constructor() {
        this.name = '';
        this.place = '';
        this.unit = '';
        this.unitPlural = '';
        this.value = 0;
        this.maxAttempts = 1;
        this.questionImageUrl = '';
        this.nextQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-bulb.svg';
        this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-euro.svg';
        this.totalQuestions = 0;
        this.index = 0;
    }
}
