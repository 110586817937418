<div class="todo-container">
    <h2>TO DO</h2>
    <div class="todo-filters">
        <div class="filter" (click)="selectFilter('all')" [ngClass]="{'filter-selected': selectedFilter === 'all'}">
            <div class="dot white"></div>
            Tout afficher
        </div>
        <div class="filter" (click)="selectFilter(todoTaskStatusEnum.Started)" [ngClass]="{'filter-selected': selectedFilter === todoTaskStatusEnum.Started}">
            <div class="dot started"></div>
            En cours
        </div>
        <div class="filter" (click)="selectFilter(todoTaskStatusEnum.NotStarted)" [ngClass]="{'filter-selected': selectedFilter === todoTaskStatusEnum.NotStarted}">
            <div class="dot error"></div>
            À faire
        </div>
        <div class="filter" (click)="selectFilter(todoTaskStatusEnum.Finished)" [ngClass]="{'filter-selected': selectedFilter === todoTaskStatusEnum.Finished}">
            <div class="dot finished"></div>
            Terminées
        </div>
    </div>
</div>

<div class="todo-list">
    <ng-container *ngFor="let todoGroup of todoGroups">
        <app-todo-task-group *ngIf="hasTaskByFilter(selectedFilter, todoGroup)" [selectedFilter]="selectedFilter" [todoGroup]="todoGroup"></app-todo-task-group>
    </ng-container>
    <div class="no-tasks" *ngIf="!todoGroups || todoGroups.length === 0 || !hasTaskByFilter(selectedFilter, null, todoGroups)">Vous n'avez aucune tâche</div>
</div>