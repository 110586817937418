import { CookieService } from 'ngx-cookie-service';
/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BgConfigHelper } from './data/BgItem';
import { SidemenuService } from './services/SidemenuService';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuthService } from './services/AuthService';
import { ModalTutorialCatalogComponent } from './features/shared/modal/modal-tutorial-catalog/modal-tutorial-catalog.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalContactComponent } from './features/shared/modal/modal-contact/modal-contact.component';
import { PrimeNGConfig } from 'primeng/api';
import { HeaderBarService } from './services/HeaderBarService';
import { BackgroundShape } from './data/models/BackgroundShape';
import { BackgroundService } from './services/BackgroundService';
import { UserService } from '@mocli/MocliCommonLib';
import { PartialUserInfo } from '@mocli/MocliCommonLib/lib/data/models/UserInfo';
import { Location } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { AutoSaveConfig, AutoSaveService } from './services/AutoSaveService';
import { RolesEnum } from './data/enums/Roles.enum';
import { SimpleQuiz } from './data/models/SimpleQuiz';
import { CompTypeHelper } from './helpers/CompTypeHelper';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  modal: HTMLElement;
  currentPageBgConfig: BackgroundShape[] = [];
  hasHeaderBar: boolean = true;

  public basePages = [
    { name: 'Accueil', url: '/dashboard', icon: 'home.svg' },
    { name: 'Créer', url: '/experiences', icon: 'create.svg' },
    { name: 'Mes jeux', url: '/projects', icon: 'experiences.svg' },
    { name: 'Mes favoris', url: '/favorites', icon: 'favorites.svg' },
    { name: 'Mon compte', url: this.profileUrl(), icon: 'account.svg', },
    { name: 'Guide pratique', externalLink: true, url: 'https://www.mocli.fr/blog', icon: 'guide.svg' }
  ];

  public appPagesAdmin = [
    { name: 'Accueil', url: '/dashboard', icon: 'home.svg' },
    { name: 'Créer', url: '/experiences', icon: 'create.svg' },
    { name: 'Mes jeux', url: '/projects', icon: 'experiences.svg' },
    { name: 'Mes favoris', url: '/favorites', icon: 'favorites.svg' },
    { name: 'Mon compte', url: this.profileUrl(), icon: 'account.svg', },
    { name: 'Guide pratique', externalLink: true, url: 'https://www.mocli.fr/blog', icon: 'guide.svg' }
  ];

  public appPagesUser = [
    { name: 'Mes jeux', url: '/projects', icon: 'experiences.svg' },
    { name: 'Mon compte', url: this.profileUrl(), icon: 'account.svg', },
    { name: 'Guide pratique', externalLink: true, url: 'https://www.mocli.fr/blog', icon: 'guide.svg' }
  ];

  displayedPages = this.appPagesUser;

  public disconnect = {
    name: 'Déconnexion', icon: 'logout.svg'
  };

  public user: PartialUserInfo = null;

  public isAutoSaving: boolean = false;
  public autoSavingResult: boolean = null;

  public sideMenuShown: boolean;
  private sideMenuSubscription: Subscription;
  private launchTutorialSubscription: Subscription;
  private userLoggedSubscription: Subscription;
  private cantShowSideMenu: boolean = false;
  private bgSubscription: Subscription = null;
  private autoSaveSubscription: Subscription = null;

  private genericDefault: any = { SimpleQuiz: new SimpleQuiz() };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public modalCtrler: ModalController,
    private sideMenuService: SidemenuService,
    private translate: TranslateService,
    public authService: AuthService,
    public headerBarService: HeaderBarService,
    private backgroundService: BackgroundService,
    private config: PrimeNGConfig,
    private location: Location,
    private autoSaveService: AutoSaveService,
    private cookieService: CookieService,
    private dialogService: DialogService
  ) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.setupListener();
  }

  async ngOnInit() {
    const generic = CompTypeHelper.getGenericElementConfiguration(this.genericDefault);


    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.onNavEvent(e);
      }
    });

    this.currentPageBgConfig = BgConfigHelper.pageBgConfig.default;
    // this.currentPageBgConfig = [
    //   // CHASSE AUX OEUFS
    //   {
    //     svgName: 'easter-background-bottom.svg',
    //     bottom: 0,
    //     width: 100,
    //     isExperience: true
    //   },
    //   {
    //     svgName: 'easter-background-egg_blue.svg',
    //     width: 30,
    //     right: -11,
    //     bottom: 7,
    //     isExperience: true
    //   },
    //   {
    //     svgName: 'easter-background-egg_pink-2.svg',
    //     width: 30,
    //     left: -13,
    //     bottom: 35,
    //     isExperience: true
    //   },

    // ];
    this.headerBarService.onHeaderBarChanged.subscribe((data) => {
      this.hasHeaderBar = data !== null;
    });

    this.bgSubscription = this.backgroundService.backgroundShapes.subscribe((data: BackgroundShape[]) => {
      this.currentPageBgConfig = data;
    });

    this.autoSaveSubscription = this.autoSaveService.autoSaveSubject.subscribe((data: AutoSaveConfig) => {
      this.autoSavingResult = data.result;
      let timer = 0;
      if (this.isAutoSaving === true && data.active === false) {
        timer = 1000;
      }
      setTimeout(() => {
        this.isAutoSaving = data.active;
      }, timer);
    });

    this.sideMenuShown = this.sideMenuService.sideMenuShown;
    // setup de l'url de la page profile
    // find index of elem where name === 'Mon compte'
    const index = this.appPagesAdmin.findIndex((elem) => elem.name === 'Mon compte');
    this.basePages[index].url = this.profileUrl();
    this.appPagesAdmin[index].url = this.profileUrl();

    if (this.location.path().startsWith('/CheckPrize')) {
      this.hasHeaderBar = false;
      return;
    };

    try {
      if (this.authService?.userLogged?.value?.id) {
        this.user = await firstValueFrom(this.userService.getUserById(this.authService?.userLogged?.value?.id));
        this.authService.newUserLogged({...this.authService?.userLogged?.value, ...this.user});
        let roles = null;
        if (this.user?.roles && this.user.roles.length > 0) {
          roles = this.user.roles;
        }

        let showAdmin: boolean = false;
        if(roles.includes(RolesEnum.admin)) {
          showAdmin = true;
          this.displayedPages = this.appPagesAdmin;
        } else {
          this.displayedPages = this.appPagesUser;
        }



        this.sideMenuService.onSideMenuStateChanged.next(true);

        if (this.router.url === '/login') {
          if (this.user.hasOrdered) {
            this.router.navigateByUrl('/dashboard');
          } else {
            this.router.navigateByUrl('/experiences');
          }
        }
        this.headerBarService.updateHeaderBar({
          ...this.headerBarService.onHeaderBarChanged.value,
          showAdmin
        });
      } else {
        this.listenUser();
      }
    } catch (e) {
      this.listenUser();
    }
  }

  profileUrl() {
    return '/profile/' + this.authService?.userLogged?.value?.id;
  }

  listenUser() {
    if (this.userLoggedSubscription) return;

    this.userLoggedSubscription = this.authService.userLogged.subscribe(async user => {
      if (!user?.id) return;

      this.user = user;
      let roles = null;
      if (this.user?.roles && this.user.roles.length > 0) {
        roles = this.user.roles;
      } else if (user && Object.keys(user)?.length > 2 && user?.id) {
        try {
          this.user = await firstValueFrom(this.userService.getUserById(this.authService?.userLogged?.value?.id));
          if (this.user?.roles && this.user.roles?.length > 0) roles = this.user.roles;
        } catch (error) {
          console.error('error getting user roles on app.component.ts l: 174', error);
        }
      }

      let showAdmin: boolean = false;
      if(roles?.length > 0 && roles.includes(0) && this.appPagesAdmin.filter(page => page.name === 'Admin').length === 0) {
        showAdmin = true;
      }
      this.headerBarService.updateHeaderBar({
        ...this.headerBarService.onHeaderBarChanged.value,
        showAdmin
      });
    });
  }

  ngOnDestroy() {
    if (this.sideMenuSubscription) this.sideMenuSubscription.unsubscribe();
    if (this.launchTutorialSubscription) this.launchTutorialSubscription.unsubscribe();
    if (this.userLoggedSubscription) this.userLoggedSubscription.unsubscribe();
    if (this.bgSubscription) this.bgSubscription.unsubscribe();
    if (this.autoSaveSubscription) this.autoSaveSubscription.unsubscribe();
  }

  onNavEvent(data: any) {
    if(data.url && !this.cantShowSideMenu) {
      if(data.url === '/login'
      || data.url === '/register') {
        this.sideMenuService.onSideMenuStateChanged.next(false);
      }
    }
    const url = data.url.substring(1);

    if (url.startsWith('book/'))
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig['book/'];
    else if (url.startsWith('experience/'))
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig['experience/'];
    else if (url.startsWith('admin/'))
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig['admin/'];
    // else if (url.startsWith('register' || url.startsWith('login')))
    //   this.currentPageBgConfig = BgConfigHelper.pageBgConfig.register;
    else if (url.startsWith('configure/'))
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig['configure/'];
    else if (url.startsWith('customize/') || url.includes('stats/'))
      this.currentPageBgConfig = null;
    else if (BgConfigHelper.pageBgConfig[url])
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig[url];
    else
      this.currentPageBgConfig = BgConfigHelper.pageBgConfig.default;
  }

  setupListener() {
    this.sideMenuSubscription = this.sideMenuService.onSideMenuStateChanged.subscribe((data: boolean) => {
      let roles = null;
      if (this.user?.roles && this.user.roles.length > 0) {
        roles = this.user.roles;
      }

      let showAdmin = false;

      if(roles?.includes(RolesEnum.admin)) {
        showAdmin = true;
        this.displayedPages = this.appPagesAdmin;
      } else {
        this.displayedPages = this.appPagesUser;
      }

      this.headerBarService.updateHeaderBar({
        ...this.headerBarService.onHeaderBarChanged.value,
        showAdmin
      });

      this.sideMenuShown = data;

      if (!this.cantShowSideMenu) {
        this.cantShowSideMenu = true;
        setTimeout(() => {
          this.cantShowSideMenu = false;
        }, 1);
      }
    });

    this.launchTutorialSubscription = this.authService.launchTutorial.subscribe(() => {
      this.openCatalogTutorial();
    });
  }

  onDisconnectClicked() {
    this.authService.disconnectUser();
    this.router.navigateByUrl('/login');
  }

  async onContactClicked() {
    const modal = await this.modalCtrler.create({
       component: ModalContactComponent,
       cssClass: 'modal-tutorial-catalog',
      });
    return await modal.present();
  }

  async openCatalogTutorial() {
    const modal = await this.modalCtrler.create({
      component: ModalTutorialCatalogComponent,
      backdropDismiss: false,
      cssClass: 'modal-tutorial-catalog'
    });

    modal.onWillDismiss().then((dataReturned: any) => {
      if (dataReturned !== null && dataReturned.data === 'hasDoneTutorialCatalog') {
        this.authService.setTutorialCatalogDone();
      }
      if (dataReturned !== null && dataReturned.data === 'confirm') {

      }
    });
    return await modal.present();
  }

  openNewTab(url: string) {
    window.open(url, '_blank').focus();
  }

  getStyleBgConfig(config: any) {
    let finalStr = 'position: absolute;';

    if (config?.height) finalStr += 'height: ' + config.height + '%;';
    if (config?.width) finalStr += 'width: ' + config.width + '%;';
    if (config?.top !== undefined) finalStr += 'top: ' + config.top + '%;';
    if (config?.left !== undefined) finalStr += 'left: ' + config.left + '%;';
    if (config?.right !== undefined) finalStr += 'right: ' + config.right + '%;';
    if (config?.bottom !== undefined) finalStr += 'bottom: ' + config.bottom + '%;';

    return finalStr;
  }

  deleteUser() {
    this.authService.userLogged.next(null);
  }
}
