import { BackgroundShape } from './models/BackgroundShape';

export class BgConfigHelper {
   public static pageBgConfig: { [pageName: string]: BackgroundShape[] } =
   {
      default: [
         {
            svgName: 'form3.svg',
            left: 60,
            top: 15,
            height: 60,
            width: 60,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form4.svg',
            left: 10,
            top: 80,
            height: 25,
            width: 25,
            color: 'red',
            objectFit: null,
         },
      ],
      dashboard: [
         {
            svgName: 'form5.svg',
            left: 55,
            top: 25,
            height: 45,
            width: 45,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form6.svg',
            left: 71,
            top: 60,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form7.svg',
            left: -17,
            top: 54,
            height: 50,
            width: 50,
            color: 'blue',
            objectFit: null,
         },
      ],
      register: [
         {
            svgName: 'form5.svg',
            left: 55,
            top: 25,
            height: 45,
            width: 45,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form6.svg',
            left: 71,
            top: 60,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form7.svg',
            left: -17,
            top: 54,
            height: 50,
            width: 50,
            color: 'blue',
            objectFit: null,
         },
      ],
      favorites: [
         {
            svgName: 'form3.svg',
            left: 65,
            top: 15,
            height: 60,
            width: 60,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form4.svg',
            left: 0,
            top: 72,
            height: 50,
            width: 50,
            color: 'red',
            objectFit: null,
         },
      ],
      'book/': [
         {
            svgName: 'form5.svg',
            left: -11,
            top: 7,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form11.svg',
            left: 60,
            top: 75,
            height: 40,
            width: 40,
            color: 'red',
            objectFit: null,
         },
      ],
      experiences: [
         {
            svgName: 'form3.svg',
            left: 65,
            top: 63,
            height: 50,
            width: 50,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form4.svg',
            left: -10,
            top: 60,
            height: 30,
            width: 30,
            color: 'red',
            objectFit: null,
         },
      ],
      'experience/': [
         {
            svgName: 'form9.svg',
            left: 68,
            top: 65,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form8.svg',
            left: -27,
            top: 27,
            height: 90,
            width: 90,
            color: 'red',
            objectFit: null,
         },
      ],
      'experiences/configure/quiz': [
         {
            svgName: 'form10.svg',
            left: 45,
            top: 50,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form11.svg',
            left: 3,
            top: 20,
            height: 15,
            width: 15,
            color: 'red',
            objectFit: null,
         },
      ],
      projects: [
         {
            svgName: 'form12.svg',
            left: 45,
            top: 30,
            height: 70,
            width: 70,
            color: 'blue',
            objectFit: null,
         }
      ],
      'admin/': [
      ],
      'configure/': [
         {
            svgName: 'form5.svg',
            left: -11,
            top: 7,
            height: 40,
            width: 40,
            color: 'blue',
            objectFit: null,
         },
         {
            svgName: 'form11.svg',
            left: 60,
            top: 75,
            height: 40,
            width: 40,
            color: 'red',
            objectFit: null,
         },
      ],
      'client-experience': [
         {
            svgName: 'default_0.svg',
            width: 25,
            left: -14,
            bottom: -14,
            isExperience: true
         },
         {
            svgName: 'default_1.svg',
            width: 48,
            right: -38,
            top: 16,
            isExperience: true
         },
         {
            svgName: 'default_2.svg',
            width: 80,
            right: -46,
            bottom: -35,
            isExperience: true
         },
         // {
         //    svgName: 'easter-background-bottom.svg',
         //    left: 0,
         //    top: 57,
         //    height: 45,
         //    width: 100,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_blue.svg',
         //    left: -12,
         //    top: 50,
         //    height: 30,
         //    width: 30,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_pink.svg',
         //    left: 87,
         //    top: 10,
         //    height: 30,
         //    width: 30,
         //    objectFit: null,
         //    color: 'blue'
         // }


         // {
         //    svgName: 'easter-background-bottom.svg',
         //    left: 0,
         //    top: 57,
         //    height: 45,
         //    width: 100,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_blue.svg',
         //    left: 7,
         //    top: 45,
         //    height: 30,
         //    width: 30,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_pink-2.svg',
         //    left: 90,
         //    top: 46,
         //    height: 18,
         //    width: 18,
         //    objectFit: null,
         //    color: 'blue'
         // }

         // {
         //    svgName: 'easter-background-bottom.svg',
         //    left: 0,
         //    top: 57,
         //    height: 45,
         //    width: 100,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_blue.svg',
         //    left: 84,
         //    top: 73,
         //    height: 25,
         //    width: 25,
         //    objectFit: null,
         //    color: 'blue'
         // },
         // {
         //    svgName: 'easter-background-egg_pink-2.svg',
         //    left: -13,
         //    top: 41,
         //    height: 30,
         //    width: 30,
         //    objectFit: null,
         //    color: 'blue'
         // }
      ]
   };
}
