import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent {
  @Input() label: string;
  @Input() disable: boolean = false;
  @Input() name: string;
  @Input() id: string;
  @Input() tabIndex: number = null;

  @Input() checked: boolean = false;

  public valueCheckbox: boolean;

  constructor() { }
}

