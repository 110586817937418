<div class="modal-data">
  <div class="title">{{fieldName ?? 'Énnoncé'}}</div>

  <div class="required-container">
    <div class="input-description" style="margin-top: 16px;">Le champs doit-il être obligatoirement complété par l’utilisateur ? </div>
    <div class="choice">
      <div class="label">Oui</div>
      <app-radio-input (click)="isRequired = true" [checked]="isRequired === true"></app-radio-input>
    </div>
    <div class="choice" style="margin-top: 12px;">
      <div class="label">Non</div>
      <app-radio-input (click)="isRequired = false" [checked]="isRequired === false"></app-radio-input>
    </div>
  </div>

  <div class="controls-data">
    <div class="btn btn-cancel" (click)="closeModal()">Annuler</div>
    <div class="btn btn-confirm" (click)="confirm()">Confirmer</div>
  </div>
</div>