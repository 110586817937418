<div class="codehunt-guide-container ch-color__primary ch-background-color__modal">
  <!-- Top -->
  <div class="top">
    <div class="title">{{'guide-title' | translate}}</div>
  </div>
  
  <!-- Content -->
  <div class="content">
    <div *ngIf="pageIndex === 0">
      <div class="text">{{'guide-0-welcome' | translate : {code: stickerWord} }}</div>
      <div class="text">{{'guide-0-objective' | translate}}</div>
      <div class="text">{{'guide-0-info-first' | translate}} <b class="ch-color__fourth">{{totalCodes}} {{stickerWord}}{{totalCodes > 1 ? 's' : ''}}</b> {{'guide-0-info-second' | translate}} : <b class="ch-color__fourth">{{perimeter}}</b>.</div>
      <div class="text" *ngIf="specialMessage"><b class="ch-color__fourth">{{specialMessage}}</b></div>
      <div class="glasses">
        <img src="https://cdn.mocli.fr/icon/glasses.png">
      </div>
    </div>

    <div *ngIf="pageIndex === 1">
      <div class="text">{{'guide-click-on' | translate}}</div>
      <div class="btn">
        <app-code-hunt-button [imageUrl]="'https://cdn.mocli.fr/icon/scan.png'" [text]="translateService.instant('btn-scan')" [classes]="'ch__btn-second'"></app-code-hunt-button>
      </div>
      <div class="text">{{'guide-1-first' | translate}}.</div>
      <div class="text">{{'guide-1-second' | translate}}.</div>
      <div class="example" *ngIf="exampleImageUrl">
        <div class="text">{{'guide-1-third' | translate}} :</div>
        <div class="example-container">
          <img [src]="exampleImageUrl" alt="exemple de sticker à retrouver">
        </div>
      </div>
    </div>

    <div *ngIf="pageIndex === 2">
      <div class="text">{{'guide-click-on' | translate}}</div>
      <div class="btn">
        <app-code-hunt-button [imageUrl]="'https://cdn.mocli.fr/icon/podium.png'" [text]="translateService.instant('btn-leaderboard')" [classes]="'ch__btn-first'"></app-code-hunt-button>
      </div>
      <div class="text">{{'guide-2-first' | translate}}.</div>
      <div class="podium">
        <div class="text">{{'guide-2-second' | translate}} :</div>
        <div class="podium-container">
          <div class="podium-table">
            <p-table #tableLeaderboard id="tableLeaderboard" [value]="leaderBoard" styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th class="ch-color__tertiary" style="display: table-cell !important; background-color: transparent !important">{{'label-position' | translate}}</th>
                  <th class="ch-color__tertiary" style="display: table-cell !important; background-color: transparent !important; padding-left: 15px !important">{{'label-pseudo' | translate}}</th>
                  <th class="ch-color__tertiary" style="display: table-cell !important; background-color: transparent !important; padding-right: 10px !important">{{'label-points' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-playerInfo let-i="rowIndex">  
                <tr>
                  <td class="ch-color__tertiary" style="display: table-cell !important; width:1% !important;white-space:nowrap !important;">
                    {{i + 1}}e{{i === 0 ? 'r' : ''}}
                  </td>
                  <td class="ch-color__tertiary" style="display: table-cell !important; padding-left: 15px !important; padding-right: 10px !important; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 180px;">
                    {{playerInfo.name}}
                  </td>
                  <td class="ch-color__tertiary" style="display: table-cell !important">
                    {{playerInfo.score}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="pageIndex === 3">
      <div class="text">{{'guide-click-on' | translate}}</div>
      <div class="btn">
        <app-code-hunt-button [imageUrl]="'https://cdn.mocli.fr/icon/hint-icon2.png'" [text]="translateService.instant('btn-hint')" [classes]="'ch__btn-third'"></app-code-hunt-button>
      </div>
      <div class="text">{{'guide-3-first' | translate}}.</div>

      <div class="hint">
        <div class="hint-title ch-color__primary color-custom">{{'hint-title' | translate}} 5/15 :</div>
        <div class="hint-content">{{'guide-3-second' | translate}}</div>
      </div>
    </div>

    <div *ngIf="pageIndex === 100">
      <div class="wand">
        <img src="https://cdn.mocli.fr/icon/magic-wand.png" alt="baguette magique">
      </div>
      <div class="final-text">{{'guide-4-first' | translate}}</div>
      <div class="final-text">{{'guide-4-second' | translate}}</div>
      <div class="btn">
        <app-code-hunt-button (onClick)="closeModal(true)" [imageUrl]="'https://cdn.mocli.fr/icon/home.png'" [text]="translateService.instant('btn-start-exp')" [classes]="'ch__btn-primary'"></app-code-hunt-button>
      </div>
    </div>
  </div>
  
  <!-- Bottom -->
  <div class="bottom" *ngIf="pageIndex !== 100">
    <div class="left-btn">
      <img (click)="updatePage(-1)" *ngIf="pageIndex > 0" [src]="previousIconUrl" alt="bouton précédent">
    </div>
    <div class="pagination">
      {{pageIndex + 1}} / {{totalPages}}
    </div>
    <div class="right-btn">
      <img (click)="updatePage(1)" [src]="nextIconUrl" alt="bouton suivant">
    </div>
  </div>
</div>
