import { Injectable } from '@angular/core';
import { HttpService } from './HttpService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './AuthService';
import { CdnResponse } from '../data/models/cdnResponse';
import { DateTime } from 'luxon';

@Injectable({providedIn: 'root'})
export class HelpersService {
    private requester: HttpService;

    constructor(
        public client: HttpClient,
        private _authService: AuthService
    ) {
        const endpoint = environment.mainUrl + '/api/Helper';
        this.requester = new HttpService(client, endpoint, _authService);
    }

    obfuscate(num: number): Observable<number> {
        const params = new HttpParams().append('number', num);
        return this.requester.getApi<number>('ObfuscateNumber', params);
    }

    deobfuscate(num: number): Observable<number> {
        const params = new HttpParams().append('number', num);
        return this.requester.getApi<number>('DeobfuscateNumber', params);
    }

    //pour récupérer les fichiers sur le S3. On récupère tous les fichiers de la clés passé
    // la clé peut être un préfixe de dossier
    // ex: key = icon/ -> on récupère tous les fichiers dans icon
    // si on veux tous les fichiers on peut passer *all en key
    listS3Objects(key: string): Observable<CdnResponse[]> {
        const params = new HttpParams().append('key', key);
        return this.requester.getApi<CdnResponse[]>('S3ListObjectsForKey', params);
    }

    logger(orderId: number, message: string): Observable<any> {
        const params = new HttpParams().append('orderId', orderId);
        const data = JSON.stringify(message);
        return this.requester.postApi('Log', data, params);
    }

    getMongoDateTime(date: DateTime): Observable<any> {
        const params = new HttpParams().append('time', date.toString());
        return this.requester.getApi<any>('GetMongoDateTime', params);
    }
}
