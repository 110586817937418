import { DateTime } from 'luxon';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
import { RandomHelper } from 'src/app/helpers/RandomHelper';
import { AuthService } from 'src/app/services/AuthService';
import { isComplete } from '../IsComplete';
import { UserService } from '@mocli/MocliCommonLib';
import { SeekAndScan } from 'src/app/data/models/SeekAndScan';

@Component({
  selector: 'app-seek-and-scan-configurator',
  templateUrl: './seek-and-scan-configurator.component.html',
  styleUrls: ['./seek-and-scan-configurator.component.scss'],
})
export class SeekAndScanConfiguratorComponent implements OnInit, isComplete {
  config: any;
  data: SeekAndScan;
  saved = false;
  orderId: number = null;
  pageIndex: number = null;
  inputDisabled: boolean = null;

  visualFile: File;
  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  seekAndScanForm: FormGroup;
  qrCodeSolution: string;

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private authSrv: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.data = this.config.SeekAndScan;
    this.orderId = this.route.snapshot.params.id;

    this.data.showHintTimer = true;
    this.data.showSkipButton = true;
    if (!this.data.solution || this.data.solution.length === 0) {
      this.data.solution = this.generateSolutionCode();
    }

    this.seekAndScanForm = this.fb.group({
      hint: [
        {value: this.data.hint, disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      solutionDescription: [
        {value: this.data.solutionDescription, disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      solution: [
        {value: this.data.solution, disabled: true},
        [Validators.required, Validators.maxLength(5), Validators.pattern('^[a-zA-Z0-9]*')]
      ]
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  public configuratorComplete(): boolean {
    return this.seekAndScanForm.valid;
  }

  //attention cette méthode doit être la plus légère possible
  autoSave() {
    this.qrCodeSolution = (this.seekAndScanForm.get('solution').value as string).toUpperCase();

    this.isValidEventEmitter.emit(this.seekAndScanForm.valid);

    if (this.seekAndScanForm.invalid)
      return;

    this.data.hint = this.seekAndScanForm.get('hint').value;
    this.data.solutionDescription = this.seekAndScanForm.get('solutionDescription').value;
    this.data.solution = (this.seekAndScanForm.get('solution').value as string).toUpperCase();
  }

  generateSolutionCode() {

    const str: string = this.orderId.toString() + this.pageIndex;
    const nb: number = JSON.parse(str);
    const random = RandomHelper.getRandomNumber(nb);

    let numberSeq = Math.ceil(random * 1000);
    if (numberSeq < 100) numberSeq *= 10;

    let letterIdx = Math.ceil(random * 100);
    if (letterIdx < 10) letterIdx *= 10;
    const firstLetter = RandomHelper.alphabet[Math.ceil(letterIdx / 10)];
    const secondLetter = RandomHelper.alphabet[letterIdx % 10];

    const finalStr = firstLetter + secondLetter + numberSeq.toString();

    this.qrCodeSolution = finalStr;
    return finalStr;
  }

  async deleteFile() {
    if (this.uploadedImage?.name) {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    }
  }

  async onFileUpload(event) {
    const files = event.target.files;
    const file = files[0] as File;
    this.visualFile = file;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        firstValueFrom(uploadPromise).then(res => {
          this.visualImageUrl = res.url;
          this.uploadedImage = res;
        });
      } else {
        //max file size
        console.error('file > 5mb');
      }
    };

    reader.readAsArrayBuffer(file);
  }
}
