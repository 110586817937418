import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ExperienceInfo } from '@mocli/MocliCommonLib/lib/data/models/ExperienceInfo';

@Component({
  selector: 'app-carousel-experiences',
  templateUrl: './carousel-experiences.component.html',
  styleUrls: ['./carousel-experiences.component.scss'],
})
export class CarouselExperiencesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title: string = 'Nos suggestions du moment';
  @Input() experiences: ExperienceInfo[] = [];

  public experienceDisplayed: ExperienceInfo = null;

  public index: number = 1;
  private interval: any;

  private stepDuration: number = 3000; // in milliseconds


  constructor() { }

  ngOnInit() {
    if (this.experiences) this.setupCarousel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.experiences?.currentValue?.length > 0) {
      if (this.interval)
        clearInterval(this.interval);
      this.setupCarousel();
    }
  }

  ngOnDestroy() {
    if (this.interval)
      clearInterval(this.interval);
  }

  setupCarousel() {
    if (!this.experiences || this.experiences.length <= 0) return;
    this.experienceDisplayed = this.experiences[0];
    this.interval = setInterval(() => {
      if (this.index >= this.experiences.length) this.index = 0;
      this.experienceDisplayed = this.experiences[this.index];
      this.index++;
    }, this.stepDuration);
  }

  clickOnDot(index: number) {
    this.index = index +1;
    if (index <= this.experiences.length) this.experienceDisplayed = this.experiences[index];
  }
}
