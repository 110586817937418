import { ModalController, NavParams } from '@ionic/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-ionic-confirm-prime',
  templateUrl: './modal-ionic-confirm-prime.component.html',
  styleUrls: ['./modal-ionic-confirm-prime.component.scss']
})
export class ModalIonicConfirmPrimeComponent {
  constructor(
    private modalController: ModalController
  ) {}

  cancel() {
    this.modalController.dismiss('cancel');
  }

  leave() {
    this.modalController.dismiss('leave');
  }
}
