import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: FormGroup;
  @Input() name: string;

  @Input() startValue: number;
  @Output() startValueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() suffix: string;
  @Input() step: number;

  @Input() min: number = 0;
  @Input() max: number = 10000;

  @Input() noMargin: boolean = false;
  @Input() noNegative: boolean = false;
  @Input() disabled: boolean = false;
  @Input() resetSubject: Subject<void>;

  public value: number;
  public resetSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.value = this.startValue;

    if (this.resetSubject) {
      this.resetSubscription = this.resetSubject.subscribe(() => {
        this.value = 0;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.startValue) {
      const newValue = changes.startValue.currentValue;
      if (newValue < this.min || newValue > this.max) return;
      this.value = newValue;

      if (this.group && this.group.get(this.name)) {
        this.group.get(this.name).patchValue(newValue);
      }
    }
  }

  ngOnDestroy() {
    if (this.resetSubscription) this.resetSubscription.unsubscribe();
  }

  updateValue(value: number) {
    if (this.value + value < this.min || this.value + value > this.max)
      return;

    this.value += value;
    if (this.group && this.group.get(this.name)) {
      this.group.get(this.name).patchValue(this.value);
    }

    this.startValueChange.emit(this.value);
  }
}
