import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ModalConfirmPrimeComponent } from '../features/shared/modal/modal-confirm-prime/modal-confirm-prime.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(
    private dialogService: DialogService,
  ) {}

  openConfirmationModal(config: any): void {
    const ref = this.dialogService.open(ModalConfirmPrimeComponent, {
      showHeader: false,
      data: config,
      closeOnEscape: false
    });
  }
}
