import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-modal-stats-custom-period',
  templateUrl: './modal-stats-custom-period.component.html',
  styleUrls: ['./modal-stats-custom-period.component.scss'],
})
export class ModalStatsCustomPeriodComponent implements OnInit {
  public calendarFormGroup: FormGroup;

  public startDate: Date = null;
  public endDate: Date = null;

  public startDateStr: string = null;
  public endDateStr: string = null;

  public orderStartDate = null;
  public orderEndDate = null;

  startTime: Date = DateTime.now().startOf('day').toJSDate();
  endTime: Date = DateTime.now().endOf('day').toJSDate();

  customDates: {startDate: DateTime; endDate: DateTime} = null;

  errorMessage: string = null;

  constructor(
    public modalController: ModalController,
    private fb: FormBuilder,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.dates?.orderStartDate) this.orderStartDate = new Date(this.navParams.data.orderStartDate);
    if (this.navParams?.data?.dates?.orderEndDate) this.orderEndDate = new Date(this.navParams.data.orderEndDate);

    if (this.navParams?.data?.customDates) {

      if (this.navParams.data.customDates.startDate) {
        this.customDates.startDate = this.navParams.data.customDates.startDate.plus({day: 1});
        this.startDate = this.customDates.startDate.toJSDate();
        this.startDateStr = this.formatDate(this.startDate);
        this.startTime = this.startDate;
      }
      if (this.navParams.data.customDates.endDate) {
        this.customDates.endDate = this.navParams.data.customDates.endDate.plus({day: 1});
        this.customDates.endDate = this.customDates.endDate.minus({day: 1});
        this.endDate = this.navParams.data.customDates.endDate.toJSDate();
        this.endDateStr = this.formatDate(this.endDate);
        this.endTime = this.endDate;
      }
    }

    this.initFormGroup();
  }

  public initFormGroup() {
    let calendarRange: any = null;

    if (this.customDates) {
      calendarRange = [this.startDate, this.endDate];
    }

    this.calendarFormGroup = this.fb.group({
      calendarRange: null,
      startTime: this.customDates?.startDate ? this.startTime : DateTime.now().startOf('day').toJSDate(),
      endTime: this.customDates?.endDate ? this.endTime : DateTime.now().endOf('day').toJSDate()
    });

    if (calendarRange) this.calendarFormGroup.get('calendarRange').patchValue(calendarRange);

    this.calendarFormGroup.valueChanges.subscribe((data: any) => {
      if (data?.calendarRange && data?.calendarRange[0]) {
        this.startDate = new Date(data.calendarRange[0]);
      } else {
        this.startDate = null;
      }

      if (data?.calendarRange && data?.calendarRange[1]) {
        this.endDate = new Date(data.calendarRange[1]);
      } else {
        this.endDate = null;
      }

      if (data?.startTime) {
        this.startTime = data.startTime;
      } else {
        this.startTime = null;
      }

      if (data?.endTime) {
        this.endTime = data.endTime;
      } else {
        this.endTime = null;
      }

      if (this.startDate) this.startDateStr = this.formatDate(this.startDate);
      if (this.endDate) this.endDateStr = this.formatDate(this.endDate);

    });
  }

  onConfirmClick() {
    const values = this.calendarFormGroup?.get('calendarRange').value;
    if (!values || !values[0] || !values[1]) {
      this.errorMessage = 'Veuillez sélectionner une période valide.';
      return;
    }

    if (!this.startTime || !this.endTime) {
      if (!this.startTime) this.errorMessage = 'Veuillez sélectionner une heure de début.';
      if (!this.endTime) this.errorMessage = 'Veuillez sélectionner une heure de fin.';
      if (!this.startTime && !this.endTime) this.errorMessage = 'Veuillez sélectionner une plage horaire valide.';
      return;
    }

    this.errorMessage = null;

    const onClosedData: { startDate: Date; endDate: Date } = {
      startDate: this.startDate ? new Date(this.startDate.setDate(this.startDate.getDate())) : null,
      endDate: this.endDate
    };

    if (onClosedData?.startDate) {
      onClosedData.startDate.setHours(
        this.startTime.getHours(),
        this.startTime.getMinutes(),
        0
      );
    }

    if (onClosedData?.endDate) {
      onClosedData.endDate.setHours(
        this.endTime.getHours(),
        this.endTime.getMinutes(),
        0
      );
    }
    this.closeModal('confirm', onClosedData);
  }

  async closeModal(role: string, data: any = null) {
    await this.modalController.dismiss(data, role);
  }

  formatDate(inputDate: Date) {
    if (!inputDate) return null;

    let date: any;
    let month: any;
    const year: any = inputDate.getFullYear();;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;

    date = date.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');

    return `${date}/${month}/${year}`;
  }
}
