<!---------------------------- SMALL CARD  ---------------------------->

<ion-card *ngIf="format === 'small'" class="small-exp-item">
  <div class="clicker">
    <a [href]="'experience/' + experience?.id"></a>
  </div>
  <!-- Header Image -->
  <div class="small-exp-img">
    <ion-img [src]="experience?.imageUrl ?? '/../../../assets/experience-img-offline/chasse-au-tresor.png'"></ion-img>
  </div>
  
  <!-- Content -->
  <ion-card-content>

    <!-- Title -->
    <ion-card-title>
      {{experience?.name ?? ''}}
    </ion-card-title>

    <!-- Duration -->
    <ion-card-subtitle class="duration">
      {{experience?.duration ?? ''}}
    </ion-card-subtitle>

    <!-- Description -->
    <ion-card-subtitle class="description">
      {{experience?.shortDescription ?? ''}}
    </ion-card-subtitle>

    <!-- Categories -->
    <ion-card-subtitle class="categories" *ngIf="experience">
      <span *ngFor="let tag of experience.categories; let l = last">
        {{tag}}{{l ? '': ','}}&nbsp;
      </span>
    </ion-card-subtitle>

    <!-- Heart icon -->
    <ion-icon class="heart-icon" (click)="toggleFavorite()" [name]="favorited ? 'heart' : 'heart-outline'" size="medium"></ion-icon>

  </ion-card-content>
</ion-card>

<!---------------------------- LARGE CARD  ---------------------------->

<ion-card *ngIf="format === 'large'" (click)="navigateToExperience()" class="large-exp-item">
  <!-- Header Image -->
  <div class="large-exp-img">
    <ion-img [src]="experience?.imageUrl ?? '../../../../assets/experience-img-offline/chasse-au-tresor.png'"></ion-img>
  </div>
  
  <!-- Content -->
  <ion-card-content>

    <!-- Title -->
    <ion-card-title>
      {{experience?.name ?? ''}}
    </ion-card-title>

    <!-- Duration -->
    <ion-card-subtitle class="duration">
      {{experience?.duration ?? ''}}
    </ion-card-subtitle>

    <!-- Description -->
    <ion-card-subtitle class="description">
      {{experience?.shortDescription ?? ''}}
    </ion-card-subtitle>

    <!-- Categories -->
    <ion-card-subtitle class="categories" *ngIf="experience">
      <span *ngFor="let tag of experience.categories; let l = last">
        {{tag}}{{l ? '': ','}}&nbsp;
      </span>
    </ion-card-subtitle>

  </ion-card-content>
</ion-card>