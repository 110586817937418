<ion-content>
  <ion-grid >
    <ion-row class="title">
      <h1>Voulez risquez de perdre vos modifications.</h1>
    </ion-row>
    <ion-row class="controls">
      <div class="mocli-button button confirm" (click)="closeModal('leave')">Quitter</div>
      <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="closeModal('leave-save')">Quitter et sauvegarder</div>
      <div class="mocli-button button cancel" style="margin-left: 10px;" (click)="closeModal('cancel')">Retour</div>
    </ion-row>
  </ion-grid>
</ion-content>