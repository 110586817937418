<div class="mocli-box-content configure-question">
  <form [formGroup]="priceOrWeightIsRightForm">
    <!-- Name -->
    <div class="question-info">
      <div class="big-title title">Nom de l'objet {{data.index + 1}}</div>
      <div class="question-content">
        <textarea formControlName="name"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': priceOrWeightIsRightForm.controls.name.invalid, 'disabled-input': inputDisabled}">
                </textarea>
        <div class="question-content-counter">
          <span *ngIf="priceOrWeightIsRightForm.controls.name.invalid" class="invalid-input-text">
            <span *ngIf="priceOrWeightIsRightForm.controls.name?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="priceOrWeightIsRightForm.controls.name?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{priceOrWeightIsRightForm.controls.name.value.length}} / 50
          </span>
        </div>
      </div>
    </div>

    <!-- Place -->
    <div class="explain">
      <div class="visuel-title">
        <div class="title">Emplacement</div>
        <div class="optional">(optionnel, uniquement si présent physiquement)</div>
      </div>
      <div class="question-content inline-button" style="margin-top: 7px;">
        <input type="text" 
              pInputText
              placeholder="Écrire ici..."
              formControlName="place"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': priceOrWeightIsRightForm.controls.place.invalid, 'disabled-input': inputDisabled}">
        <div class="question-content-counter">
          <span *ngIf="priceOrWeightIsRightForm.controls.place.invalid" class="invalid-input-text">
            <span *ngIf="priceOrWeightIsRightForm.controls.place?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="priceOrWeightIsRightForm.controls.place?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{priceOrWeightIsRightForm.controls.place.value.length}} / 100
          </span>
        </div>
      </div>
    </div>

    <!-- Value -->
    <div class="explain">
      <div class="visuel-title">
        <div class="title">Nombre à trouver</div>
        <div class="optional">(sans décimales)</div>
      </div>
      <div class="small-content inline-button" style="margin-top: 7px;">
        <input type="number" 
              pInputText
              [min]="0"
              placeholder="Écrire ici..."
              formControlName="value"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': priceOrWeightIsRightForm.controls.value.invalid, 'disabled-input': inputDisabled}">
        <div class="question-content-counter">
          <span *ngIf="priceOrWeightIsRightForm.controls.value.invalid" class="invalid-input-text">
            <span *ngIf="priceOrWeightIsRightForm.controls.value?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="priceOrWeightIsRightForm.controls.value?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{priceOrWeightIsRightForm.controls.value.value.toString().length}} / 20
          </span>
        </div>
      </div>
    </div>

    

    <!-- Attempts -->
    <div class="explain">
      <div class="visuel-title">
        <div class="title">Nombre de tentatives</div>
        <div class="optional">(entre 1 et 5, indiquera “plus” ou “moins” selon la réponse donnée)</div>
      </div>
      <div class="stepper-price-is-right">
        <app-stepper [disabled]="inputDisabled" (startValueChange)="onAttemptsChanged($event)" [startValue]="data?.maxAttempts ?? 1" [step]="1" [min]="1" [max]="5"></app-stepper>
      </div>
    </div>
  </form>

  <!-- Visuel -->
  <div class="visuel" style="margin-top: 15px;">
    <div class="visuel-title">
      <div class="title">Visuel</div>
      <div class="optional">(optionnel)</div>
    </div>
    <div *ngIf="!visualImageUrl">
      <input #inputFile 
            [id]="'inputFile-' + data?.index"
            type="file"
            name="file"
            class="inputfile"
            accept="image/*"
            (change)="onFileUpload($event)">
      <label [for]="'inputFile-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrl" class="visual-name-container">
      <div class="delete">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="inputDisabled ? null : deleteFile()" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrl" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
  </div>
</div>