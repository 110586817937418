export class LanguagePicker {
    imageUrl: string;
    secondaryImageUrl: string;
    startButtonImageUrl: string;

    constructor() {
        this.imageUrl = '';
        this.secondaryImageUrl = '';
        this.startButtonImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
    }
}
