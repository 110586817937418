import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommunicationKitElement } from 'src/app/data/models/CommunicationKitElement';

@Component({
  selector: 'app-modal-preview-template-support',
  templateUrl: './modal-preview-template-support.component.html',
  styleUrls: ['./modal-preview-template-support.component.scss'],
})
export class ModalPreviewTemplateSupportComponent implements OnInit {
  detailSupport: CommunicationKitElement = null;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    if (this.navParams.data && this.navParams.data.detailSupport) this.detailSupport = this.navParams.data.detailSupport;
  }

  async closeModal(data: any) {
    const onClosedData: any = data;
    await this.modalController.dismiss(onClosedData);
  }

}
