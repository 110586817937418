import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { UserService } from '@mocli/MocliCommonLib';
import { AddressTypeEnum } from '@mocli/MocliCommonLib/lib/data/enums/AddressType.enum';
import { Address } from '@mocli/MocliCommonLib/lib/data/models/Address';
import { CompanyInfo } from '@mocli/MocliCommonLib/lib/data/models/CompanyInfo';

@Component({
  selector: 'app-modal-update-adress',
  templateUrl: './modal-update-adress.component.html',
  styleUrls: ['./modal-update-adress.component.scss'],
})
export class ModalUpdateAdressComponent implements OnInit {
  company: CompanyInfo;
  adress: Address;
  type: AddressTypeEnum;
  userId: number;

  adressFormGroup: FormGroup;
  // addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;

  constructor(
    private navParams: NavParams,
    private userService: UserService,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalCtrler: ModalController
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.company)
      this.company = this.navParams.data.company;

    if (this.type === AddressTypeEnum.billing) {
      this.adress = this.company.addresses[0];
    } else if (this.type === AddressTypeEnum.shipping) {
      this.adress = this.company.addresses[1];
    }

    if (this.navParams?.data?.type)
      this.type = this.navParams.data.type;

    this.initFormGroup();
  }

  initFormGroup() {
    this.adressFormGroup = this.fb.group({
      addressName: [this.adress.addressName ?? '', Validators.required],
      streetNumber: [this.adress.streetNumber ?? 0, Validators.required],
      postalCode: [this.adress.postalCode ?? 0, Validators.required],
      city: [this.adress.city ?? '', Validators.required],
      country: [this.adress.country ?? '', Validators.required],
    });
  }

  async onSubmit() {
    if (this.adressFormGroup.valid) {
      try {
        const adress = this.adressFormGroup.value;
        this.company.locations = [];

        if (this.type === AddressTypeEnum.billing) {
          this.company.addresses[0] = adress;
          const facturationInfo = this.userService.updateFacturationInfo(this.userId, this.company);
          await firstValueFrom(facturationInfo);
        } else if (this.type === AddressTypeEnum.shipping) {
          this.company.addresses[1] = adress;
          const shippingInfo = this.userService.updateShippingInfo(this.userId, this.company);
          await firstValueFrom(shippingInfo);
        }

        await this.modalCtrler.dismiss();

      } catch (err) {
        console.error(err);
      }
    }
  }
}
