<div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
  <img [src]="'https://cdn.mocli.fr/icon/cross.svg'" alt="close modal">
</div>

<div *ngIf="selectedIdx === -1" class="choice-container terms">
  <div class="big-border-custom terms-item ch-color__tertiary " (click)="selectedIdx = 0">
    <div class="terms-item-title">Termes et conditions de jeu Mocli</div>
  </div>

  <div class="big-border-custom terms-item ch-color__tertiary " (click)="selectedIdx = 1">
    <div class="terms-item-title">{{conditions?.title}}</div>
  </div>
</div>

<!-- Termes et conditions de Mocli -->
<div *ngIf="selectedIdx === 0" class="terms-container">
  <div class="big-title">Termes et conditions de Mocli en vigueur au 07/02/2023</div>

  <div class="description">
    Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition de l’expérience mobile par Mocli et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».<br>
    Les présentes CGU sont accessibles pendant l’expérience en cliquant en bas de page sur le lien «CGU».<br>
    <br>
    En cliquant sur la case cochée invitant à démarrer l’expérience, ou sur toute autre action amenant l’expérience Mocli à démarrer, l’utilisateur accepte sans aucune réserve ni restriction les présentes CGU.<br>
    En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par Mocli SAS.<br>
    <br>
    MOCLI SAS se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
  </div>

  <div class="article">
    <div class="title">ARTICLE 1 : Les mentions légales</div>
    <div class="text">
      L'édition du site exp.mocli.fr, ci-après dénommé « Plateforme », est assurée par la Société MOCLI SAS au capital de 10000 euros, immatriculée au RCS de STRASBOURG sous le numéro Siret 9211 69728 00018, dont le siège social est situé au 17 RUE DE LA NUEE BLEUE, 67000 STRASBOURG.<br>
      Adresse e-mail : contact@mocli.fr<br>
      L'hébergeur de la Plateforme et des autres pages web du groupe Mocli SAS est la société AMAZON WEB SERVICES EMEA SARL, dont le siège social est situé au 38 AVENUE JOHN F. KENNEDY à LUXEMBOURG.
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 2 : Accès au site</div>
    <div class="text">
      Le site exp.mocli.fr, ci-après dénommé « Plateforme » est disponible sur smartphones Android et Apple sans téléchargement d’application et sans étape d’identification obligatoire. Il permet à l'Utilisateur un accès gratuit aux services suivants :<br>
      <br>
      Jouir d’expériences mises en place par des professionnels dans un but de divertissement ou d’information.<br>
      <br>
      Dans certaines expériences, avoir la possibilité (sans obligation) d’inscrire son score dans un classement ou d’en améliorer les performances. Cela par la transmission d’informations qui ne seront traitées qu’à des fins d'exécution du service de divertissement et en aucun cas pour des raisons commerciales.<br>
      En acceptant de partager ces informations aux services réservés, l’Utilisateur s’engage à fournir des informations sincères et exactes concernant notamment son adresse email.<br>
      <br>
      Dans certains cas, un tirage au sort ou une remise immédiate de promotion et/ou gains divers peuvent être mis en place par le client sur la Plateforme via une expérience Mocli. Dans ce cas, ce dernier sera indiqué et cela n’implique aucune obligation d’achat. De plus, Mocli SAS ne saurait être tenu responsable de la gestion de ces tirages, promotions ou gains potentiels.
      Cette responsabilité reste tenue par Le client de Mocli SAS utilisant la solution dashboard.mocli.fr pour la mise en place d’une expérience auprès de ses utilisateurs. Cette responsabilité étant indiquée expressément dans ses CGV et CGU signés par le Client lors de son premier achat et restent disponibles à tout moment dans l’«Espace CGV et CGU» à disposition sur son compte.<br>
      <br>
      Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.<br>
      <br>
      Tout Utilisateur régulièrement inscrit pourra également solliciter la suppression de son email du système informatique de Mocli SAS en écrivant à contact@mocli.fr et en y indiquant expressément et de manière limpide sa demande. Celle-ci sera effective dans un délai raisonnable.<br>
      <br>
      Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de Mocli SAS. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.<br>
      L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 3 : Collecte des données</div>
    <div class="text">
      Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.<br>
      En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit par mail à l'adresse mail contact@mocli.fr.
    </div>
  </div>
  
  <div class="article">
    <div class="title">ARTICLE 4 : Propriété intellectuelle</div>
    <div class="text">
      Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.<br>
      L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.<br>
      Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.<br>
      Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 5 : Responsabilité</div>
    <div class="text">
      Les sources des informations diffusées sur la Plateforme sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
      Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, la Plateforme ne peut être tenue responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, la Plateforme ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.
      La rédaction des expériences présentes sur la Plateforme sont la responsabilité du Client comme indiqué dans ses propres CGV et CGU.

      Dans les situations où ce dernier est nécessaire, l'utilisateur s'assure de garder son mot de passe /code PIN secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. MOCLI SAS tout comme son Client déclinent toute responsabilité. Mocli SAS et son Client ne peuvent être tenus pour responsables d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de la Plateforme.
      La responsabilité de la Plateforme ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 6 : Liens hypertextes</div>
    <div class="text">
      Des liens hypertextes peuvent être présents sur la Plateforme. L'Utilisateur est informé qu’en cliquant sur ces liens, il sortira de l’application. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 7 : Cookies</div>
    <div class="text">
      L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.<br>
      Les cookies sont de petits fichiers stockés temporairement sur la mémoire du smartphone de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation de la Plateforme. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br>
      L’information contenue dans les cookies est utilisée pour améliorer la Plateforme et l’expérience.<br>
      En utilisant la plateforme exp.mocli.fr, l’Utilisateur les accepte.<br>
      A défaut d’acceptation, l'Utilisateur se doit de renoncer à l'accès des services proposés par Mocli SAS.
    </div>
  </div>

  <div class="article">
    <div class="title">ARTICLE 8 : Droit applicable et juridiction compétente</div>
    <div class="text">
      La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.<br>
      Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
    </div>
  </div>
</div>


<!-- Règlement du client -->
<div *ngIf="selectedIdx === 1" class="terms-container">
  <div class="big-title">{{conditions?.title}}</div>
  <div class="description"></div>

  <ng-container *ngFor="let section of conditions.sections">
    <div class="article">
      <div class="title">{{section?.title}}</div>
      <div class="text" *ngIf="section?.content" [innerHTML]="section?.content"></div>
    </div>
  </ng-container>
</div>