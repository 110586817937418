<div class="todo-task-group">
  <div class="todo-top">
    <h4>Projet {{todoGroup.order.experienceInfo.name}}</h4>
    <!-- <h4 class="date">24/05</h4> -->
    <h6 class="size">{{counter}}</h6>
  </div>
  <ng-container *ngFor="let task of todoGroup.tasks">
    <div *ngIf="(task?.status === selectedFilter) || (selectedFilter === 'all')" class="task"
         (click)="onItemClick(task)">
      <p class="nowrap-ellipsis">{{task.name}}</p>
      <div class="task-right">
        <div [class]="'dot dot-' + getTaskStatusColor(task)"></div>
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </div>
    </div>
  </ng-container>
  <div class="no-task" *ngIf="todoGroup?.tasks && todoGroup?.tasks?.length <= 0">
    Aucune tâche à afficher.
  </div>
</div>