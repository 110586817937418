<div class="preview-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>
  
  <div class="image-element-container">
    <img class="image-element" [src]="detailSupport?.templateSupport?.templateBaseImageUrl" [alt]="detailSupport?.templateSupport?.templateBaseImageUrl">
  </div>

  <div class="description" *ngIf="detailSupport?.description">{{detailSupport?.description}}</div>
</div>