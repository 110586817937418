import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { AuthService } from 'src/app/services/AuthService';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        // private route: ActivatedRoute,
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe( tap(() => {},
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                    return;
                }

                if (!this.authService.isOnPopupLogin) {
                    // Get url to redirect after login
                    let url = null;
                    if (this.router?.routerState?.snapshot?.url) {
                        url = this.router.routerState.snapshot.url;
                    };

                    if (url === '/login') return;

                    if (!url) {
                        setTimeout(() => {
                            if (this.router?.routerState?.snapshot?.url) {
                                url = this.router.routerState.snapshot.url;
                            };

                            this.authService.openPopupLogin(url);
                        }, 200);
                    } else {
                        this.authService.openPopupLogin(url);
                    }
                }
            }
        }));
    }
}
