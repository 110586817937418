import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidemenuService } from 'src/app/services/SidemenuService';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {

  constructor(
    private router: Router,
    private sideMenuService: SidemenuService
  ) {}

  ngOnInit() {
    this.sideMenuService.onSideMenuStateChanged.next(false);
  }

  goTo(url: string) {
    this.sideMenuService.onSideMenuStateChanged.next(true);
    this.router.navigateByUrl(url);
  }
}
