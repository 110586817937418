<div class="add-stat-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>

  <div class="add-stat-form" [formGroup]="addStatFormGroup">
    <div class="input-container">
      <label for="name">Nom de la statistique *</label>
      <input [ngClass]="{'invalid-input': addStatFormGroup.controls.name.invalid && isSubmitted, 'input-disabled': statToEdit}" formControlName="name" type="text" id="name">
      <div class="invalid-input-container">
          <span *ngIf="addStatFormGroup.controls.name.invalid" class="invalid-input-text">
              <span *ngIf="addStatFormGroup.controls.name?.errors?.required && isSubmitted">Champs requis. </span>
              <span *ngIf="addStatFormGroup.controls.name?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
              <span *ngIf="addStatFormGroup.controls.name?.errors?.alreadyExists && isSubmitted">Ce nom existe déjà. </span>
          </span>
      </div>
    </div>
  
    <div class="input-container">
      <label for="description">Description</label>
      <input [ngClass]="{'invalid-input': addStatFormGroup.controls.description.invalid && isSubmitted}" formControlName="description" type="text" id="description">
      <div class="invalid-input-container">
          <span *ngIf="addStatFormGroup.controls.description.invalid" class="invalid-input-text">
              <span *ngIf="addStatFormGroup.controls.description?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
          </span>
      </div>
    </div>
  </div>

  <div class="add-stat-controls">
    <div class="mocli-button add-btn" (click)="onAddClick()">
      {{!statToEdit ? 'Ajouter' : 'Enregistrer'}}
    </div>
  </div>
</div>