<div class="modal-language-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img [src]="iconCloseImageUrl" alt="close modal">
  </div>
  <div class="languages-container" *ngIf="languages?.length > 0">
    <div *ngFor="let language of languages" class="language" [ngClass]="{'language-selected': language === selectedLanguage}" (click)="onLanguageClick(language)">
      <img [src]="'https://cdn.mocli.fr/icon/flags/' + language + '.svg'" alt="">
    </div>
  </div>

  <div class="confirm-container">
    <div class="img-container" [ngClass]="{'changes': hasChanges}" (click)="hasChanges ? confirmLanguage() : ''">
      <img src="https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg" alt="">
    </div>
  </div>
</div>