/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserService } from '@mocli/MocliCommonLib';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { SidemenuService } from 'src/app/services/SidemenuService';
import { UserWebService } from 'src/app/services/UserService';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {
  isSubmitted = false;
  isLoading: boolean = false;
  loginFormGroup: FormGroup;
  errorMessage: string = null;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    private userWebService: UserWebService,
    private userService: UserService,
    private sideMenuService: SidemenuService,
    private authService: AuthService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

  async submitForm() {
    this.isSubmitted = true;
    if (!this.loginFormGroup.valid) {
      return false;
    } else {
      this.isLoading = true;
      try {
        const resLogin = await firstValueFrom(this.userWebService.login(this.loginFormGroup.value.email, this.loginFormGroup.value.password));

        if (!resLogin || !resLogin.userId) {
          this.isLoading = false;
          this.errorMessage = 'Une erreur est survenue, merci de réessayer.';
          return;
        }

        const user = await firstValueFrom(this.userService.getUserById(resLogin.userId));

        if (user) this.authService.newUserLogged({...this.authService?.userLogged?.value, ...user});
        this.isLoading = false;
        this.closeModal({ valid: true });
      } catch (err) {
        this.isLoading = false;
        if (err.error === 'bad password')
          this.errorMessage = 'Mot de passe erroné, merci de réessayer.';
        else if (err.error === 'User not found')
          this.errorMessage = 'Utilisateur introuvable, merci de réessayer.';
        else if (err.error === 'user not active')
          this.errorMessage = 'Veuillez activer votre compte en cliquant sur le lien envoyé par mail.';
        else if (err.error) this.errorMessage = err.error;
      }
    }
  }
}
