import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { UserService } from '@mocli/MocliCommonLib';
import { environment } from 'src/environments/environment';
import { ExperienceInfo } from '@mocli/MocliCommonLib/lib/data/models/ExperienceInfo';
import { UserInfo } from '@mocli/MocliCommonLib/lib/data/models/UserInfo';
import { Address } from '@mocli/MocliCommonLib/lib/data/models/Address';
import { CompanyInfo } from '@mocli/MocliCommonLib/lib/data/models/CompanyInfo';

@Component({
  selector: 'app-modal-buy-credits',
  templateUrl: './modal-buy-credits.component.html',
  styleUrls: ['./modal-buy-credits.component.scss'],
})
export class ModalBuyCreditsComponent implements OnInit {
  @ViewChild('payForm') payFormElement;

  user: UserInfo;
  userId: number = 0;
  experience: ExperienceInfo;
  actionLink: string;
  creditsAmount: number = 50;
  price: number = 450;
  isSubmitted = false;
  saveInfo = true;

  paymentsFormGroup: FormGroup = null;

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private userSrv: UserService,
    private authSrv: AuthService
  ) { }

  ngOnInit() {
    //sync pour forcer l'ordre d'execution
    firstValueFrom(this.userSrv.getUserById(this.authSrv.userLogged.value.id)).then(e => {
      this.user = e;
      this.userId = e.id;
      this.initFormGroup();
    });

    this.actionLink = environment.mainUrl + '/api/Checkout/Create';

    if (this.navParams.data && this.navParams.data.creditsAmount)
      this.creditsAmount = this.navParams.data.creditsAmount;

    if (this.navParams.data && this.navParams.data.price)
      this.price = this.navParams.data.price;
  }

  public initFormGroup(): void {
    this.paymentsFormGroup = this.fb.group({
      firstName:   [this?.user?.name ?? ''                , [Validators.required, Validators.minLength(1)]],
      lastName:    [this?.user?.lastName ?? ''            , [Validators.required, Validators.minLength(1)]],
      companyName: [this?.user?.company?.name ?? ''       , [Validators.required, Validators.minLength(1)]],
      address:     ['', [Validators.required, Validators.minLength(1)]],
      city:        ['', [Validators.required, Validators.minLength(1)]],
      postalCode:  ['' , [Validators.required, Validators.minLength(1)]],
      country:     [''    , [Validators.required, Validators.minLength(1)]],
      tvaNumber:   [this?.user?.company?.tvaNumber ?? ''  , [Validators.required, Validators.minLength(1)]],
    });

    if (this?.user?.company?.addresses && this?.user?.company?.addresses.length > 0) {
      this.paymentsFormGroup.get('address').patchValue(this?.user?.company?.addresses[0].addressName);
      this.paymentsFormGroup.get('city').patchValue(this?.user?.company?.addresses[0].city);
      this.paymentsFormGroup.get('postalCode').patchValue(this?.user?.company?.addresses[0].postalCode);
      this.paymentsFormGroup.get('country').patchValue(this?.user?.company?.addresses[0].country);
    }
  }

  async saveFacturationInfo() {
    const address = this.paymentsFormGroup.get('address').value;
    //on extraie le numéro dans la rue en mappant tout les chiffres et espaces
    const streetNum = address.split(' ')[0] as number;
    const streetName = address.split(' ')[1];

    const obs = this.userSrv.updateFacturationInfo(this.userId,
      {
        id: this.user.company.id,
        tvaNumber: this.paymentsFormGroup.get('tvaNumber').value,
        addresses: [
          {
            addressName: streetName,
            streetNumber: streetNum,
            city: this.paymentsFormGroup.get('city').value,
            country: this.paymentsFormGroup.get('country').value,
            postalCode: this.paymentsFormGroup.get('postalCode').value
          } as Address
        ],
        name: '',
        logoUrl: '',
      } as CompanyInfo);
    const res = await firstValueFrom(obs);
    if(res == null) {

    }
  }

  async onSubmit() {
    this.isSubmitted = true;
    if (this.paymentsFormGroup.valid) {
      if (this.saveInfo === true) this.saveFacturationInfo();
      this.payFormElement.nativeElement.submit();
    }
  }
}
