/* eslint-disable max-len */
export class RandomHelper {

    // Sans la lettre I qui est confondue avec le chiffre 1 ou la lettre i minuscule
    // Sans la lettre O qui est confondue avec le chiffre 0
    static alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'A', 'W', 'X', 'Y', 'Z'];
    static alphaNum: string[] = [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm',
        'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M',
        'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];

    //retourne 'freq' nombre de lettres aléatoirement choisies
    public static getRandomLetterSeq(freq: number): string {
        let randomStr = '';
        for (let i = 0; i < freq; i++) {
            randomStr += this.alphabet[this.getRandomRangeNumber(0, this.alphabet.length - 1)];
        }

        return randomStr;
    }

    //retourne 'freq' nombre de chiffres aléatoirement choisis (représenté en string)
    public static getRandomNumberSeq(freq: number): string {
        let randomNbr = '';
        for (let i = 0; i < freq; i++) {
            randomNbr += this.getRandomRangeNumber(0, 9);
        }
        return randomNbr;
    }

    public static getRandomSeekAndScanCode(): string {
        let solutionCode = '';

        solutionCode += this.getRandomLetterSeq(2);
        solutionCode += this.getRandomNumberSeq(3);
        return solutionCode;
    }

    public static getRandomNumber(seed: number): number {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }

    public static getRandomRangeNumber(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static getRandomPassword(len: number): string {
        let password = '';
        for (let i = 0; i < len; i++) {
            const idx = Math.floor(Math.random() * this.alphaNum.length);
            password += this.alphaNum[idx];
        }
        return password;
    }
}
