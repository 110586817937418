import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-add-section',
  templateUrl: './modal-add-section.component.html',
  styleUrls: ['./modal-add-section.component.scss'],
})
export class ModalAddSectionComponent implements OnInit {
  addSectionFormGroup: FormGroup = null;
  isSubmitted: boolean = false;
  sectionToEdit: any = null;
  title: string = null;

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.sectionToEdit) {
      this.sectionToEdit = this.navParams.data.sectionToEdit;
    }
    if (this.navParams?.data?.title) {
      this.title = this.navParams.data.title;
    }
    this.addSectionFormGroup = this.fb.group({
      title: [this.sectionToEdit?.title ?? '', [Validators.required]],
    });
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

  onAddClick() {
    this.isSubmitted = true;

    if (this.addSectionFormGroup.invalid) return;

    this.closeModal(this.addSectionFormGroup.value);
  }

}
