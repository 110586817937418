import { Component, OnInit } from '@angular/core';
import { UserService } from '@mocli/MocliCommonLib';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications = [
    // {text: 'Notif1', date: 'il y a 10 minutes', read: false},
    // {text: 'Notif2', date: 'il y a 20 minutes', read: false},
    // {text: 'Notif3', date: 'il y a 30 minutes', read: false},
    // {text: 'Notif4', date: 'il y a 40 minutes', read: true},
    // {text: 'Notif4', date: 'il y a 40 minutes', read: true},
    // {text: 'Notif4', date: 'il y a 40 minutes', read: true},
  ];

  constructor(public userService: UserService,
    ) { }

  ngOnInit() {
  }
}
