/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HelpersService } from 'src/app/services/HelperService';
import { ModalController } from '@ionic/angular';
import { ModalScannerErrorComponent } from '../modal/modal-scanner-error/modal-scanner-error.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-scanner-custom',
  templateUrl: './scanner-custom.component.html',
  styleUrls: ['./scanner-custom.component.scss'],
})
export class ScannerCustomComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Output() onScan: EventEmitter<string> = new EventEmitter<string>();
  // @Input() showTorchBtn: boolean = true;
  @Input() showSwitchCamBtn: boolean = true;
  @Input() hasLog: boolean = true;
  @Output() onScan: EventEmitter<string> = new EventEmitter<string>();

  public mode: 'user' | 'environment' = 'environment';

  public canvas: HTMLCanvasElement;

  public video: HTMLVideoElement;

  public context: CanvasRenderingContext2D;

  public defaultWidth: number = 350;
  public reader: any;

  public loopInterval: any;

  public dateResult: string = null;
  public result: string = null;
  public ready: boolean = false;

  numberDevices= null;
  jsonDevices= null;


  errorMessage: string = null;
  errorMessageInner: string = null;
  trackActive: any = null;
  zoomSupported: boolean = false;
  processingScan: boolean = false;


  string1: string = null;
  string2: string = null;

  mediaDevices: MediaDeviceInfo[] = [];

  stream: MediaStream = null;

  environmentCamera: InputDeviceInfo = null;
  frontCamera: InputDeviceInfo = null;

  constructor(
    public helperSrv: HelpersService,
    private messageService: MessageService,
    private modalCtrler: ModalController
  ) { }

  ngOnInit() {
    this.reader = (window as any)['zbarWasm'];
  }

  async ngAfterViewInit() {
    this.initWindowListener();

    if (
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia &&
      navigator.mediaDevices.enumerateDevices
    ) {}

    this.stream = await navigator.mediaDevices.getUserMedia({ video: true });

    const mdConstraints = navigator.mediaDevices.getSupportedConstraints();
    const mdInfos = {
      mdConstraints,
      userAgent: navigator.userAgent,
      devices: []
    };

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const mediaDevices = devices.filter(device => device.kind === 'videoinput');
      // if (this.hasLog) await firstValueFrom(this.helperSrv.logger(0, 'test clem media infos' + JSON.stringify(mdInfos)));
      if (!mediaDevices || mediaDevices?.length <= 0) {
        mdInfos.devices = devices;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Aucun device trouvé' });
        return;
      }
      this.mediaDevices = mediaDevices;
      if (mediaDevices?.length < 2) this.showSwitchCamBtn = false;
      this.findCameras();
      await this.initCamera();
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err?.message ?? err?.error });
      this.openScannerErrorModal('Error 1');
    }

    // if (this.hasLog) await firstValueFrom(this.helperSrv.logger(0, 'Media infos ' + JSON.stringify(mdInfos)));
    this.startLoop();
  }

  initWindowListener() {
    let timeout = null;
    const delay = 250;

    const fn = this.getDimensions.bind(this);

    window.addEventListener('resize', () => {
      // clear the timeout
      clearTimeout(timeout);
      // start timing for event "completion"
      timeout = setTimeout(fn, delay);
    });
  }

  getDimensions() {
    const maxHeight = (window.innerHeight - 240 - 160);
    const newWidth = (window.innerWidth - 50) > maxHeight ? maxHeight : window.innerWidth - 50;

    this.canvas.width = newWidth;
    this.canvas.height = newWidth;
    this.video.setAttribute('width', this.canvas.width.toString());
    this.video.setAttribute('height', this.canvas.width.toString());
  }

  ngOnDestroy() {
    // stop stream video from mediaDevices
    const stream = this.video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    this.video.srcObject = null;

    if (this.loopInterval) clearInterval(this.loopInterval);
  }

  startLoop() {
    this.loopInterval = setInterval(async () => {
      this.scanImageForQrCode();
    }, 300);
  }

  stopLoop() {
    if (this.loopInterval) clearInterval(this.loopInterval);
  }

  findCameras() {
    // this.errorMessage = JSON.stringify(this.mediaDevices);

    // DEBUG CAMERA
    // let mediaDeviceInfos = '';

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.mediaDevices.length; i++) {
      const device = this.mediaDevices[i] as any;

      if (typeof device.getCapabilities === 'function') {
        const capabilities = device.getCapabilities();
        const position = capabilities.position || capabilities.facing || capabilities.facingMode || null;
        const width = capabilities.width.max || capabilities.width || null;
        const height = capabilities.height.max || capabilities.height || null;

        // DEBUG CAMERA
        // mediaDeviceInfos += `label: ${device?.label} <br>width: ${width} / height: ${height} <br> aspect-ratio: ${JSON.stringify(capabilities?.aspectRatio)} <br><br>`;

        if ((position === 'environment' || (position?.length > 0 && position?.includes('environment'))) && width && height && !device?.label?.includes('grand angle') && !device?.label?.includes('Wide Camera')) {
          this.environmentCamera = device;
        }
        if ((position === 'user' || (position?.length > 0 && position?.includes('user'))) && width && height && !this.frontCamera) {
          this.frontCamera = device;
        }
      } else if (
        (device.label.toLowerCase().includes('back') ||
        device.label.toLowerCase().includes('landscape') ||
        device.label.toLowerCase().includes('arrière') ||
        device.label.toLowerCase().includes('environment'))
        && !device?.label?.includes('grand angle') && !device?.label?.includes('Wide Camera')
      ) {
        this.environmentCamera = device;
      } else if (
        device.label.toLowerCase().includes('front') ||
        device.label.toLowerCase().includes('avant') ||
        device.label.toLowerCase().includes('user') ||
        device.label.toLowerCase().includes('face')
      ) {
        this.frontCamera = device;
      }
    }

    // DEBUG CAMERA
    // mediaDeviceInfos = `camera used: ${this.environmentCamera.label}<br><br>` + mediaDeviceInfos;
    // this.errorMessageInner = mediaDeviceInfos;

    if (!this.environmentCamera && !this.frontCamera) {
      this.openScannerErrorModal('Error 3');
      return;
    }
  }

  async initCamera() {
    let camera: any = null;
    if (this.mode === 'environment') camera = this.environmentCamera ?? this.frontCamera;
    if (this.mode === 'user') camera = this.frontCamera ?? this.environmentCamera;
    const constraints: any =  {
      audio: false,
      video: {
        deviceId: { exact: camera?.deviceId },
      }
    };
    if (this.stream) {
      this.stream.getTracks().forEach(t => {
        t.stop();
        this.stream.removeTrack(t);
     });
    }

    navigator.mediaDevices.getUserMedia(constraints).then(async (stream) => {
      const sTracks = stream.getTracks();
      for (const sTrack of sTracks) {
        const sTrackInfo = {
          constraints: sTrack.getConstraints(),
          capabilities: sTrack.getCapabilities(),
          settings: sTrack.getSettings(),
        };
        // if (this.hasLog) await firstValueFrom(this.helperSrv.logger(0, 'stream track ' + i++ + ' ' + JSON.stringify(sTrackInfo)));
      }
      this.canvas = document.createElement('canvas');
      this.stream = stream;
      console.log('this.stream', this.stream);

      this.video = document.querySelector('video');
      this.video.setAttribute('autoplay', '');
      this.video.setAttribute('muted', '');
      this.video.setAttribute('playsinline', '');
      this.video.removeAttribute('controls');
      this.video.onloadedmetadata = () => { this.video.play(); };
      this.video.oncanplay = () => { this.onVideoReady(); };
      this.video.srcObject = stream;
      this.ready = true;

      const [track] = stream.getVideoTracks();
      const settings = track.getSettings();

      this.trackActive = track;
      // Check whether zoom is supported or not.
      if (!('zoom' in settings)) {
        this.zoomSupported = false;
      } else {
        this.zoomSupported = true;
      }
    })
    .catch(err => {
      this.errorMessage = err;
      console.error('error on mediaDevices.getUserMedia(): ', err);
      this.openScannerErrorModal('Error 2');
    });
  }

  onVideoReady() {
    this.context = this.canvas.getContext('2d', { willReadFrequently: true });
    this.getDimensions();
  }


  async scanImageForQrCode() {
    if (!this.context || this.processingScan) return;
    this.processingScan = true;
    this.context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

    const imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
    const symbols = await this.reader.scanImageData(imageData);
    if (symbols?.length > 0) {
      const url = symbols[0].decode();
      if (url) {
        this.result = url;
        this.onScan.emit(url);
        navigator.vibrate(100);
        this.dateResult = new Date(Date.now()).toLocaleTimeString();
      }
    }
    this.processingScan = false;
  }

  async switchCamera() {
    this.mode = this.mode === 'user' ? 'environment' : 'user';
    await this.initCamera();
  }

  zoomIn() {
    this.trackActive.applyConstraints({
      advanced: [{ zoom: this.trackActive.getSettings().zoom + 0.1 }]
    });
  }

  zoomOut() {
    this.trackActive.applyConstraints({
      advanced: [{ zoom: this.trackActive.getSettings().zoom - 0.1 }]
    });
  }

  async openScannerErrorModal(errorMessage: string) {
    const modal = await this.modalCtrler.create({
      component: ModalScannerErrorComponent,
      backdropDismiss: true,
      componentProps: {
        errorMessage
      },
      cssClass: 'modal-scanner-error'
    });

    return await modal.present();
  }
}
