<div class="header-container" *ngIf="headerConfig">
  <ion-icon *ngIf="headerConfig && headerConfig.showBackBtn" 
            (click)="onBackBtnClick()"
            class="back-btn"
            name="chevron-back-outline"></ion-icon>
  <div class="main-container">
    <div class="first-line">
      <h2 [ngClass]="{'title-page': headerConfig && headerConfig.isTitlePage, 'title-left': !headerConfig || !headerConfig.showBackBtn}">{{headerConfig && headerConfig.title ? headerConfig.title : ''}}</h2>
      <div *ngIf="headerConfig?.circleColor" class="circleColor" [style]="'background-color: ' + headerConfig?.circleColor"></div>
      <div *ngIf="headerConfig?.description" class="description">{{headerConfig?.description}}</div>
    </div>
    <div class="second-line" *ngIf="headerConfig?.secondTitle">
      {{headerConfig?.secondTitle}}
    </div>
  </div>

  <div class="page-description" *ngIf="headerConfig?.pageDescription" [pTooltip]="hasToolTipDescription ? headerConfig?.pageDescription : null" tooltipPosition="bottom">
    <div class="description-text">
      {{headerConfig?.pageDescription}}
    </div>
  </div>

  <div class="steps-container" *ngIf="headerConfig && headerConfig?.stepsConfig">
    <div class="steps-content">
      <app-steps [config]="headerConfig?.stepsConfig ?? null"></app-steps>
    </div>
  </div>

  <!-- Badge -->
  <div class="badge-container" id="badgeContainer" #badgeContainer>
    <!-- Admin -->
    <div class="badge badge-admin" *ngIf="headerConfig?.showAdmin">
      <a href="/admin">
        <img src="../../../../assets/icon/admin.svg">
      </a>
    </div>

    <!-- Wallet -->
    <div class="badge" *ngIf="headerConfig?.showWallet && headerConfig.showAdmin" (click)="onClickWallet()">
      <img src="../../../../assets/icon/mocli-credit-wallet.svg">
      <p-badge [value]="userBalance.toString()" size="large" severity="warning"></p-badge>
    </div>
    
    <!-- Notifications -->
    <!-- <div class="badge badge-notifications" *ngIf="headerConfig?.showWallet" (click)="onClickNotifications()">
      <img src="../../../../assets/icon/bell.svg" style="margin-top: 10px; margin-right: 20px;">
      <p-badge *ngIf="getNewNotificationsCount() > 0" id="badge-notifications" [value]="getNewNotificationsCount().toString()" size="large" severity="warning"></p-badge>
    </div> -->

  </div>

  <div class="notifications-backdrop" (click)="onClickNotifications(); showSettingsIdx = -1" *ngIf="showNotifications"></div>
  <div class="notifications-container" [ngClass]="{'show': showNotifications, 'hide': !showNotifications}">
    <div style="height: 70px;"></div>
    <div class="no-notifications" *ngIf="!notificationsList || notificationsList.length <= 0">Vous n'avez pas de notification</div>
    <div class="block" *ngIf="notificationsList && notificationsList.length > 0">
      <ng-container *ngFor="let notification of notificationsList; let i = index">
        <div class="notification-block">
          <div class="image-container">
            <img [src]="'../../../../assets/icon/notifications' + (notification?.isRead ? '-read' : '-unread') + '.svg'" alt="">
            <div *ngIf="notification?.isNew" class="dot"></div>
          </div>
  
          <div class="content" (click)="onClickNotificationBlock(i)">
              <div class="content-text" [ngClass]="{'content-text-read': notification.isRead}">{{notification.template}}</div>
            <div class="content-date">{{formatDate(notification.dateCreated)}}</div>
          </div>
  
          <div class="settings">
            <div class="text" (click)="onClickSettings(i)">...</div>
            <div class="settings-block" [ngClass]="{'show': showSettingsIdx === i, 'hide': showSettingsIdx !== i}">
              <div class="read" (click)="notification.isRead = true; notification.isNew = false">Marquer comme lu</div>
              <div class="delete" (click)="onDeleteNotification(i)">Supprimer</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>