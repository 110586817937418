import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-tutorial-catalog',
  templateUrl: './modal-tutorial-catalog.component.html',
  styleUrls: ['./modal-tutorial-catalog.component.scss'],
})
export class ModalTutorialCatalogComponent implements OnInit {
  selectedTutorial: any = null;
  selectedIdx: number = 0;

  tutorialsSlide: any[];

  constructor(
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.tutorialsSlide = [
      {
        title: 'Bienvenue sur Mocli !' + '\n' + 'Voici quelques informations pour bien débuter sur notre solution',
        imageUrl: 'catalogue.png',
        description: 'Trouvez votre première expérience parmi celles' + '\n' + 'proposées dans notre catalogue',
        btnText: 'Suivant'
      },
      {
        title: 'Bienvenue sur Mocli !' + '\n' + 'Voici quelques informations pour bien débuter sur notre solution',
        imageUrl: 'formule.png',
        description: 'Choisissez votre formule d’expérience, achetez les' + '\n' + 'crédits adéquats et validez votre 1ère commande.',
        btnText: 'Suivant'
      },
      {
        title: 'Ce premier achat vous donne accès à l’ensemble de la solution Mocli !',
        imageUrl: 'details.png',
        description: 'Configurez les détails de votre expérience, puis personnalisez son' + '\n' + 'contenu pour la rendre unique.',
        btnText: 'Suivant'
      },
      {
        title: 'Tout est en place pour faire vivre une expérience satisfaisante à vos clients !',
        imageUrl: 'resultats.png',
        description: 'Il ne vous reste plus qu’à suivre les résultats si vous le souhaitez,' + '\n'
                     + 'et laisser vos clients apprécier l’expérience.',
        btnText: 'J’ai tout compris !'
      },
    ];
    this.selectedTutorial = this.tutorialsSlide[0];
  }

  public nextSlide() {
    if (this.tutorialsSlide && this.tutorialsSlide.length > this.selectedIdx + 1) {
      this.selectedIdx += 1;
      this.selectedTutorial = this.tutorialsSlide[this.selectedIdx];
    } else {
      this.closeModal('hasDoneTutorialCatalog');
    }
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }
}
