import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { StatPageConfiguration } from 'src/app/data/models/StatPageConfiguration';

@Component({
  selector: 'app-modal-add-stat',
  templateUrl: './modal-add-stat.component.html',
  styleUrls: ['./modal-add-stat.component.scss'],
})
export class ModalAddStatComponent implements OnInit {
  addStatFormGroup: FormGroup;
  isSubmitted: boolean = false;

  statToEdit: StatPageConfiguration = null;
  baseStats: StatPageConfiguration[] = null;

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.stat) {
      this.statToEdit = this.navParams.data.stat;
    }
    if (this.navParams?.data?.stats) {
      this.baseStats = this.navParams.data.stats;
    }
    this.addStatFormGroup = this.fb.group({
      name: [
        {value: this.statToEdit?.statBlockName ?? '', disabled: this.statToEdit ?? false},
        [Validators.required, Validators.maxLength(48)]],
      description: [this.statToEdit?.description ?? '', [Validators.maxLength(128)]],
    });
  }

  onAddClick() {
    this.isSubmitted = true;

    if (this.addStatFormGroup.invalid) return;

    if (this.baseStats?.find(stat => stat.statBlockName === this.addStatFormGroup.value.name)) {
      this.addStatFormGroup?.controls?.name?.setErrors({alreadyExists: true});
      return;
    }

    this.closeModal(this.addStatFormGroup.value);
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }
}
