<div class="mocli-box-content configure-question">
  <form [formGroup]="guessClosestForm">
    <!-- Titre -->
    <div class="question-info">
      <div class="big-title title">Question {{data.index + 1}}</div>
      <div class="question-content">
        <textarea formControlName="question"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': guessClosestForm.controls.question.invalid, 'disabled-input': inputDisabled}">
                </textarea>
        <div class="question-content-counter">
          <span *ngIf="guessClosestForm.controls.question.invalid" class="invalid-input-text">
            <span *ngIf="guessClosestForm.controls.question?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="guessClosestForm.controls.question?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{guessClosestForm.controls.question.value.length}} / 300
          </span>
        </div>
      </div>
    </div>

    <!-- Unit -->
    <div class="explain">
      <div class="visuel-title" style="margin-bottom: 7px;">
        <div class="title">Unité de la réponse</div>
        <div class="optional">(année, prix, nombre, kilomètres...)</div>
      </div>
      <div class="small-content inline-button">
        <input type="text" 
              pInputText
              placeholder="Écrire ici..."
              formControlName="unit"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': guessClosestForm.controls.unit.invalid, 'disabled-input': inputDisabled}">
        <div class="question-content-counter">
          <span *ngIf="guessClosestForm.controls.unit.invalid" class="invalid-input-text">
            <span *ngIf="guessClosestForm.controls.unit?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="guessClosestForm.controls.unit?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{guessClosestForm.controls.unit.value.length}} / 20
          </span>
        </div>
      </div>
    </div>

    <!-- Réponse -->
    <div class="explain">
      <div class="explain-title">
        <div class="title">Réponse</div>
      </div>
      <div class="small-content inline-button">
        <input type="number" 
              pInputText
              placeholder="Écrire ici..."
              formControlName="answer"
              (change)="autoSave()"
              [ngClass]="{'invalid-input': guessClosestForm.controls.answer.invalid, 'disabled-input': inputDisabled}">
        <div class="question-content-counter">
          <span *ngIf="guessClosestForm.controls.answer.invalid" class="invalid-input-text">
            <span *ngIf="guessClosestForm.controls.answer?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="guessClosestForm.controls.answer?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{guessClosestForm.controls.answer.value.toString().length}} / 20
          </span>
        </div>
      </div>
    </div>

    <!-- Explication -->
    <div class="explain">
      <div class="visuel-title"style="margin-bottom: 7px;">
        <div class="title">Explication</div>
        <div class="optional">(optionelle)</div>
      </div>
      <div class="question-content inline-button">
        <textarea formControlName="explain"
                  placeholder="Écrire ici..."
                  (change)="autoSave()"
                  [ngClass]="{'invalid-input': guessClosestForm.controls.explain.invalid, 'disabled-input': inputDisabled}">
        </textarea>
        <div class="question-content-counter">
          <span *ngIf="guessClosestForm.controls.explain.invalid" class="invalid-input-text">
            <span *ngIf="guessClosestForm.controls.explain?.errors?.maxlength">Taille maximale atteinte. </span>
            <span *ngIf="guessClosestForm.controls.explain?.errors?.required">Champ requis. </span>
          </span>
          <span>
            Caractères : {{guessClosestForm.controls.explain.value.length}} / 300
          </span>
        </div>
      </div>
    </div>
  </form>

  <!-- Visuel -->
  <div class="visuel">
    <div class="visuel-title">
      <div class="title">Visuel</div>
      <div class="optional">(optionnel)</div>
    </div>
    <div *ngIf="!visualImageUrl">
      <input #inputFile 
            [id]="'inputFile-' + data?.index"
            type="file"
            name="file"
            class="inputfile"
            accept="image/*"
            (change)="onFileUpload($event)">
      <label [for]="'inputFile-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrl" class="visual-name-container">
      <div class="delete">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrl" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
  </div>
</div>