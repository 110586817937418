import { Injectable } from '@angular/core';
import { HeaderBarService } from 'src/app/services/HeaderBarService';

@Injectable({
    providedIn: 'root'
})
export class StepsService {

    currentStepIdx: number = 0;
    totalSteps: number = 0;

    constructor(
        private headerBarService: HeaderBarService
    ) {
    }

    nextStep() {
        if (this.currentStepIdx < this.totalSteps && this.checkStep(this.currentStepIdx + 1)) {
            this.currentStepIdx++;
        }
    }

    previousStep() {
        if (this.currentStepIdx > 0 && this.checkStep(this.currentStepIdx - 1)) {
            this.currentStepIdx--;
        }
    }

    checkStep(stepIdx: number) {
        const step = this.headerBarService.onHeaderBarChanged.value?.stepsConfig?.steps[stepIdx];

        if (step?.checkClickable !== null && step?.checkClickable !== undefined) {
            const res = step.checkClickable(step?.language);
            return res;
        } else if (step) {
            return true;
        };
        return false;
    }

    resetSteps() {
        this.currentStepIdx = 0;
    }

    goToStep(stepIdx: number, force: boolean = false) {
        if (force) {
            this.currentStepIdx = stepIdx;
            return true;
        }

        if (stepIdx >= 0 && stepIdx <= this.totalSteps) {
            const step = this.headerBarService.onHeaderBarChanged.value?.stepsConfig?.steps[stepIdx];

            if (!step) return false;

            const stepClickable = this.checkStep(stepIdx);

            if (stepClickable) {
                this.currentStepIdx = stepIdx;
            }

            return true;
        }
        return false;
    }
}
