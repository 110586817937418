import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-edit-object-name',
  templateUrl: './modal-edit-object-name.component.html',
  styleUrls: ['./modal-edit-object-name.component.scss']
})
export class ModalEditObjectNameComponent implements OnInit {
  objectNameFormGroup: FormGroup = null;
  isFormSubmitted: boolean = false;

  maxLengthInput: number = 20;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    let objectName = null;
    let objectNamePlural = null;
    let demonstrativeAdjective = null;

    if (this.navParams?.data?.objectName) {
      objectName = this.navParams.data.objectName;
    }
    if (this.navParams?.data?.objectNamePlural) {
      objectNamePlural = this.navParams.data.objectNamePlural;
    }
    if (this.navParams?.data?.demonstrativeAdjective) {
      demonstrativeAdjective = this.navParams.data.demonstrativeAdjective;
    }

    this.objectNameFormGroup = this.fb.group({
      objectName: [objectName, [Validators.required, Validators.maxLength(this.maxLengthInput)]],
      objectNamePlural: [objectNamePlural, [Validators.required, Validators.maxLength(this.maxLengthInput)]],
      demonstrativeAdjective: [demonstrativeAdjective, [Validators.required, Validators.maxLength(this.maxLengthInput)]]
    });
  }

  async closeModal(data: any = null, role: string = 'close') {
    await this.modalController.dismiss(data, role);
  }

  submitForm() {
    this.isFormSubmitted = true;

    if (this.objectNameFormGroup.valid) {
      this.closeModal(this.objectNameFormGroup.value, 'confirm');
    }
  }
}
