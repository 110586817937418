import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TodoGroup } from '@mocli/MocliCommonLib/lib/data/models/TodoGroup';
import { TodoTask } from '@mocli/MocliCommonLib/lib/data/models/TodoTask';
import { TodoTaskStatusEnum } from 'src/app/data/enums/TodoTaskStatus.enum';
import { TodoTaskStatusHelper } from 'src/app/helpers/TodoTaskStatusHelper';

@Component({
  selector: 'app-todo-task-group',
  templateUrl: './todo-task-group.component.html',
  styleUrls: ['./todo-task-group.component.scss'],
})
export class TodoTaskGroupComponent implements OnInit {

  @Input() todoGroup: TodoGroup;
  @Input() selectedFilter: TodoTaskStatusEnum | 'all';

  public counter: string = '0/0';
  public todoTaskStatusEnum: typeof TodoTaskStatusEnum = TodoTaskStatusEnum;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.computeCounter();
  }

  public computeCounter() {
    if (!this.todoGroup || !this.todoGroup.tasks || this.todoGroup.tasks.length <= 0)
      return;

    const total = this.todoGroup.tasks.length;
    const count = this.todoGroup.tasks.filter(t => t
                                                && t.status === TodoTaskStatusEnum.Finished).length;
    this.counter = count + '/' + total;
  }

  public getTaskStatusColor(task: TodoTask) {
    return TodoTaskStatusHelper.statusColor[task && task.status ? task.status : 'default'];
  }

  public onSettingsClick(task: TodoTask) {
  }

  onItemClick(task: TodoTask) {
    if(task.status !== 'Finished') {
      if (task?.redirectUrl) {
        const uri = task.redirectUrl.substring(26);
        this.router.navigateByUrl(uri);
      }
    }
  }
}
