import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-crop-image',
  templateUrl: './modal-crop-image.component.html',
  styleUrls: ['./modal-crop-image.component.scss'],
})
export class ModalCropImageComponent implements OnInit {
  imageChangedEvent: any = null;
  croppedImage: any = null;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    // if (this.navParams?.data?.file) this.imageChangedEvent = this.navParams.data.file;
    if (this.navParams?.data?.file) this.imageChangedEvent = this.navParams.data.event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  async closeModal(data: string) {
    const onClosedRole: string = data;

    const onClosedData: any = {
      fileBase64: this.croppedImage,
    };

    await this.modalController.dismiss(onClosedData, onClosedRole);
  }

}
