import { Component, OnInit } from '@angular/core';
import { SidemenuService } from 'src/app/services/SidemenuService';

@Component({
  selector: 'app-test-user-page',
  templateUrl: './test-user-page.component.html',
  styleUrls: ['./test-user-page.component.scss'],
})
export class TestUserPageComponent implements OnInit {
  height = null;
  constructor(
    private sideMenuService: SidemenuService,
  ) { }

  ngOnInit() {
    // this.sideMenuService.sideMenuShown = false;
    this.sideMenuService.onSideMenuStateChanged.next(false);

    // const fullHeightDiv = document.getElementById('page-scan');
    // this.height = window.innerHeight;
    console.log('window.innerHeight', window.innerHeight);
    // fullHeightDiv.style.height = `${window.innerHeight}px`;
  }

}
