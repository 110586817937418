<div class="simple-date-picker-container">

    <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
        <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
    </div>
    <div class="simple-container" id="calendar-mocli" *ngIf="modalType === 'simple' || modalType === 'simple-hour'">
        <div class="title">{{title}}</div>
        <div>
            <p-calendar [(ngModel)]="selectedDate" [minDate]="minDate" styleClass="dateSelectorClass" (ngModelChange)="selectedDate ? errorMessage = null : ''" [showTime]="modalType === 'simple-hour'" [maxDate]="maxDate" [inline]="true"></p-calendar>
        </div>
        <!-- <div class="time-picker-container">
            <p-calendar *ngIf="modalType === 'simple-hour'" [timeOnly]="true" [(ngModel)]="selectedTime"></p-calendar>
        </div> -->
        
    </div>

    <div *ngIf="modalType === 'period'" id="calendar-mocli">
        <!-- <div class="title">{{type === 'start' ? 'Date de début' : 'Date de fin'}}</div>
        <p-calendar [(ngModel)]="selectedDate" [minDate]="minDate" [maxDate]="maxDate" [inline]="true"></p-calendar> -->

        <!-- <div class="calendar-container" [formGroup]="calendarFormGroup">
            <div class="calendar" style="margin-left: 15px;">
                <h1>Date de départ</h1>
                <p-calendar [maxDate]="dateEnd === null ? maxDate : dateEnd" [minDate]="minDate" styleClass="dateSelectorClass" formControlName="dateStart" [inline]="true"></p-calendar>
                <div class="date-container">
                    <h3>{{dateStartFmt ? dateStartFmt : 'Non sélectionée'}}</h3>
                </div>
            </div>
            <div class="calendar" style="margin-right: 15px;">
                <h1>Date de fin</h1>
                <p-calendar [minDate]="dateStart === null ? minDate : dateStart" [maxDate]="maxDate" styleClass="dateSelectorClass" formControlName="dateEnd" [inline]="true"></p-calendar>
                <div class="date-container">
                    <h3>{{dateEndFmt ? dateEndFmt : 'Non sélectionée'}}</h3>
                </div>
            </div>
        </div> -->

        <div class="title">{{title ?? 'Période d\'activation'}}</div>
        <div [formGroup]="calendarFormGroup" style="min-height: 340px;">
            <p-calendar (onMonthChange)="onMonthChange($event)" (onYearChange)="onYearChange($event)" id="calendarRangeCustom" [minDate]="minDate" [maxDate]="maxDate" formControlName="rangeDate" styleClass="dateSelectorClass" [inline]="true" selectionMode="range"></p-calendar>
        </div>

        <div class="days-selected" *ngIf="showAmountSelected">
            <div class="selected-days">{{daysAmountSelected}} jour{{daysAmountSelected > 1 ? 's' : ''}} sélectionné{{daysAmountSelected > 1 ? 's' : ''}}</div>
        </div>
        <!-- <div class="selected-days" *ngIf="daysAmountSelected > 0">{{daysAmountSelected}} jour{{daysAmountSelected > 1 ? 's' : ''}} sélectionné{{daysAmountSelected > 1 ? 's' : ''}}</div>
        <div class="selected-days" *ngIf="daysAmountSelected <= 0"></div> -->

        <div [formGroup]="calendarFormGroup" class="hours-selector-container">
            <div class="hour-row">
                <div class="hour-title">Heure de début</div>
                <p-calendar [timeOnly]="true" [inline]="true" formControlName="hourStart"></p-calendar>
            </div>
            <div class="hour-row">
                <div class="hour-title">Heure de fin</div>
                <p-calendar [timeOnly]="true" [inline]="true" formControlName="hourEnd"></p-calendar>
            </div>
        </div>
    </div>

    <div class="error-container">
        <div  *ngIf="errorMessage" class="error" [innerHTML]="errorMessage"></div>
    </div>

    <div class="confirm-btn">
        <div class="mocli-button" (click)="onConfirmClick()">Confirmer</div>
    </div>
</div>