import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-create-custom-theme',
  templateUrl: './modal-create-custom-theme.component.html',
  styleUrls: ['./modal-create-custom-theme.component.scss'],
})
export class ModalCreateCustomThemeComponent implements OnInit {
  addThemeFormGroup: FormGroup;
  isSubmitted: boolean = false;


  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.addThemeFormGroup = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(64)]],
    });
  }

  onCreateClick() {
    this.isSubmitted = true;

    if (this.addThemeFormGroup.invalid) return;

    this.closeModal({data: 'confirm', value: this.addThemeFormGroup.get('name')?.value});
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

}
