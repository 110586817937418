<div class="modal-data" [formGroup]="modalDataFormGroup" *ngIf="modalDataFormGroup">
  <div class="title">Réponse ouverte</div>

  <div class="languages-container" *ngIf="languages?.length > 1">
    <div class="language" *ngFor="let language of languages; let idx = index">

      <div class="top">
        <div class="flag-wrapper" [ngClass]="{'flag-wrapper-selected': currentLanguageIdx === idx}">
          <img [src]="'https://cdn.mocli.fr/icon/flags/' + language + '.svg'" alt="">
        </div>
  
        <div class="bar" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}"></div>
      </div>
      
      <div class="badge" [ngStyle]="{'background-color': (stepStatusColors[getStatus(idx)] + '4d'), 'color': stepStatusColors[getStatus(idx)]}">{{stepStatusLabels[getStatus(idx)]}}</div>
    </div>
  </div>

  <div class="input-description" [ngClass]="{'input-description-with-languages': languages?.length > 1}">Veuillez compléter la case avec le texte à afficher</div>

  <div class="textarea">
    <textarea placeholder="Écrire ici..." formControlName="question" [ngClass]="{'invalid-input': modalDataFormGroup.get('question')?.invalid && isSubmitted}" rows="5"></textarea>
    <div class="textarea-counter">
      <span *ngIf="modalDataFormGroup.controls.question.invalid" class="invalid-input-text">
        <span *ngIf="modalDataFormGroup.controls.question?.errors?.required && isSubmitted">Champs requis. </span>
        <span *ngIf="modalDataFormGroup.controls.question?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
      </span>
      <div class="char-count">Caractères : {{modalDataFormGroup?.controls?.question?.value?.length}} / 200</div>
    </div>
  </div>

  <div class="max-chars-container" style="margin-top: 16px;">
    <div class="input-description">Nombre de caractères disponibles pour l’utilisateur</div>
    <div [ngClass]="{'input-container-disabled': currentLanguageIdx > 0}"  tooltipPosition="left" [tooltipDisabled]="currentLanguageIdx === 0" [pTooltip]="'Uniquement modifiable sur la langue d\'origine (Français)'">
      <input type="number" [ngClass]="{'input-disabled': currentLanguageIdx > 0}" [min]="1" formControlName="maxChoices">
    </div>
  </div>

  <div class="required-container">
    <div class="input-description" style="margin-top: 16px;">La case doit-elle être obligatoirement cochée par l’utilisateur ?</div>
    <div style="width: fit-content" tooltipPosition="left" [tooltipDisabled]="currentLanguageIdx === 0" [pTooltip]="'Uniquement modifiable sur la langue d\'origine (Français)'" >
      <div class="choice">
        <div class="label">Oui</div>
        <app-radio-input [disable]="currentLanguageIdx > 0" (click)="currentLanguageIdx > 0 ? '' : isRequired = true" [checked]="isRequired === true"></app-radio-input>
      </div>
      <div class="choice" style="margin-top: 12px;">
        <div class="label">Non</div>
        <app-radio-input [disable]="currentLanguageIdx > 0" (click)="currentLanguageIdx > 0 ? '' : isRequired = false" [checked]="isRequired === false"></app-radio-input>
      </div>
    </div>
  </div>

  <div class="controls-data">
    <div class="btn btn-cancel" (click)="prevStep()">{{currentLanguageIdx === 0 ? 'Annuler' : 'Langue précédente'}}</div>
    <div class="btn btn-confirm" (click)="nextStep()">{{currentLanguageIdx + 1 === languages?.length ? 'Confirmer' : 'Langue suivante'}}</div>
  </div>
</div>