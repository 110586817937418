import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/AuthService';
import { OrderService } from '@mocli/MocliCommonLib';
import { UserService } from '@mocli/MocliCommonLib';
import { ModalExtendExperienceComponent } from '../modal-extend-experience/modal-extend-experience.component';
import { DateTime } from 'luxon';
import { ExperienceOrder } from '@mocli/MocliCommonLib/lib/data/models/ExperienceOrder';

@Component({
  selector: 'app-modal-date-picker',
  templateUrl: './modal-date-picker.component.html',
  styleUrls: ['./modal-date-picker.component.scss'],
})
export class ModalDatePickerComponent implements OnInit {
  public calendarFormGroup: FormGroup;
  public dateStart: Date = null;
  public dateEnd: Date = null;
  public maxDays: number = null;
  public daysToPay: number = null;

  public showExtend: boolean = false;
  public showExtendSuccess: boolean = false;

  public cost: number = null;
  public actualBalance: number = null;

  public experienceId: number = null;
  public experienceOrder: ExperienceOrder = null;

  public daysAmountSelected: number = 0;

  constructor(
    public modalController: ModalController,
    private orderSrv: OrderService,
    private userSrv: UserService,
    private authSrv: AuthService,
    private fb: FormBuilder,
    private navParams: NavParams,
    public router: Router
  ) { }

  public ngOnInit() {
    this.initFormGroup();
    if (this.navParams.data && this.navParams.data.maxDays) this.maxDays = this.navParams.data.maxDays;
    if (this.navParams.data && this.navParams.data.experienceId) this.experienceId = this.navParams.data.experienceId;
    if (this.navParams.data && this.navParams.data.experienceOrder) this.experienceOrder = this.navParams.data.experienceOrder;
  }

  public initFormGroup() {
    this.calendarFormGroup = this.fb.group({
      dateStart: null,
      dateEnd: null
    });

    this.calendarFormGroup.valueChanges.subscribe((data: any) => {
      if (data?.dateStart && data?.dateEnd) {
        this.daysAmountSelected = this.getDaysBetweenDates(data.dateStart, data.dateEnd);
      }
      if (data && data.dateEnd) {
        this.dateEnd = new Date(data.dateEnd);
      }
      if (data && data.dateStart) {
        this.dateStart = new Date(data.dateStart);
        if (this.dateEnd && this.dateStart > this.dateEnd) {
          this.dateEnd = null;
          this.calendarFormGroup.get('dateEnd').patchValue(null);
        }
      }
    });
  }

  async closeModal(data: string) {
    const onClosedRole: string = data;

    const onClosedDate: any = {
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      maxDays: this.maxDays,
    };

    await this.modalController.dismiss(onClosedDate, onClosedRole);
  }

  async confirmRange() {
    // get days between date start and date end
    const days = this.getDaysBetweenDates(this.dateStart, this.dateEnd);

    if (days > this.maxDays) {
      this.daysToPay = days - this.maxDays;

      const price30Days = await firstValueFrom(this.orderSrv.get30DaysPrice());

      this.cost = Math.floor(this.daysToPay * (price30Days / 30));
      this.actualBalance = await firstValueFrom(this.userSrv.getUserBalance(this.authSrv.userLogged.value.id));
      this.showExtend = true;
    } else {
      this.closeModal('confirm');
    }
    // this.closeModal('confirm');
  }

  public getDaysBetweenDates(startDate: Date, endDate: Date) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.ceil((endDate.getTime() - startDate.getTime()) / millisecondsPerDay) + 1;
  }

  public onBuyCreditsClick() {
    this.closeModal('close');
    this.router.navigate(['/book/buy'], { queryParams: { creditsAmount: this.cost - this.actualBalance } });
  }

  public cancelDates() {
    this.showExtend = false;
  }

  public async confirmExtend() {
    if (!this.experienceId) return;

    try {
      const endFinal = new Date(this.dateStart);
      endFinal.setDate(this.dateStart.getDate() + this.maxDays);

      this.experienceOrder.experiencePlanning.startDate = DateTime.fromJSDate(this.dateStart);
      this.experienceOrder.experiencePlanning.endDate = DateTime.fromJSDate(endFinal);
      this.experienceOrder.experienceInfo.communicationKits = [];

      await firstValueFrom(this.orderSrv.configureOrder(this.authSrv.userLogged?.value?.id, this.experienceOrder));
      await firstValueFrom(this.orderSrv.extendOrderPlanning(this.authSrv.userLogged?.value?.id, this.experienceId, this.daysToPay));

      this.showExtend = false;
      this.showExtendSuccess = true;
      this.maxDays = this.maxDays + this.daysToPay;
    } catch (e) {
      console.error(e);
    }
  }
}
