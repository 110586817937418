/* eslint-disable max-len */
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrueOrFalse } from 'src/app/data/models/TrueOrFalse';

export interface TrueOrFalseLibraryFunFact {
  funFact: string;
  answer: boolean;
  explain: string;
  isSelected: boolean;
}

const library: TrueOrFalseLibraryFunFact[] = [
  {
    funFact: 'L’inventeur de la chasse d’eau est mort noyé dans une toilette.',
    answer: false,
    explain: 'C’est totalement faux, mais il faut savoir que la chasse d’eau telle qu’on la connaît aujourd’hui existe depuis la fin du 16e siècle.',
    isSelected: false
  },
  {
    funFact: 'Il y a plus de télévisions que de toilettes en Afghanistan',
    answer: true,
    explain: '9% de la population possède une TV alors que seulement 7% ont des toilettes avec chasse d’eau.',
    isSelected: false
  },
  {
    funFact: 'Le premier toilette d’une rangée est souvent le plus propre.',
    answer: true,
    explain: 'Le premier cabinet dans une rangée de toilette… Est souvent le plus propre, car le moins utilisé. Les utilisateurs ont souvent tendances à se rendre dans les suivants.',
    isSelected: false
  },
  {
    funFact: 'Il existe une journée mondiale des toilettes.',
    answer: true,
    explain: 'C’est vrai, et elle a lieu chaque année le 9 novembre.',
    isSelected: false
  },
  {
    funFact: 'En Italie, les premiers ministres sont réputés pour ne jamais aller aux toilettes.',
    answer: false,
    explain: 'Ce sont des personnes normales et se rendent ainsi aux toilettes comme tout le monde.',
    isSelected: false
  }
];

@Component({
  selector: 'app-true-or-false-configurator',
  templateUrl: './true-or-false-configurator.component.html',
  styleUrls: ['./true-or-false-configurator.component.scss'],
})
export class TrueOrFalseConfiguratorComponent implements OnInit {
  config: any;
  data: TrueOrFalse;
  showChoices: boolean = true;
  showLibrary: boolean = false;
  choice: string = '';
  library: TrueOrFalseLibraryFunFact[] = library;
  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  inputDisabled: boolean = null;

  trueOrFalseForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.data = this.config.TrueOrFalse;
    if (this.data?.question || this.data?.explain) {
      this.showChoices = false;
    }

    this.trueOrFalseForm = this.fb.group({
      question: [
        {value: this.data?.question, disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(300)]
      ],
      explain: [
        {value: this.data?.explain, disabled: this.inputDisabled},
        [Validators.maxLength(300)]
      ],
      correctAnswer: this.data?.answer ? true : false,
      wrongAnswer: this.data?.answer ? false : true
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  autoSave() {
    this.isValidEventEmitter.emit(this.trueOrFalseForm.valid);

    if (this.trueOrFalseForm.invalid)
      return;

    this.data.question = this.trueOrFalseForm.get('question').value;
    this.data.explain = this.trueOrFalseForm.get('explain').value;

    if (this.trueOrFalseForm.get('correctAnswer').value === true) this.data.answer = true;
    if (this.trueOrFalseForm.get('wrongAnswer').value === true) this.data.answer = false;
  }

  onSelectCorrectAnswer(correctAnswer: string, wrongAnswer: string) {
    this.trueOrFalseForm?.get(correctAnswer)?.patchValue(true);
    this.trueOrFalseForm?.get(wrongAnswer)?.patchValue(false);
  }

  onChoiceClick(choice: string) {
    this.choice = choice;
    this.showChoices = false;

    if (choice === 'library') {
      this.showLibrary = true;
    }
  }

  onAddOrDeleteLibraryQuestionClick(question: TrueOrFalseLibraryFunFact) {
    question.isSelected = !question.isSelected;
  }
}
