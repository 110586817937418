<div class="add-order-option-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>
  <div class="add-option-form" [formGroup]="addOrderOptionFormGroup">
    <div class="input-container">
      <label for="name">Nom de l'option *</label>
      <input [ngClass]="{'invalid-input': addOrderOptionFormGroup.controls.name.invalid && isSubmitted}" formControlName="name" type="text" id="name">
      <div class="invalid-input-container">
          <span *ngIf="addOrderOptionFormGroup.controls.name.invalid" class="invalid-input-text">
              <span *ngIf="addOrderOptionFormGroup.controls.name?.errors?.required && isSubmitted">Champs requis. </span>
              <span *ngIf="addOrderOptionFormGroup.controls.name?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
          </span>
      </div>
    </div>

    <div class="input-container">
      <label for="amount">Nombre d'éléments *</label>
      <input [ngClass]="{'invalid-input': addOrderOptionFormGroup.controls.amount.invalid && isSubmitted}" formControlName="amount" type="number" [min]="1" [step]="1" id="amount">
      <div class="invalid-input-container">
          <span *ngIf="addOrderOptionFormGroup.controls.amount.invalid" class="invalid-input-text">
              <span *ngIf="addOrderOptionFormGroup.controls.amount?.errors?.required && isSubmitted">Champs requis. </span>
              <span *ngIf="addOrderOptionFormGroup.controls.amount?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
          </span>
      </div>
    </div>
  </div>

  <div class="add-option-controls">
    <div class="mocli-button add-btn" (click)="onAddClick()">
      Ajouter
    </div>
  </div>
</div>