<div class="register-result">
  <div class="cross" (click)="closeModal('cross')">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="X">
  </div>
  
  <div class="title-container">
    <div class="title">
      Félicitations, votre compte est désormais crée !
    </div>
  </div>
  <div class="description">
    Un mail de confirmation de votre inscription vous a été envoyé.
  </div>
  <div class="later" (click)="closeModal('later')">
    Vous pouvez fermer cette page
  </div>
</div>