<div class="add-theme-container">
  <div class="icon-close" (click)="closeModal('close')" style="cursor: pointer;">
    <img src="https://cdn.mocli.fr/icon/cross.svg" alt="close modal">
  </div>

  <div class="add-theme-form" [formGroup]="addThemeFormGroup">
    <div class="input-container">
      <label for="name">Nom du thème *</label>
      <input [ngClass]="{'invalid-input': addThemeFormGroup.controls.name.invalid && isSubmitted}" formControlName="name" type="text" id="name">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.name.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.name?.errors?.required && isSubmitted">Champs requis. </span>
              <span *ngIf="addThemeFormGroup.controls.name?.errors?.maxlength && isSubmitted">Taille maximale atteinte. </span>
          </span>
      </div>
    </div>
  
    <div class="input-container">
      <label for="mainImageUrl">Image principale (URL) *</label>
      <input [ngClass]="{'invalid-input': addThemeFormGroup.controls.mainImageUrl.invalid && isSubmitted}" formControlName="mainImageUrl" type="text" id="mainImageUrl">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.mainImageUrl.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.mainImageUrl?.errors?.required && isSubmitted">Champs requis. </span>
          </span>
      </div>
    </div>

    <div class="input-container">
      <label for="cssUrl">Thème CSS (URL) *</label>
      <input [ngClass]="{'invalid-input': addThemeFormGroup.controls.cssUrl.invalid && isSubmitted}" formControlName="cssUrl" type="text" id="cssUrl">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.cssUrl.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.cssUrl?.errors?.required && isSubmitted">Champs requis. </span>
          </span>
      </div>
    </div>

    <div class="input-container">
      <label for="bgElementsUrl">Formes de fond (URL) *</label>
      <input [ngClass]="{'invalid-input': addThemeFormGroup.controls.bgElementsUrl.invalid && isSubmitted}" formControlName="bgElementsUrl" type="text" id="bgElementsUrl">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.bgElementsUrl.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.bgElementsUrl?.errors?.required && isSubmitted">Champs requis. </span>
          </span>
      </div>
    </div>

    <div class="input-container">
      <label for="baseTheme">Thème de base</label>
      <input class="input-checkbox" [ngClass]="{'invalid-input': addThemeFormGroup.controls.baseTheme.invalid && isSubmitted}" formControlName="baseTheme" type="checkbox" id="baseTheme">
      <div class="invalid-input-container">
          <span *ngIf="addThemeFormGroup.controls.baseTheme.invalid" class="invalid-input-text">
              <span *ngIf="addThemeFormGroup.controls.baseTheme?.errors?.required && isSubmitted">Champs requis. </span>
          </span>
      </div>
    </div>
  </div>

  <div class="absolute-spinner" *ngIf="isLoading">
    <ion-spinner name="crescent" color="light"></ion-spinner>
  </div>
  <div class="invalid-input-text" style="text-align: center; width: 100%;" *ngIf="errorMessage">{{errorMessage}}</div>

  <div class="add-theme-controls">
    <div class="mocli-button add-btn" (click)="onAddClick()">
      {{!themeToEdit ? 'Ajouter' : 'Enregistrer'}}
    </div>
  </div>
</div>