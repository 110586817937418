import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@mocli/MocliCommonLib';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { ThePriceOrWeightIsRight } from 'src/app/data/models/ThePriceOrWheightIsRight';
import { CdnResponse } from 'src/app/data/models/cdnResponse';
// eslint-disable-next-line max-len
import { AuthService } from 'src/app/services/AuthService';

@Component({
  selector: 'app-the-weight-is-right-configurator',
  templateUrl: './the-weight-is-right-configurator.component.html',
  styleUrls: ['./the-weight-is-right-configurator.component.scss'],
})
export class TheWeightIsRightConfiguratorComponent implements OnInit {
  config: any;
  priceOrWeightIsRightForm: FormGroup;
  data: ThePriceOrWeightIsRight;
  inputDisabled: boolean = null;

  visualImageUrl: string;
  uploadedImage: CdnResponse = {
    name: '',
    url: '',
    urn: '',
    lastModified: DateTime.now()
  };

  isPrice: boolean = null;

  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  imageDeleteEventEmitter: EventEmitter<CdnResponse> = new EventEmitter<CdnResponse>();

  constructor(
    private fb: FormBuilder,
    private userSrv: UserService,
    private authSrv: AuthService,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.data = this.config.TheWeightIsRight;

    this.visualImageUrl = this.data?.questionImageUrl;
    this.uploadedImage.url = this.visualImageUrl;
    this.uploadedImage.name = this.visualImageUrl.substring(20);

    this.priceOrWeightIsRightForm = this.fb.group({
      name: [
        {value: this.data?.name ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(50)]
      ],
      place: [
        {value: this.data?.place ?? '', disabled: this.inputDisabled},
        [Validators.maxLength(100)]
      ],
      value: [
        {value: this.data?.value ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(20)]
      ],
      maxAttempts: [
        {value: this.data?.maxAttempts ?? 1, disabled: this.inputDisabled},
        [Validators.required]
      ]
    });

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);
  }

  autoSave() {
    this.isValidEventEmitter.emit(this.priceOrWeightIsRightForm.valid);

    if (this.priceOrWeightIsRightForm.invalid)
      return;

    this.data.questionImageUrl = this.uploadedImage?.url ?? '';

    this.data.name = this.priceOrWeightIsRightForm.get('name').value;
    this.data.place = this.priceOrWeightIsRightForm.get('place').value;
    this.data.value = this.priceOrWeightIsRightForm.get('value').value.toString();
    this.data.maxAttempts = this.priceOrWeightIsRightForm.get('maxAttempts').value;
  }

  onAttemptsChanged(attempts: number) {
    if (!attempts || attempts <= 0 || attempts > 5) return;

    this.priceOrWeightIsRightForm.get('maxAttempts').patchValue(attempts);
  }

  async deleteFile() {
    if (this.uploadedImage?.name) {
      this.imageDeleteEventEmitter.emit(this.uploadedImage);
      this.visualImageUrl = undefined;
      this.uploadedImage = {
        name: '',
        url: '',
        urn: '',
        lastModified: DateTime.now()
      };
    }
  }

  async onFileUpload(event) {
    const files = event.target.files;
    const file = files[0] as File;

    const userid = this.authSrv.userLogged.value.id;
    const user = await firstValueFrom(this.userSrv.getUserById(userid));

    const reader = new FileReader();
    reader.onload = () => {
      const b64file = reader.result;
      //5mb
      if (file.size < 5000000) {
        const uploadPromise = this.userSrv.uploadImageForUser(userid, user.company.id, file.type, b64file);
        firstValueFrom(uploadPromise).then(res => {
          this.visualImageUrl = res.url;
          this.uploadedImage = res;
        });
      } else {
        //max file size
        console.error('file > 5mb');
      }
    };

    reader.readAsArrayBuffer(file);
  }
}
