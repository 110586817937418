export class Outro {
    showDiscount: boolean;
    mainDiscount: string;
    discountText: string;
    showDiscountCondition: boolean;
    discountCondition: string;
    showScore: boolean;

    constructor() {
        this.showDiscount = false;
        this.mainDiscount = '';
        this.discountText = '';
        this.showDiscountCondition = false;
        this.discountCondition = '';
        this.showScore = false;
    }
}
