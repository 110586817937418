<div class="mInput_label-container" [ngClass]="{'no-label': label === null}" [style]="(labelBottom > 0 ? ('margin-bottom: ' + labelBottom + 'px;') : '') + (labelWidth > 0 ? ('width: ' + labelWidth + 'px;') : '')">
    <div class="mInput_label-container_start">
        <div class="mInput_label">{{label}}</div>
        <div class="mInput_label-optional" *ngIf="optional">(optionnel)</div>
        <div class="mInput_label-optional" *ngIf="optionalFeminine">(optionnelle)</div>
        <div class="mInput_label-optional" *ngIf="labelDescription">{{labelDescription}}</div>
        <div class="mInput_label-tooltip" *ngIf="labelTooltip" [pTooltip]="labelTooltip">i</div>
    </div>
    <div class="mInput_label-errors" *ngIf="isFormSubmitted !== false && label !== null">
        {{getErrorsFmt()}}
    </div>
</div>
