import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { ExperienceService } from '@mocli/MocliCommonLib';
import { firstValueFrom } from 'rxjs';
import { ExperienceTheme } from 'src/app/data/models/ExperienceTheme';

@Component({
  selector: 'app-modal-add-theme',
  templateUrl: './modal-add-theme.component.html',
  styleUrls: ['./modal-add-theme.component.scss'],
})
export class ModalAddThemeComponent implements OnInit {
  addThemeFormGroup: FormGroup = null;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  themeToEdit: ExperienceTheme = null;
  errorMessage: string = null;

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private experienceService: ExperienceService,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.themeToEdit) this.themeToEdit = this.navParams.data.themeToEdit;

    this.addThemeFormGroup = this.fb.group({
      name: [this.themeToEdit?.themeName ?? '', [Validators.required, Validators.maxLength(64)]],
      mainImageUrl: [this.themeToEdit?.mainImageUrl ?? '', [Validators.required]],
      cssUrl: [this.themeToEdit?.cssUrl ?? '', [Validators.required]],
      bgElementsUrl: [this.themeToEdit?.bgElementsUrl ?? '', [Validators.required]],
      baseTheme: [this.themeToEdit?.isBaseTheme ?? false]
    });
  }

  async closeModal(data: any) {
    await this.modalController.dismiss(data);
  }

  async onAddClick() {
    this.isSubmitted = true;
    this.errorMessage = null;
    if (this.addThemeFormGroup.invalid) return;

    const newTheme: any = {
      themeName: this.addThemeFormGroup.controls.name.value,
      mainImageUrl: this.addThemeFormGroup.controls.mainImageUrl.value,
      cssUrl: this.addThemeFormGroup.controls.cssUrl.value,
      bgElementsUrl: this.addThemeFormGroup.controls.bgElementsUrl.value,
      isBaseTheme: this.addThemeFormGroup.controls.baseTheme.value
    };

    if (this.themeToEdit) newTheme.id = this.themeToEdit.id;

    this.isLoading = true;
    try {
      let res = null;
      if (this.themeToEdit) {
        res = await firstValueFrom(this.experienceService.updateTheme(newTheme));
      } else {
        res = await firstValueFrom(this.experienceService.addTheme(newTheme));
      }

      console.log('res add/edit theme', res);
      this.closeModal('success');
      this.isLoading = false;
    } catch (error) {
      this.errorMessage = 'Erreur lors de l\'ajout du thème, veuillez réessayer.';
      console.error('error add theme', error);
      this.isLoading = false;
    }
  }
}
