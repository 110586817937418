import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OddOneOut } from 'src/app/data/models/OddOneOut';

@Component({
  selector: 'app-odd-one-out-configurator',
  templateUrl: './odd-one-out-configurator.component.html',
  styleUrls: ['./odd-one-out-configurator.component.scss'],
})
export class OddOneOutConfiguratorComponent implements OnInit {
  oddOneOutForm: FormGroup;
  config: any;
  isValidEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  data: OddOneOut;
  objectsList: string[] = null;
  showMaxObjects: boolean = false;
  alreadyExists: boolean = false;
  inputDisabled: boolean = null;

  // Nombre maximum d'objets
  maxObjects = 10;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.data = this.config.OddOneOut;
    this.oddOneOutForm = this.fb.group({
      name: [
        {value: this.data?.name ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(150)]
      ],
      objectInput: [
        {value: '', disabled: this.inputDisabled},
        [Validators.maxLength(20)]
      ],
      intruderSelect: [
        {value: this.data?.intruder ?? '', disabled: this.inputDisabled},
        [Validators.required, Validators.maxLength(20)]
      ],
      explain: [
        {value: this.data?.explain ?? '', disabled: this.inputDisabled},
        [Validators.maxLength(500)]
      ]
    });

    if (this.data?.objects?.length === 1 && this.data.objects[0] === '') this.objectsList = null;
    else this.objectsList = this.data.objects;

    //l'update est fait au onChange des input mais vu que c'est maj que lorsque l'input perds le focus
    // on rajoute ça pour s'assurer d'avoir un truc réactif
    // on pourrais passer par onInput mais ça risque de déclencher trop d'évents en un coup
    setInterval(() => {
      this.autoSave();
    }, 500);

    this.oddOneOutForm.get('objectInput').valueChanges.subscribe((value: string) => {
      this.alreadyExists = this.objectsList?.includes(value);
    });
  }

  autoSave() {
    this.isValidEventEmitter.emit(this.oddOneOutForm.valid);

    if (this.oddOneOutForm.invalid)
      return;

    this.data.name = this.oddOneOutForm.get('name').value;
    this.data.objects = this.objectsList;
    this.data.intruder = this.oddOneOutForm.get('intruderSelect').value.toString();
    this.data.explain = this.oddOneOutForm.get('explain').value;
  }

  onAttemptsChanged(attempts: number) {
    if (!attempts || attempts <= 0 || attempts > 5) return;

    this.oddOneOutForm.get('maxAttempts').patchValue(attempts);
  }

  onAddObjectClick() {
    if (this.alreadyExists) return;

    if (this.objectsList && this.objectsList.length >= this.maxObjects) {
      this.showMaxObjects = true;
    }

    if (!this.objectsList) this.objectsList = [];

    if (this.oddOneOutForm
        && this.oddOneOutForm.get('objectInput')?.value
        && this.oddOneOutForm.get('objectInput')?.value?.length <= 20
        && this.objectsList?.length < this.maxObjects) {
      this.objectsList.push(this.oddOneOutForm.get('objectInput').value);
      this.oddOneOutForm.get('objectInput').patchValue('');
      this.showMaxObjects = false;
    }
  }

  onDeleteObjectClick(idx: number) {
    this.objectsList.splice(idx, 1);

    this.showMaxObjects = (this.objectsList && this.objectsList.length >= this.maxObjects);
  }

  onChangeIntruder(intruder: any) {
    this.oddOneOutForm.get('intruderSelect').patchValue(intruder?.value);
  }
}
