import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-preview-image',
  templateUrl: './modal-preview-image.component.html',
  styleUrls: ['./modal-preview-image.component.scss'],
})
export class ModalPreviewImageComponent implements OnInit {

  imageUrl: string = null;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    if (this.navParams?.data?.orderId) this.imageUrl = this.navParams.data.imageUrl;
  }

  async closeModal(data: string) {
    const onClosedData: string = data;
    await this.modalController.dismiss(onClosedData);
  }
}
