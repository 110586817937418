<div class="mocli-box-content configure-question">
  <form [formGroup]="quizForm">
    <!-- Titre -->
    <div class="question-info">
      <div class="visuel-title title">Question {{data.index + 1}}</div>
      <div class="question-content">
        <textarea formControlName="question"
                  placeholder="Écrire ici..."
                  [ngClass]="{'invalid-input': quizForm.controls.question.invalid, 'disabled-input': inputDisabled}">
        </textarea>
        <div class="question-content-counter">
          <span *ngIf="quizForm.controls.question.invalid" class="invalid-input-text">
            <span *ngIf="quizForm.controls.question?.errors?.required">Champs requis. </span>
            <span *ngIf="quizForm.controls.question?.errors?.maxlength">Taille maximale atteinte. </span>
          </span>
          <span>Caractères : {{quizForm.controls.question.value.length}} / 300</span>
        </div>
      </div>
    </div>
  </form>
    
  <!-- Réponses -->
  <div class="answer-top" style="margin-bottom: 0;">
    <div class="visuel-title" style="margin-bottom: 0;">
      <div class="title">Réponses</div>
      <div class="optional">(Entre 2 et 4 réponses)</div>
    </div>
    <div class="title-correct">Réponse correcte</div>
    <div *ngIf="answersForms.length < 2"></div>
  </div>

  <div *ngIf="data?.answers" class="answers">
    <ng-container *ngFor="let answer of answersForms; let i = index">
      <form [formGroup]="answer">
        <div class="answer">
          <div class="index">{{i+1}}</div>

          <div class="first">
            <input *ngIf="data"
                   formControlName="answer"
                   type="text"
                   placeholder="Quel ingrédient mystère se trouve dans ... à compléter selon le type de dégustation"
                   [ngClass]="{'invalid-input': answer.controls.answer.invalid, 'disabled-input': inputDisabled}">
            <div class="answer-counter">
              <span *ngIf="answer.controls.answer.invalid" class="invalid-input-text">
                <span *ngIf="answer.controls.answer?.errors?.maxlength">Taille maximale atteinte. </span>
                <span *ngIf="answer.controls.answer?.errors?.required">Champs requis. </span>
              </span>
              <span>Caractères : {{answer.controls.answer.value.length}} / 50</span>
            </div>
          </div>
          
          <div class="delete" >
            <img *ngIf="data?.answers && data?.answers?.length > 2 && !inputDisabled"
                src="https://cdn.mocli.fr/icon/delete.svg"
                (click)="openModalDeleteAnswer(i)" alt="delete">
          </div>

          <div class="second">
            <app-radio-input [checked]="answer.controls.correct.value" (click)="inputDisabled ? null : onSelectCorrectAnswer(i)"></app-radio-input>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <div *ngIf="data?.answers && !inputDisabled" (click)="addAnswer()" class="add-answer" [style]="data?.answers?.length >= 4 ? 'cursor: not-allowed !important;' : ''">
    <div class="add-answer-add">+</div>
    <div class="add-answer-content">Ajouter une réponse</div>
  </div>

  <!-- Visuel -->
  <div class="visuel">
    <div class="visuel-title">
      <div class="title">Visuel</div>
      <div class="optional">(optionnel)</div>
    </div>
    <div *ngIf="!visualImageUrl">
      <input #inputFile 
            [id]="'inputFile-' + data?.index"
            type="file"
            name="file"
            class="inputfile"
            accept="image/*"
            (change)="onFileUpload($event)">
      <label [for]="'inputFile-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrl" class="visual-name-container">
      <div class="delete" *ngIf="!inputDisabled">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile()" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrl" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrl" alt="" class="preview">
  </div>

  <!-- Explication -->
  <div class="explain">
    <form [formGroup]="quizForm">
      <div class="explain-title">
        <div class="title">Explication</div>
        <div class="optional">(optionnelle)</div>
      </div>
      <div class="explain-content">
        <textarea [rows]="3" 
                  formControlName="explanation"
                  placeholder="Écrire ici..."
                  [ngClass]="{'invalid-input': quizForm.controls.explanation.invalid, 'disabled-input': inputDisabled}">
        </textarea>
        <div class="explain-content-counter">
          <span class="invalid-input-text">
            <span *ngIf="quizForm.controls.explanation?.errors?.maxlength">Taille maximale atteinte. </span>
          </span>
          <span>Caractères : {{quizForm.controls.explanation.value.length}} / 300</span>
        </div>
      </div>
    </form>
  </div>

  <!-- Visuel de l'explication -->
  <div class="visuel">
    <div class="visuel-title">
      <div class="title">Visuel de l'explication</div>
      <div class="optional">(optionnel)</div>
    </div>
    <div *ngIf="!visualImageUrlExplain">
      <input #inputFileExplain 
            [id]="'inputFileExplain-' + data?.index"
            type="file"
            name="file"
            class="inputfileExplain"
            accept="image/*"
            (change)="onFileUpload($event, 'explain')">
      <label [for]="'inputFileExplain-' + data?.index" *ngIf="!inputDisabled" class="add-file" >Joindre un fichier</label>
    </div>
    <div *ngIf="visualImageUrlExplain" class="visual-name-container">
      <div class="delete" *ngIf="!inputDisabled">
        <img src="https://cdn.mocli.fr/icon/delete.svg" (click)="deleteFile('explain')" alt="delete">
      </div>
    </div>
  </div>
  <div *ngIf="visualImageUrlExplain" class="preview-container">
    <img id="previewVisual" [src]="visualImageUrlExplain" alt="" class="preview">
  </div>
</div>