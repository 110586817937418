import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HeaderBarConfig } from '../data/models/HeaderBarConfig';

@Injectable({
  providedIn: 'root'
})
export class HeaderBarService {

  public onHeaderBarChanged: BehaviorSubject<HeaderBarConfig> = new BehaviorSubject(null);

  constructor() { }


  public updateHeaderBar(headerBar: HeaderBarConfig) {
    this.onHeaderBarChanged.next(headerBar);
  }
}
