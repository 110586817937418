<div *ngIf="calendarFormGroup" id="statsModalCustomPeriod" class="stats-period-container"
  [formGroup]="calendarFormGroup">
    <h1>Période Personnalisée</h1>
    <p-calendar styleClass="dateSelectorClass" formControlName="calendarRange" [minDate]="orderStartDate" [maxDate]="orderEndDate" [inline]="true" selectionMode="range" class="datePicker">
    </p-calendar>

    <div class="error-message">
      {{errorMessage}}
    </div>
  
    <div class="time-pickers">
      <span>
        <div>Heure de début</div>
        <p-calendar styleClass="dateSelectorClass" [timeOnly]="true" formControlName="startTime"></p-calendar>
      </span>
      <span>
        <div>Heure de fin</div>
        <p-calendar styleClass="dateSelectorClass" [timeOnly]="true" formControlName="endTime"></p-calendar>
      </span>
    </div>
    <div class="datesList">
      <div class="start-container">
        <h2>Date de début</h2>
        <h3>{{startDateStr ?? 'Non sélectionée'}}</h3>
      </div>
      <div class="end-container">
        <h2>Date de fin</h2>
        <h3>{{endDateStr ?? 'Non sélectionée'}}</h3>
      </div>
    </div>

    <div class="controls">
      <div class="mocli-button button cancel" (click)="closeModal('cancel')">Annuler</div>
      <div class="mocli-button button confirm" style="margin-left: 10px;" (click)="onConfirmClick()">Confirmer</div>
    </div>
</div>