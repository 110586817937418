<div class="add-category-container" [ngClass]="{'add-category-container-languages': languages?.length > 1}">
  <div class="languages-container" *ngIf="languages?.length > 1">
    <div class="language" *ngFor="let language of languages; let idx = index">

      <div class="top">
        <div class="flag-wrapper" [ngClass]="{'flag-wrapper-selected': currentLanguageIdx === idx}">
          <img [src]="'https://cdn.mocli.fr/icon/flags/' + language + '.svg'" alt="">
        </div>
  
        <div class="bar" [ngStyle]="{'background-color': stepStatusColors[getStatus(idx)]}"></div>
      </div>
      
      <div class="badge" [ngStyle]="{'background-color': (stepStatusColors[getStatus(idx)] + '4d'), 'color': stepStatusColors[getStatus(idx)]}">{{stepStatusLabels[getStatus(idx)]}}</div>
    </div>
  </div>
  
  <div class="input-container" [formGroup]="addCategoryFormGroup">
    <!-- <label for="name">Nom de la catégorie</label>
    <input [ngClass]="{'invalid-input': addCategoryFormGroup.controls.name.invalid && isSubmitted}" [style]="'margin-top: ' + (languages?.length > 1 ? '40px' : '12px')" formControlName="name" type="text" id="name" [placeholder]="currentLanguageIdx === 0 ? 'Écrire ici...' : ('À traduire : ' + nameByLang['fr'])"> -->
    <app-input-text
      [inputFormGroup]="addCategoryFormGroup"
      [inputFormControlName]="'name'"
      [placeholder]="'Écrire ici...'"
      [maxLength]="100"
      [isFormSubmitted]="isSubmitted"
      label="Nom de la catégorie"
      [labelWidth]="languages?.length > 1 ? 120 : 0"
      [labelBottom]="languages?.length > 1 ? 50 : 0"
    ></app-input-text>

    <!-- <span *ngIf="addCategoryFormGroup.controls.name?.errors?.alreadyExists && isSubmitted">Ce nom existe déjà. </span> -->
  </div>

  <div class="controls-data">
    <div class="global-btn" [ngClass]="{'global-btn-cancel': currentLanguageIdx === 0, 'global-btn-back': currentLanguageIdx > 0}" (click)="prevStep()">{{currentLanguageIdx === 0 ? 'Annuler' : 'Langue précédente'}}</div>
    <div class="global-btn global-btn-back" (click)="nextStep()">{{currentLanguageIdx + 1 === languages?.length ? 'Confirmer' : 'Langue suivante'}}</div>
  </div>
</div>