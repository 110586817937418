/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-code-hunt-button',
  templateUrl: './code-hunt-button.component.html',
  styleUrls: ['./code-hunt-button.component.scss'],
})
export class CodeHuntButtonComponent implements OnInit {
  @Input() imageUrl: string = null;
  @Input() classes: string = null;
  @Input() text: string = null;
  @Input() iconRight: boolean = null;
  @Input() whiteCircle: boolean = true;
  @Input() smallImage: boolean = false;


  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}
}
