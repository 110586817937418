<div class="edit-object-name-container">
    <div class="edit-object-name-title">Objet à retrouver</div>

    <div class="edit-object-name-description">Permet de définir le nom qui s’affichera lorsqu’un joueur scanne un QR code. Ce nom peut être personnalisé pour s’adapter au thème du jeu (ex : QR Code trouvé, Cadeau trouvé, Sticker fleuri trouvé…). </div>

    <div class="form-container">
        <app-input-text
            [inputFormGroup]="objectNameFormGroup"
            [inputFormControlName]="'objectName'"
            [placeholder]="'Par défaut : Sticker'"
            [maxLength]="maxLengthInput"
            label="Nom de l’objet (singulier)"
        ></app-input-text>

        <app-input-text
            [inputFormGroup]="objectNameFormGroup"
            [inputFormControlName]="'objectNamePlural'"
            [placeholder]="'Par défaut : Stickers'"
            [maxLength]="maxLengthInput"
            label="Nom de l’objet (pluriel)"
        ></app-input-text>

        <app-input-text
            [inputFormGroup]="objectNameFormGroup"
            [inputFormControlName]="'demonstrativeAdjective'"
            [placeholder]="'Par défaut : ce'"
            [maxLength]="maxLengthInput"
            label="Déterminant démonstratif de l’objet (singulier)"
        ></app-input-text>
    </div>

    <div class="edit-object-name-controls">
        <div class="global-btn global-btn-cancel" (click)="closeModal()">Annuler</div>
        <div class="global-btn global-btn-back" (click)="submitForm()">Confirmer</div>
    </div>
</div>