export class TrueOrFalse {
    question: string;
    answer: boolean;
    explain: string;

    questionImageUrl: string;
    nextQuestionImageUrl: string;
    idleQuestionImageUrl: string;

    totalQuestions: number;
    index: number;

    hasAnswered: boolean;
    hasCorrectlyAnswered: boolean;

    constructor() {
        this.question = '';
        this.answer = false;
        this.explain = '';
        this.questionImageUrl = '';
        this.nextQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-checkbox-checked-correct.svg';
        this.idleQuestionImageUrl = 'https://cdn.mocli.fr/icon/exp/mocli-bulb.svg';
        this.totalQuestions = 0;
        this.index = 0;
    }
}
