<div class="structure-main-container mocli-box-content" [ngClass]="{'structure-main-container-multiquiz': isMultiQuiz === true}">

    <div class="structure-column">

        <div class="flag-main-container" *ngIf="languages?.length > 1">
            <div class="flag-container">
                <img [src]="'https://cdn.mocli.fr/icon/flags/' + baseLanguage + '.svg'" alt="">
            </div>
    
            <div class="country-title">
                {{ getLabelForLanguage(baseLanguage) }}
            </div>
        </div>
        
        <!-- Nom du jeu -->
        <div class="structure-block">
            <app-input-text
                [inputFormGroup]="codeHuntStructureForm"
                [inputFormControlName]="'gameName'"
                [placeholder]="'Par défaut : Chasse aux codes...'"
                [maxLength]="30"
                label="Nom du jeu"
                customId="input-gameName"
            ></app-input-text>
        </div>

        <!-- Texte d'introduction -->
        <div class="structure-block">
            <app-input-textarea
                [inputFormGroup]="codeHuntStructureForm"
                [inputFormControlName]="'introText'"
                [placeholder]="'Par défaut : Bienvenue dans la chasse aux codes, retrouvez...'"
                [maxLength]="500"
                label="Texte d'introduction"
                customId="input-introText"
            ></app-input-textarea>
        </div>


        <!-- Zone de recherche -->
        <div class="structure-block" *ngIf="!isMultiQuiz">
            <app-input-text
                [inputFormGroup]="codeHuntStructureForm"
                [inputFormControlName]="'searchArea'"
                [placeholder]="'Décrire ici le périmètre de la zone de recherche'"
                [maxLength]="100"
                [optionalFeminine]="true"
                label="Zone de recherche"
                customId="input-searchArea"
            ></app-input-text>
        </div>


        <!-- Nom de l’objet à retrouver -->
        <div class="structure-block" *ngIf="!isMultiQuiz">
            <app-input-label label="Nom de l’objet à retrouver"></app-input-label>
            <div class="name-object-to-find-container">
                {{objectName}} 
                <app-edit-button (editButtonClicked)="onEditObjectNameClick()"></app-edit-button>
            </div>
        </div>

    </div>

    <div class="structure-column right-column" [ngClass]="{'right-column-top': languages?.length > 1 && !isMultiQuiz}">
        <!-- Nombre de codes à retrouver -->
        <!-- <div class="structure-block">
            <app-input-text [value]="totalCodes" [disabled]="true" forcedWidth="80px" [label]="isMultiQuiz ? 'Nombre maximum de quiz' : 'Nombre maximum de QR Codes'"></app-input-text>
        </div> -->
         
    
        <!-- Points par code trouvé -->
        <div class="structure-block" id="mInput_select">
            <app-input-label [label]="isMultiQuiz ? 'Points par quiz joué' : 'Points par QR Code trouvé'"></app-input-label>
            <p-dropdown
                [options]="codePointsList"
                [disabled]="inputDisabled"
                (onChange)="updateCodesPoints()"
                [(ngModel)]="codesPoints"
            ></p-dropdown>
        </div>
    
        <!-- Points par bonne réponse aux questions bonus -->
        <div class="structure-block" id="mInput_select">
            <app-input-label label="Points par bonne réponse aux questions bonus"></app-input-label>
            <p-dropdown
                [options]="bonusPointsList"
                [disabled]="inputDisabled"
                (onChange)="updateSubExpCodesPoints()"
                [(ngModel)]="subExpQuestionPoints"
            ></p-dropdown>
        </div>
    </div>


    



    <!-- Nom de l’objet à retrouver (singulier) -->
    <!-- Nom de l’objet à retrouver (pluriel) -->
    <!-- Adjectif qualificatif de l’objet (singulier) -->

    <!-- <div class="question-info">
        <div class="visuel-title">
            <div class="title"></div>
        </div>
        <div class="question-content" style="margin-top: 5px;" [formGroup]="codeHuntStructureForm">
            <textarea formControlName="intro"
                [placeholder]="selectedLanguage === 'fr' ? 'Bienvenue dans la chasse aux codes, retrouvez....' : 'À traduire : ' + configByLang['fr']?.content[0].CodeHunt?.introText"
                [ngClass]="{'invalid-input': codeHuntStructureForm.controls.intro.invalid, 'disabled-input': inputDisabled}">
            </textarea>
            <div class="question-content-counter">
                <span *ngIf="codeHuntStructureForm.controls.intro.invalid" class="invalid-input-text">
                    <span *ngIf="codeHuntStructureForm.controls.intro?.errors?.required">Champ requis. </span>
                    <span *ngIf="codeHuntStructureForm.controls.intro?.errors?.maxlength">Taille maximale atteinte.
                    </span>
                </span>
                <span>Caractères : {{codeHuntStructureForm.controls.intro.value.length}} / 500</span>
            </div>
        </div>
    </div> -->

    <!-- Points bonus pour les séries de bonnes réponses aux questions -->
    <!-- <div class="question-info" *ngIf="data?.isCodeHuntQuiz === true">
        <div class="visuel-title">
          <div class="title">Points bonus pour les séries de bonnes réponses aux questions</div>
        </div>
        <div class="question-content status-container" style="margin-top: 5px; margin-bottom: 22px">
          <div class="row-status" (click)="hasBonusPoints = true" style="margin-top: 8px">
            <div class="text">Oui</div>
            <app-radio-input [checked]="hasBonusPoints"></app-radio-input>
          </div>
          <div class="row-status" (click)="hasBonusPoints = false">
            <div class="text">Non</div>
            <app-radio-input [checked]="!hasBonusPoints"></app-radio-input>
          </div>
        </div>
      </div> -->

    <!-- Zone de recherche -->
    <!-- <div class="question-info" *ngIf="!isQuizLeaderboard">
        <div class="visuel-title">
            <div class="title">Zone de recherche</div>
        </div>
        <div class="question-content" style="margin-top: 5px;" [formGroup]="codeHuntStructureForm">
            <input formControlName="searchArea"
                [placeholder]="selectedLanguage === 'fr' ? '' : 'À traduire : ' + configByLang['fr']?.content[0].CodeHunt?.searchArea"
                [ngClass]="{'invalid-input': codeHuntStructureForm.controls.searchArea.invalid, 'disabled-input': inputDisabled}">
            <div class="question-content-counter">
                <span *ngIf="codeHuntStructureForm.controls.searchArea.invalid" class="invalid-input-text">
                    <span *ngIf="codeHuntStructureForm.controls.searchArea?.errors?.required">Champ requis. </span>
                    <span *ngIf="codeHuntStructureForm.controls.searchArea?.errors?.maxlength">Taille maximale atteinte.
                    </span>
                </span>
                <span>Caractères : {{codeHuntStructureForm.controls.searchArea.value.length}} / 20</span>
            </div>
        </div>
    </div> -->
</div>