<div class="status-badge-main-container" [ngClass]="{'status-badge-main-container-disabled': disabled}" (click)="onBadgeClick()">

    <div class="background-container" [ngClass]="{'background-container-disabled': disabled}">

        <div class="left-btn btn">
            <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.58194 3.75775L1.25405 0.612184C1.00015 0.462401 0.671428 0.46281 0.418461 0.612184C0.160434 0.764145 0 1.04805 0 1.35293V7.64447C0 7.94948 0.160301 8.23339 0.416863 8.38453C0.543746 8.4601 0.688736 8.5 0.836123 8.5C0.98311 8.5 1.12797 8.46024 1.25432 8.38521L6.5822 5.23937C6.83996 5.087 7 4.80323 7 4.49877C6.99987 4.19471 6.83996 3.91094 6.58194 3.75775Z" fill="#002852"/>
            </svg>            
        </div>

        <div class="right-btn btn">
            <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.000476115 7.70133C0.000476115 7.92107 0.0576098 8.107 0.171877 8.25913C0.286145 8.41126 0.428979 8.49155 0.600381 8.5H1.80019C1.96524 8.5 2.10808 8.41971 2.22869 8.25913C2.34931 8.09855 2.40644 7.91262 2.4001 7.70133V1.2993C2.4001 1.07956 2.34296 0.889404 2.22869 0.728825C2.11443 0.568246 1.97159 0.492182 1.80019 0.500634H0.600381C0.435328 0.500634 0.292493 0.576697 0.171877 0.728825C0.0512616 0.880952 -0.00587208 1.07111 0.000476115 1.2993V7.70133ZM4.59991 7.70133C4.59991 7.92107 4.66021 8.107 4.78083 8.25913C4.90144 8.41126 5.0411 8.49155 5.19981 8.5H6.39962C6.56467 8.5 6.70751 8.41971 6.82812 8.25913C6.94874 8.09855 7.00587 7.91262 6.99952 7.70133V1.2993C6.99952 1.07956 6.94239 0.889404 6.82812 0.728825C6.71385 0.568246 6.57102 0.492182 6.39962 0.500634H5.19981C5.03476 0.500634 4.8951 0.576697 4.78083 0.728825C4.66656 0.880952 4.60625 1.07111 4.59991 1.2993V7.70133Z" fill="#002852"/>
            </svg>
        </div>
        
        <div class="top-content" [ngClass]="{'top-content-inactive': status === codeHuntCodeStatusEnum.INACTIVE}">
            {{status === codeHuntCodeStatusEnum.ACTIVE ? 'Actif' : 'Désactivé'}}
        </div>
    </div>

</div>