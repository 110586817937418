<div class="mocli-box-content configure-question" *ngIf="!isLoading">
    <div class="element-list" *ngIf="pages?.length >= 0">
        <div>
            <div class="language-selector-container" id="languageSelector" *ngIf="languages?.length > 1">
              <p-dropdown (onChange)="onLanguageChange($event)" [options]="languages" [(ngModel)]="dropdownSelectedLanguage" optionLabel="name" placeholder="Select a Country">
                <ng-template pTemplate="selectedItem">
                    <div class="language-selector-item" *ngIf="dropdownSelectedLanguage">
                      <div class="flag-container">
                        <img [src]="'https://cdn.mocli.fr/icon/flags/' + dropdownSelectedLanguage.key + '.svg'"/>
                      </div>
                      <div>{{ dropdownSelectedLanguage.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="language-selector-item">
                      <div class="flag-container">
                        <img [src]="'https://cdn.mocli.fr/icon/flags/' + country.key + '.svg'"/>
                      </div>
                      <div>{{ country.name }}</div>
                    </div>
                </ng-template>
              </p-dropdown>
            </div>
            
            <div class="list-header element_top">
              <div class="header-title">Numéro</div>
              <div class="header-title" style="text-align: center;">Complété</div>
            </div>
        
            <div class="orderlist-container">
              <p-orderList id="configurator-orderlist" [value]="pages" [dragdrop]="true" (onReorder)="onReorderList($event)">
                <ng-template let-page let-index="index" pTemplate="item">
                  <div class="element" (click)="gotoPage(index)" [ngClass]="{'element-selected': index === selectedIdx}">
                    <div class="first">
                      <div class="element-drag-icon">
                        <img src="https://cdn.mocli.fr/icon/drag-indicator.svg" alt="">
                      </div>
                      <div class="element-text">{{'Jeu ' + (index + 1)}}</div>
                    </div>
                    <div class="element-checkbox">
                      <app-checkbox-input type="checkbox" [disable]="true" [checked]="pageComplete[index]"></app-checkbox-input>
                    </div>
                  </div>
                </ng-template>
              </p-orderList>
            </div>

            <div class="add-element" *ngIf="pages?.length < maxElements" (click)="addElement()">
              <div class="add-element-add">+</div>
              <div class="add-element-content">Ajouter un jeu</div>
            </div>
          </div>
    </div>
    
    <div class="content">
        <div class="swiper-container">
            <swiper #swiperAnchor
                class="mySwiper"
                [config]="swiperConfig">
                
                <!-- Content  -->
                <ng-container *ngFor="let page of pages; let i = index">
                    <ng-template swiperSlide>
                        <ng-container *ngIf="!isLoading">
                            
                            <form [formGroup]="landingPageForm">
                                <!-- URL -->
                                <div class="question-info">
                                  <div class="title-container">
                                    <div class="visuel-title title">Jeu à lier</div>
                                    <div class="info" pTooltip="Sélectionnez parmi la liste le jeu vers lequel rediriger le joueur" tooltipPosition="right">i</div>
                                  </div>
                                    <div class="question-content question-content-small" *ngIf="landingPageForm.get('game_' + i)">
                                      <p-dropdown [ngClass]="{'dropdown-error': landingPageForm.get('game_' + i)?.invalid && isSubmitted}" (onChange)="initGamesLeft()" scrollHeight="136px" [formControlName]="'game_' + i" placeholder="Choisir un jeu..." emptyMessage="Aucun jeu trouvé." [options]="expList" [optionValue]="'id'" [optionLabel]="'name'" [optionDisabled]="'isUsed'" id="exp-selector">
                                        <ng-template let-exp pTemplate="item">
                                          <div class="dropdown-item-container">
                                            <div class="name">{{exp?.order?.order?.experienceInfo?.name ?? 'N/A'}}</div>
                                            <div class="id">{{exp?.order?.order?.id}}</div>
                                            <div class="tags">{{getTagsFormated(exp?.order?.order?.tags)}}</div>
                                          </div>
                                        </ng-template>
                                        <ng-template let-exp pTemplate="selectedItem">
                                          <div class="dropdown-selected-container">
                                            <div class="name">{{exp?.order?.order?.experienceInfo?.name ?? 'N/A'}}</div>
                                            <div class="id">{{exp?.order?.order?.id}}</div>
                                            <div class="tags">{{getTagsFormated(exp?.order?.order?.tags)}}</div>
                                          </div>
                                        </ng-template>
                                      </p-dropdown>
                                      <div class="question-content-counter">
                                          <span *ngIf="landingPageForm.get('game_' + i)?.invalid && isSubmitted" class="invalid-input-text">
                                              <span *ngIf="landingPageForm.get('game_' + i)?.errors?.required">Champs requis. </span>
                                          </span>
                                      </div>
                                  </div>
                                </div>

                                <!-- Label -->
                                <div class="question-info" style="margin-top: 10px">
                                    <div class="title-with-counter">
                                        <div class="title-container">
                                          <div class="visuel-title title">Nom du jeu {{i + 1}}</div>
                                          <div class="info" pTooltip="Modifie uniquement le nom dans la liste et non pas le nom du jeu en lui même" tooltipPosition="right">i</div>
                                        </div>
                                        <span class="length-max">Caractères : {{landingPageForm.get('label_' + i)?.value.length}} / 30</span>
                                    </div>
                                    <div class="question-content">
                                        <input type="text" [formControlName]="'label_' + i" placeholder="Écrire ici..."
                                        [ngClass]="{'invalid-input': landingPageForm.get('label_' + i)?.invalid && isSubmitted, 'disabled-input': inputDisabled}"/>
                                        <div class="question-content-counter">
                                            <span *ngIf="landingPageForm.get('label_' + i)?.invalid && isSubmitted" class="invalid-input-text">
                                                <span *ngIf="landingPageForm.get('label_' + i)?.errors?.required">Champs requis. </span>
                                                <span *ngIf="landingPageForm.get('label_' + i)?.errors?.maxlength">Taille maximale atteinte.</span>
                                                <span *ngIf="landingPageForm.get('label_' + i)?.errors?.alreadyExists">Ce nom existe déjà.</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Status -->
                                <div class="question-info" style="margin-top: 10px">
                                  <div class="title-container">
                                    <div class="visuel-title title">Statut</div>
                                    <div class="info" pTooltip="Un jeu inactif ne sera plus présent dans la liste. " tooltipPosition="right">i</div>
                                  </div>
                                  <div class="question-content status-container" id="autoSaveContainer">
                                    <div class="status-text" [ngClass]="{'status-text-disabled': landingPageForm.get('active_' + i)?.value === true}">Inactif</div>
                                    <p-inputSwitch class="small-input-switch" [formControlName]="'active_' + i"></p-inputSwitch>
                                    <div class="status-text" [ngClass]="{'status-text-disabled': landingPageForm.get('active_' + i)?.value === false}">Actif</div>
                                  </div>
                                </div>
                            </form>
                        </ng-container>
                    </ng-template>
                </ng-container>
            </swiper>
        </div>
    </div>
</div>

<!-- Controls -->
<div class="controls" *ngIf="!isLoading">

    <!-- Auto save (non utilisé pour le moment) -->
    <!-- <div class="auto-save-container" id="autoSaveContainer">
      <div class="text">Sauvegarde automatique</div>
      <p-inputSwitch class="small-input-switch" [(ngModel)]="isAutoSaveActive"></p-inputSwitch>
    </div> -->
  
  
    
    <div class="left">
      <!-- Delete -->
      <div *ngIf="pages?.length > 0" (click)="openModalDeleteElement(selectedIdx)" class="delete mocli-button" [ngClass]="{'mocli-button-disabled': pages.length <= 1}">
        Supprimer le jeu
      </div>
    </div>
  
    <!-- Save -->
    <div class="save-container">
  
      <div class="loader-container-save" *ngIf="isSaving">
        <ion-spinner name="crescent" color="light"></ion-spinner>
      </div>
      
      <!-- <div class="save mocli-button" *ngIf="!isOrderComplete()" [ngClass]="isOrderComplete() ? '': 'mocli-button-disabled'">
        Enregistrer
      </div> -->
  
      <div [pTooltip]="getSaveTooltip()" tooltipPosition="top" (click)="isOrderComplete() && changesSubject.value === true ? save() : (!isOrderComplete() ? incompleteToast() : '')" class="save mocli-button" [ngClass]="{'mocli-button-disabled': !changesSubject.value || !isOrderComplete()}">
        <span>
          Enregistrer
        </span>
      </div>
    </div>
  </div>

  <div class="skeleton-container" *ngIf="isLoading">
    <div class="skeleton-content">
      <div class="left"></div>
      <div class="right">
        <div class="skeleton-loader-container">
          <ion-spinner name="crescent" color="dark"></ion-spinner>
        </div>
      </div>
    </div>
    <div class="skeleton-controls">
      <div class="delete mocli-button">Supprimer l'indice</div>
      <div class="save mocli-button">Enregistrer</div>
    </div>
  </div>