import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RolesEnum } from '../data/enums/Roles.enum';
import { AuthService } from '../services/AuthService';
import { HeaderBarService } from '../services/HeaderBarService';
import { SidemenuService } from '../services/SidemenuService';
import { firstValueFrom } from 'rxjs';
import { UserService } from '@mocli/MocliCommonLib';

@Injectable({
  providedIn: 'root'
})
export class NotOrderedGuard  {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private headerBar: HeaderBarService,
    private sideMenuService: SidemenuService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>
  {
    // if route is experiences and user isn't logged, let him go through
    if (!this.authService.userLogged.value && (route?.url[0]?.path === 'experiences' || this.authService.userNotActive)) {
      this.sideMenuService.onSideMenuStateChanged.next(false);
      return true;
    }

    // if user isn't logged, don't let him go trough
    if (!this.authService.userLogged.value) return false;

    try {
      if (!this.authService.userLogged.value?.id) return true;

      // user.hasOrdered = true;
      this.headerBar.updateHeaderBar({
        ...this.headerBar.onHeaderBarChanged.value,
        title: this.headerBar.onHeaderBarChanged?.value?.title,
        showBackBtn: true,
        showWallet: false,
      });
      this.sideMenuService.onSideMenuStateChanged.next(this.authService?.userLogged?.value?.hasOrdered ?? false);
      return true;
    } catch (e) {
      if (e.status === 401) {
        this.authService.nextRoute = route.url[0].path;
      }
      return false;
    }
  }
}
