import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-register-result',
  templateUrl: './modal-register-result.component.html',
  styleUrls: ['./modal-register-result.component.scss'],
})
export class ModalRegisterResultComponent implements OnInit {

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private router: Router,
  ) { }

  ngOnInit() {}

  async closeModal(data: string) {
    const onClosedData: string = data;

    await this.modalController.dismiss(onClosedData);
  }

}
