export class Feedback {
    companyName: string;
    showScore: boolean;
    customButtonText: string;

    constructor() {
        this.companyName = '';
        this.showScore = false;
        this.customButtonText = '';
    }
}

